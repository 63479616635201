import {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/components/sidebar';
import {toggleSidebar} from '../../../reducers/app/actions';
import {logout} from '../../../reducers/user/actions';
import VIPLevels from '../../../views/pages/vip/levels/index';
import {navigate} from '../../../utils/navigation';
import Numeric from '../numeric';
import tippy from 'tippy.js';
import Tooltips from '../../../utils/tooltips';
import ModalsLib from '../../../lib/modals';
import InfoButton from '../info-button';

export default () => {
    const {isMobile, sidebar, isAuth, user, vip} = useSelector(Selector),
          [closing, setClosing] = useState(false),
          dispatch = useDispatch();

    const overlay_ref = useRef(),
          sidebar_ref = useRef();

    const close = () => {
        if(closing)
            return;

        setClosing(true);

        if(overlay_ref.current) {
            overlay_ref.current.classList.remove('animate__fadeIn');
            overlay_ref.current.classList.add('animate__fadeOut');
        }

        if(sidebar_ref.current) {
            sidebar_ref.current.classList.remove('animate__fadeInLeft');
            sidebar_ref.current.classList.add('animate__fadeOutLeft');
        }

        setTimeout(() => {
            dispatch(toggleSidebar());
        }, 500);
    }


    const closeHandler = e => {
        if(closing)
            return;

        if(e.target === overlay_ref.current)
            close();
    }

    useEffect(() => {
        if(sidebar)
            setClosing(false);
    }, [sidebar]);

    useEffect(() => {
        if(user && user.wager > 0) 
            tippy('#wager-sidebar-info', {
                placement: "bottom-end",
                animation: 'fade',
                theme: 'translucent', 
                arrow: true,
                content: Tooltips.wager,
                allowHTML: true
            });
    }, [user]);

    const VIP_ICON = vip ? (typeof VIPLevels[vip.icon] === 'undefined' ? <></> : VIPLevels[vip.icon]) : <></>

    if(!['tablet', 'mobile'].includes(isMobile))
        return <></>;

    if(!sidebar)
        return <></>;

    return (
        <div className="sidebar-overlay animate__animated animate__fadeIn animate__faster" ref={overlay_ref} onClick={closeHandler}>
            <div className="sidebar animate__animated animate__fadeInLeft animate__faster" ref={sidebar_ref}>
                <div className="sidebar-header">
                    <div className="logotype">Lucksy</div>
                    <div className="close" onClick={e => {
                        close();
                    }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.2">
                                <path d="M6 18L18 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18 18L6 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                        </svg>
                    </div>
                </div>

                <InfoButton onClick={e => {
                    close();
                }} />

                {isAuth ? (
                    <>
                        <div className="sidebar-profile">
                            <div className="profile-info">
                                <div className="user-info">
                                    <div className="avatar">
                                        {
                                            user.type === "local"
                                            ? <img src={`${window.location.origin}/source/system/avatars/default.png`} alt="" /> 
                                            : <img src={user.avatar} alt="" />
                                       }
                                    </div>
                                    <div className="names">
                                        <div className="name">{user.first_name}{user.last_name ? ` ${user.last_name}` : ''}</div>
                                        <div className="username">{user.username}</div>
                                    </div>
                                </div>
                                {/* <div className="arrow">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.71203 14.9401L11.602 10.0501C12.1795 9.47256 12.1795 8.52756 11.602 7.95006L6.71203 3.06006" stroke="#30344F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div> */}
                            </div>

                            <hr />

                            <div className="balance">
                                <div className="balance-info">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#44B1F9"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6192 8C15.6054 8 16.588 8 17.5742 8C17.5162 8.62505 17.4546 9.2535 17.4002 9.87856C18.5496 9.95669 19.6699 10.3032 20.6779 10.8161C20.2428 11.842 19.8041 12.8679 19.3654 13.8938C18.5169 13.2178 17.4002 12.8442 16.287 12.8577C16.0078 12.8645 15.7178 12.9155 15.4857 13.065C15.3334 13.1635 15.221 13.3163 15.1884 13.4862C15.163 13.6221 15.1703 13.7749 15.25 13.8972C15.3407 14.0331 15.5002 14.1146 15.6598 14.1656C15.9861 14.2743 16.3378 14.2811 16.6786 14.3151C17.4799 14.4068 18.2849 14.5359 19.0427 14.8042C19.6301 15.0149 20.1921 15.3308 20.5945 15.7928C20.9752 16.2174 21.1964 16.7541 21.2798 17.3045C21.3777 17.9397 21.3487 18.5987 21.1384 19.217C20.9752 19.713 20.696 20.1749 20.3335 20.5656C19.5829 21.3809 18.4807 21.8701 17.3494 21.9958C17.3857 22.665 17.4292 23.3308 17.4691 24C16.5336 24 15.6018 24 14.6699 24C14.7062 23.3376 14.7533 22.6752 14.7859 22.0127C14.0354 21.989 13.2921 21.8327 12.5923 21.5847C11.9143 21.3435 11.2689 21.0242 10.667 20.6505C11.1093 19.5839 11.5517 18.5172 11.9977 17.4471C12.5017 17.8446 13.0383 18.2149 13.6257 18.4968C14.2566 18.7992 14.9564 18.9928 15.667 18.9996C16.0042 19.003 16.3523 18.986 16.6714 18.8773C16.8672 18.8093 17.0593 18.6904 17.1464 18.5036C17.237 18.3066 17.2298 18.0586 17.092 17.882C16.9941 17.7631 16.8563 17.6815 16.7113 17.6238C16.5155 17.549 16.3124 17.5083 16.1057 17.4777C15.3443 17.3622 14.5793 17.2671 13.8432 17.0429C13.2522 16.8628 12.6757 16.6115 12.2043 16.2242C11.907 15.983 11.6605 15.6875 11.4864 15.3546C11.2254 14.8586 11.1275 14.2981 11.1347 13.7478C11.1347 13.1329 11.2689 12.5079 11.5952 11.9677C11.878 11.4854 12.3095 11.0913 12.7954 10.789C13.3827 10.4255 14.039 10.1775 14.7062 9.97707C14.6772 9.31805 14.6482 8.65902 14.6192 8Z" fill="#45B1FA"/>
                                    </svg>

                                    <div className="balance-details">
                                        <span>Balance</span>
                                        <Numeric className="balance_amount" value={user.balance} />
                                    </div>
                                </div>

                                <div className="wallet-button" onClick={e => {
                                    close();
                                    ModalsLib.open('wallet');
                                }}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.66699 7.43335H4.66699" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M1.33301 7.43331V4.35331C1.33301 2.99331 2.43301 1.89331 3.79301 1.89331H7.53967C8.89967 1.89331 9.99967 2.73998 9.99967 4.09998" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.653 8.13342C11.3197 8.45342 11.1597 8.94674 11.293 9.45341C11.4597 10.0734 12.073 10.4668 12.713 10.4668H13.333V11.4334C13.333 12.9068 12.1397 14.1001 10.6663 14.1001H3.99967C2.52634 14.1001 1.33301 12.9068 1.33301 11.4334V6.76676C1.33301 5.29343 2.52634 4.1001 3.99967 4.1001H10.6663C12.133 4.1001 13.333 5.3001 13.333 6.76676V7.7334H12.613C12.2397 7.7334 11.8997 7.88009 11.653 8.13342Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.6665 8.41341V9.78674C14.6665 10.1601 14.3598 10.4668 13.9798 10.4668H12.6931C11.9731 10.4668 11.3132 9.94009 11.2532 9.22009C11.2132 8.80009 11.3731 8.40676 11.6531 8.13342C11.8998 7.88009 12.2398 7.7334 12.6131 7.7334H13.9798C14.3598 7.7334 14.6665 8.04008 14.6665 8.41341Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>

                            {user.wager > 0 && (
                                <div className="balance wager">
                                    <div className="balance-info">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#F99637"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6192 8C15.6054 8 16.588 8 17.5742 8C17.5162 8.62505 17.4546 9.2535 17.4002 9.87856C18.5496 9.95669 19.6699 10.3032 20.6779 10.8161C20.2428 11.842 19.8041 12.8679 19.3654 13.8938C18.5169 13.2178 17.4002 12.8442 16.287 12.8577C16.0078 12.8645 15.7178 12.9155 15.4857 13.065C15.3334 13.1635 15.221 13.3163 15.1884 13.4862C15.163 13.6221 15.1703 13.7749 15.25 13.8972C15.3407 14.0331 15.5002 14.1146 15.6598 14.1656C15.9861 14.2743 16.3378 14.2811 16.6786 14.3151C17.4799 14.4068 18.2849 14.5359 19.0427 14.8042C19.6301 15.0149 20.1921 15.3308 20.5945 15.7928C20.9752 16.2174 21.1964 16.7541 21.2798 17.3045C21.3777 17.9397 21.3487 18.5987 21.1384 19.217C20.9752 19.713 20.696 20.1749 20.3335 20.5656C19.5829 21.3809 18.4807 21.8701 17.3494 21.9958C17.3857 22.665 17.4292 23.3308 17.4691 24C16.5336 24 15.6018 24 14.6699 24C14.7062 23.3376 14.7533 22.6752 14.7859 22.0127C14.0354 21.989 13.2921 21.8327 12.5923 21.5847C11.9143 21.3435 11.2689 21.0242 10.667 20.6505C11.1093 19.5839 11.5517 18.5172 11.9977 17.4471C12.5017 17.8446 13.0383 18.2149 13.6257 18.4968C14.2566 18.7992 14.9564 18.9928 15.667 18.9996C16.0042 19.003 16.3523 18.986 16.6714 18.8773C16.8672 18.8093 17.0593 18.6904 17.1464 18.5036C17.237 18.3066 17.2298 18.0586 17.092 17.882C16.9941 17.7631 16.8563 17.6815 16.7113 17.6238C16.5155 17.549 16.3124 17.5083 16.1057 17.4777C15.3443 17.3622 14.5793 17.2671 13.8432 17.0429C13.2522 16.8628 12.6757 16.6115 12.2043 16.2242C11.907 15.983 11.6605 15.6875 11.4864 15.3546C11.2254 14.8586 11.1275 14.2981 11.1347 13.7478C11.1347 13.1329 11.2689 12.5079 11.5952 11.9677C11.878 11.4854 12.3095 11.0913 12.7954 10.789C13.3827 10.4255 14.039 10.1775 14.7062 9.97707C14.6772 9.31805 14.6482 8.65902 14.6192 8Z" fill="#F99F3C"/>
                                        </svg>

                                        <div className="balance-details">
                                            <span>Wager</span>
                                            <Numeric className="balance_amount" value={user.wager} />
                                        </div>
                                    </div>

                                    <div className="button" id="wager-sidebar-info">
                                        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_636_4011)">
                                                <path d="M5.10326 9.80348C4.90076 9.88223 4.25951 10.0697 3.96701 9.90285C3.92666 9.87941 3.8926 9.84651 3.86778 9.80699C3.84295 9.76747 3.82811 9.72251 3.82451 9.67598C3.73264 9.09098 4.16951 7.7391 4.40576 7.01348C4.48451 6.7716 4.54076 6.59535 4.56326 6.48848C4.73389 5.6991 4.64951 5.09535 4.31201 4.6941C3.90589 4.21129 3.18551 4.11735 2.59451 4.20473C1.99356 4.28482 1.4063 4.4463 0.84889 4.68473C0.822997 4.69726 0.800371 4.71564 0.7828 4.73842C0.765228 4.7612 0.753192 4.78775 0.747639 4.81598L0.625764 5.3991C0.619173 5.4319 0.621431 5.46585 0.632305 5.49749C0.643179 5.52912 0.662278 5.55729 0.687639 5.5791C0.713318 5.60044 0.744084 5.61476 0.776943 5.62068C0.809802 5.62659 0.843632 5.6239 0.875139 5.61285C1.09826 5.53035 1.79576 5.34473 2.04514 5.57348C2.18576 5.70285 2.14639 5.9766 2.09014 6.18098C2.03201 6.39473 1.96264 6.62535 1.89139 6.86723C1.43014 8.4366 0.905139 10.216 1.73764 10.771C2.13151 11.0717 2.61093 11.2392 3.10639 11.2491C3.59389 11.2491 4.19576 11.0954 5.06201 10.7447C5.08812 10.7346 5.11161 10.7187 5.13076 10.6982C5.1499 10.6778 5.16422 10.6533 5.17264 10.6266L5.35076 10.0322C5.36086 9.99834 5.36129 9.96231 5.35201 9.92819C5.34274 9.89407 5.32412 9.86322 5.29826 9.8391C5.2724 9.81542 5.24039 9.79952 5.20589 9.79322C5.1714 9.78691 5.13583 9.79047 5.10326 9.80348ZM4.01951 0.750977C3.75129 0.751162 3.48914 0.830857 3.2662 0.979989C3.04326 1.12912 2.86953 1.341 2.76697 1.58884C2.66441 1.83668 2.63763 2.10936 2.69 2.37242C2.74237 2.63548 2.87156 2.87711 3.06122 3.06677C3.25088 3.25644 3.49251 3.38562 3.75557 3.43799C4.01863 3.49036 4.29131 3.46358 4.53915 3.36102C4.78699 3.25846 4.99887 3.08473 5.148 2.86179C5.29713 2.63885 5.37683 2.3767 5.37701 2.10848C5.37692 1.74848 5.23386 1.40325 4.9793 1.14869C4.72474 0.89413 4.37952 0.751076 4.01951 0.750977Z" fill="#30344F"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_636_4011">
                                                    <rect width="4.75494" height="10.4981" fill="white" transform="translate(0.62207 0.750977)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            )}
                        </div>

                        {vip && (
                            <div className="sidebar-vip" onClick={e => {
                                close();
                                navigate('/vip');
                            }}>
                                <div className="info">
                                    <div className="icon">
                                        <VIP_ICON />
                                    </div>
                                    <div className="details">
                                        <div className="title">VIP Status</div>
                                        <div className="name">{vip.name}</div>
                                    </div>
                                </div>
                                <div className="arrow">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.71203 14.9401L11.602 10.0501C12.1795 9.47256 12.1795 8.52756 11.602 7.95006L6.71203 3.06006" stroke="#30344F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <div className="guest-buttons">
                        <div className="button signin" onClick={e => {close(); ModalsLib.open('signin');}}>Sign In</div>
                        <div className="button signup" onClick={e => {close(); ModalsLib.open('signup');}}>Sign Up</div>
                    </div>
                )}

                <div className="sidebar-navigation">
                    {/* <div className="link active"> */}
                    <div className="link" onClick={e => {
                        close();
                        navigate('/');
                    }}>
                        <div className="active-margin">
                            <div className="active"></div>
                        </div>

                        <div className="navigation-link">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.02 2.83992L3.63 7.03992C2.73 7.73992 2 9.22992 2 10.3599V17.7699C2 20.0899 3.89 21.9899 6.21 21.9899H17.79C20.11 21.9899 22 20.0899 22 17.7799V10.4999C22 9.28992 21.19 7.73992 20.2 7.04992L14.02 2.71992C12.62 1.73992 10.37 1.78992 9.02 2.83992Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 17.99V14.99" fill="none" stroke="#161C2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            Home
                        </div>
                    </div>

                    <div className="link"  onClick={e => {
                        close();
                        navigate('/vip');
                    }}>
                        <div className="active-margin">
                            <div className="active"></div>
                        </div>

                        <div className="navigation-link">
                            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.8334 5.3925C22.5249 5.18339 22.1607 5.07161 21.788 5.07161C21.4153 5.07161 21.0511 5.18339 20.7426 5.3925L17.4926 7.4075C17.4603 7.42216 17.4252 7.42975 17.3897 7.42975C17.3542 7.42975 17.3191 7.42216 17.2868 7.4075C17.2145 7.38795 17.1524 7.34143 17.1134 7.2775L13.6468 1.0375C13.4783 0.757075 13.24 0.52503 12.9553 0.363934C12.6705 0.202837 12.3489 0.118172 12.0218 0.118172C11.6946 0.118172 11.373 0.202837 11.0883 0.363934C10.8035 0.52503 10.5653 0.757075 10.3968 1.0375L6.8001 7.26667C6.7611 7.33059 6.69906 7.37712 6.62677 7.39667C6.59444 7.41133 6.55935 7.41892 6.52386 7.41892C6.48836 7.41892 6.45327 7.41133 6.42094 7.39667L3.22511 5.34917C2.91571 5.15336 2.55548 5.05304 2.18941 5.06075C1.82334 5.06845 1.46765 5.18384 1.16677 5.3925C0.857982 5.60349 0.618933 5.90157 0.480037 6.24881C0.341141 6.59605 0.308676 6.97676 0.386771 7.3425L2.20677 15.89C2.29618 16.3153 2.52899 16.697 2.86624 16.9711C3.20348 17.2453 3.62467 17.3952 4.05927 17.3958H19.9734C20.408 17.3952 20.8292 17.2453 21.1665 16.9711C21.5037 16.697 21.7365 16.3153 21.8259 15.89L23.6459 7.3425C23.7207 6.9735 23.6833 6.59054 23.5385 6.24299C23.3937 5.89544 23.1481 5.59922 22.8334 5.3925Z" stroke="none" />
                            </svg>

                            VIP Club
                        </div>
                    </div>

                    <div className="link" onClick={e => {
                        close();
                        navigate('/invite');
                    }}>
                        <div className="active-margin">
                            <div className="active"></div>
                        </div>

                        <div className="navigation-link">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_506_1034)">
                                    <path d="M4.33325 9.75C6.12818 9.75 7.58325 8.29493 7.58325 6.5C7.58325 4.70507 6.12818 3.25 4.33325 3.25C2.53833 3.25 1.08325 4.70507 1.08325 6.5C1.08325 8.29493 2.53833 9.75 4.33325 9.75Z" stroke="none"/>
                                    <path d="M7.8975 11.9925C6.80712 12.6729 5.90784 13.6196 5.28431 14.7435C4.66078 15.8674 4.33352 17.1314 4.33333 18.4167H2.16667C1.59256 18.415 1.04245 18.1861 0.636496 17.7802C0.230538 17.3742 0.00171462 16.8241 0 16.25L0 14.0833C0.00257193 13.2222 0.345808 12.397 0.954744 11.7881C1.56368 11.1791 2.38884 10.8359 3.25 10.8333H5.41667C5.88962 10.8344 6.35664 10.9387 6.78512 11.1389C7.21361 11.3391 7.59324 11.6304 7.8975 11.9925Z" stroke="none"/>
                                    <path d="M21.6667 9.75C23.4617 9.75 24.9167 8.29493 24.9167 6.5C24.9167 4.70507 23.4617 3.25 21.6667 3.25C19.8718 3.25 18.4167 4.70507 18.4167 6.5C18.4167 8.29493 19.8718 9.75 21.6667 9.75Z" stroke="none"/>
                                    <path d="M26 14.0833V16.25C25.9983 16.8241 25.7695 17.3742 25.3635 17.7802C24.9576 18.1861 24.4075 18.415 23.8334 18.4167H21.6667C21.6665 17.1314 21.3393 15.8674 20.7157 14.7435C20.0922 13.6196 19.1929 12.6729 18.1025 11.9925C18.4068 11.6304 18.7864 11.3391 19.2149 11.1389C19.6434 10.9387 20.1104 10.8344 20.5834 10.8333H22.75C23.6112 10.8359 24.4364 11.1791 25.0453 11.7881C25.6542 12.397 25.9975 13.2222 26 14.0833Z" stroke="none"/>
                                    <path d="M13.0001 11.9167C15.3933 11.9167 17.3334 9.97657 17.3334 7.58333C17.3334 5.1901 15.3933 3.25 13.0001 3.25C10.6068 3.25 8.66675 5.1901 8.66675 7.58333C8.66675 9.97657 10.6068 11.9167 13.0001 11.9167Z" stroke="none"/>
                                    <path d="M19.5 18.4167V19.5C19.4974 20.3612 19.1542 21.1863 18.5453 21.7953C17.9363 22.4042 17.1112 22.7474 16.25 22.75H9.75C8.88884 22.7474 8.06368 22.4042 7.45474 21.7953C6.84581 21.1863 6.50257 20.3612 6.5 19.5V18.4167C6.5 16.9801 7.07068 15.6023 8.0865 14.5865C9.10233 13.5707 10.4801 13 11.9167 13H14.0833C15.5199 13 16.8977 13.5707 17.9135 14.5865C18.9293 15.6023 19.5 16.9801 19.5 18.4167Z" stroke="none"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_506_1034">
                                        <rect width="26" height="26" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>

                            Invite
                        </div>
                    </div>
                </div>

                <div className="sidebar-navigation">
                    <div className="link" onClick={e => {
                        window.open('https://t.me/casinos_support_bot', '_blank');
                    }}>
                        <div className="active-margin">
                            <div className="active"></div>
                        </div>

                        <div className="navigation-link">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.7498 13.2383C22.7498 7.29083 18.1348 3.25 12.9998 3.25C7.919 3.25 3.24984 7.20417 3.24984 13.3033C2.59984 13.6717 2.1665 14.365 2.1665 15.1667V17.3333C2.1665 18.525 3.1415 19.5 4.33317 19.5H5.4165V12.8917C5.4165 8.69917 8.80734 5.30833 12.9998 5.30833C17.1923 5.30833 20.5832 8.69917 20.5832 12.8917V20.5833H11.9165V22.75H20.5832C21.7748 22.75 22.7498 21.775 22.7498 20.5833V19.2617C23.389 18.9258 23.8332 18.265 23.8332 17.485V14.9933C23.8332 14.235 23.389 13.5742 22.7498 13.2383Z" stroke="none" />
                                <path d="M9.74984 15.1667C10.3481 15.1667 10.8332 14.6816 10.8332 14.0833C10.8332 13.485 10.3481 13 9.74984 13C9.15153 13 8.6665 13.485 8.6665 14.0833C8.6665 14.6816 9.15153 15.1667 9.74984 15.1667Z" stroke="none"/>
                                <path d="M16.2498 15.1667C16.8481 15.1667 17.3332 14.6816 17.3332 14.0833C17.3332 13.485 16.8481 13 16.2498 13C15.6515 13 15.1665 13.485 15.1665 14.0833C15.1665 14.6816 15.6515 15.1667 16.2498 15.1667Z" stroke="none"/>
                                <path d="M19.5 11.9492C19.2417 10.4261 18.4529 9.04363 17.2732 8.04633C16.0935 7.04903 14.5989 6.50127 13.0542 6.5C9.77167 6.5 6.24001 9.21917 6.52167 13.4875C7.85783 12.9412 9.03795 12.0726 9.95665 10.9592C10.8754 9.84582 11.5041 8.52224 11.7867 7.10667C13.2058 9.95583 16.12 11.9167 19.5 11.9492Z" stroke="none"/>
                            </svg>

                            Live Operator
                        </div>
                    </div>
                </div>

                {(isAuth && user.type === "local") && (
                    <div className="sidebar-logout" onClick={e => {
                        close();
                        navigate('/', () => {
                            dispatch(logout())
                        });
                    }}>
                        Logout
                    </div>
                )}
            </div>
        </div>
    )
}