import ModalsLib from '../../../../lib/modals';
import {useDispatch} from 'react-redux';
import {setPreset} from '../../../../reducers/wallet/actions';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import {useEffect} from 'react';
import tippy from 'tippy.js';

export default ({
    amounts,
    bonus,
    cashtag,
    paymentId,
    recipient,
    status,
    vip_xp
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        tippy('.vip-xp', {
            placement: "top",
            animation: 'fade',
            theme: 'translucent',
            arrow: true,
            content: `You got <b>${vip_xp} XP</b> for your deposit`,
            allowHTML: true
        });
    }, []);

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Deposit failed</div>
                    <div className="descriptions">Your deposit request #{paymentId} has been canceled!</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="payment-info-amount">
                    <div className="amount">
                        ${amounts.amount.toFixed(2)}
                    </div>
                    <div className="details">
                        <div className="label">Amount</div>
                        {vip_xp > 0 && (
                            <div className="vip-xp">
                                +{vip_xp}
                                <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5131 4.17661C16.2995 4.03183 16.0474 3.95445 15.7894 3.95445C15.5313 3.95445 15.2792 4.03183 15.0656 4.17661L12.8156 5.57161C12.7932 5.58176 12.7689 5.58701 12.7444 5.58701C12.7198 5.58701 12.6955 5.58176 12.6731 5.57161C12.6231 5.55807 12.5801 5.52586 12.5531 5.48161L10.1531 1.16161C10.0364 0.967464 9.87152 0.806818 9.67438 0.695289C9.47725 0.583761 9.2546 0.525146 9.02811 0.525146C8.80161 0.525146 8.57896 0.583761 8.38183 0.695289C8.18469 0.806818 8.01977 0.967464 7.90311 1.16161L5.41311 5.47411C5.3861 5.51836 5.34315 5.55057 5.29311 5.56411C5.27072 5.57426 5.24643 5.57951 5.22186 5.57951C5.19728 5.57951 5.17299 5.57426 5.15061 5.56411L2.93811 4.14661C2.72391 4.01104 2.47452 3.94159 2.22109 3.94693C1.96765 3.95226 1.72141 4.03215 1.51311 4.17661C1.29933 4.32268 1.13383 4.52904 1.03767 4.76943C0.941516 5.00983 0.91904 5.2734 0.973106 5.52661L2.23311 11.4441C2.295 11.7386 2.45618 12.0028 2.68966 12.1926C2.92313 12.3824 3.21473 12.4862 3.51561 12.4866H14.5331C14.834 12.4862 15.1256 12.3824 15.3591 12.1926C15.5925 12.0028 15.7537 11.7386 15.8156 11.4441L17.0756 5.52661C17.1274 5.27114 17.1015 5.00602 17.0012 4.7654C16.901 4.52479 16.731 4.31972 16.5131 4.17661Z" fill="white"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>

                <div className="payment-provider">
                    <div className="left">
                        <div className="icon cashapp">
                            <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.908 5.81664C11.6905 5.81664 13.3897 6.44997 14.7391 7.59997C14.8724 7.71664 15.0556 7.79997 15.2555 7.79997C15.4554 7.79997 15.622 7.71664 15.772 7.5833L17.1546 6.19997C17.2213 6.13331 17.2713 6.03331 17.3046 5.94997C17.3379 5.86664 17.3546 5.74997 17.3546 5.66664C17.3546 5.56664 17.3212 5.46663 17.2879 5.3833C17.2546 5.29997 17.188 5.19997 17.1047 5.14997C16.0218 4.21664 14.7558 3.51664 13.3897 3.06664L13.8062 0.999969C13.8229 0.89997 13.8229 0.783304 13.8062 0.683304C13.7895 0.583304 13.7396 0.483302 13.6729 0.399968C13.6063 0.316635 13.523 0.233305 13.423 0.199972C13.3231 0.149972 13.2065 0.133301 13.1065 0.133301H10.4411C10.2745 0.133301 10.1079 0.183301 9.9913 0.299967C9.87468 0.399967 9.77473 0.549972 9.72475 0.699972L9.35826 2.5333C5.79324 2.71664 2.76131 4.46664 2.76131 8.09997C2.76131 11.2333 5.26015 12.5666 7.89227 13.5166C10.3911 14.45 11.7072 14.7833 11.7072 16.1C11.7072 17.4 10.3911 18.2333 8.44201 18.2333C6.55955 18.2666 4.72707 17.55 3.36103 16.25C3.22776 16.1166 3.04451 16.0333 2.8446 16.0333C2.6447 16.0333 2.47811 16.1 2.32818 16.25L0.82887 17.7166C0.762235 17.7833 0.712258 17.85 0.662281 17.95C0.628963 18.0333 0.612305 18.15 0.612305 18.2333C0.612305 18.3333 0.628963 18.4333 0.662281 18.5166C0.695599 18.6 0.745576 18.7 0.82887 18.75C2.04497 19.9 3.52762 20.7 5.14354 21.1L4.72707 23C4.71041 23.1 4.71041 23.2166 4.72707 23.3166C4.74373 23.4166 4.7937 23.5333 4.86034 23.6C4.92697 23.6833 5.02693 23.75 5.11022 23.8166C5.21018 23.8666 5.32679 23.8833 5.42674 23.8833H8.12549C8.29208 23.8833 8.45867 23.8333 8.57529 23.7333C8.6919 23.6333 8.80851 23.4833 8.84183 23.3166L9.22498 21.5C13.5063 21.2333 16.1051 18.9333 16.1051 15.5666C16.1051 12.4666 13.5063 11.1666 10.3411 10.1C8.52531 9.41664 6.95937 8.96663 6.95937 7.6333C6.99268 6.2833 8.44201 5.81664 9.908 5.81664Z" fill="white"></path>
                            </svg>
                        </div>
                        <div className="name">CashApp</div>
                    </div>
                    <CopyToClipboard text={`CashApp request #${paymentId} for $${amounts.amount} from ${cashtag} to ${recipient.name} (${recipient.cashtag})`} onCopy={e => {
                        toast.dismiss();
                        toast.info(`The transaction information has been copied to the clipboard.`);
                    }}>
                        <div className="right">
                            #{paymentId}

                            <div className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                    <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                </svg>
                            </div>
                        </div>
                    </CopyToClipboard>
                </div>

                <div className="payment-details">
                    <div className="payment-detail">
                        <div className="row">
                            <div className="label">Payment ID</div>
                            <div className="value">#{paymentId}</div>
                        </div>
                        <div className="row">
                            <div className="label">Sender</div>
                            <div className="value">{cashtag}</div>
                        </div>
                        <div className="row">
                            <div className="label">Recipient</div>
                            <div className="value">{recipient.name} ({recipient.cashtag})</div>
                        </div>
                    </div>

                    <div className="payment-detail">
                        <div className="row">
                            <div className="label">Amount</div>
                            <div className="value">${amounts.amount}</div>
                        </div>
                    </div>
                </div>

                <div className="modal-message warning">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Most likely, we did not receive the ${amounts.amount} payment from {cashtag}.</span>
                </div>

                <div className="modal-message info center" onClick={e => {
                    dispatch(setPreset({
                        mode: "methods",
                        method: "cards-deposit"
                    }));

                    ModalsLib.open('wallet');
                }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5L11.5 14L14.75 11" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <span>
                        If you have any problems depositing via <b>CashApp</b>, use your <b>CashApp Card</b> to make deposits
                    </span>

                    <div class="arrow">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.9668 3.22003L10.3135 7.5667C10.8268 8.08003 10.8268 8.92003 10.3135 9.43336L5.9668 13.78" stroke="#0C76DA" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}