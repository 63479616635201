import {useSelector} from 'react-redux';
import Selector from '../../../selectors/modals/wallet_deposit_failed';
import ModalsLib from '../../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Types from './types';

export default () => {
    const {transaction} = useSelector(Selector),
          Template = typeof Types[transaction.type] === 'undefined' ? <></> : Types[transaction.type];

    if(!transaction)
        return <></>;

    return (
        <div className="modal deposit-failed wallet animate__animated animate__fadeInUp animate__faster">
            <Template {...transaction} />
            <div className="modal-footer">
                <div className="button back" onClick={e => ModalsLib.close()}>
                    Close
                </div>
            </div>
        </div>
    )
}