export default ({active, onClick}) => (
    <div className={`method ${!active ? 'not-available' : ''}`} onClick={e => {
        if(!active)
            return;

        onClick({
            name: 'USDT',
            network: 'TRC-20',
            token_name: "USDT",
            token: "USDT_TRC20"
        });
    }}>
        <div className="info">
            <div className="icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1275_6842)">
                        <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#26A17B"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7529 19.6895V19.6874C19.6341 19.6959 19.0218 19.7327 17.6556 19.7327C16.5648 19.7327 15.797 19.7003 15.527 19.6874V19.6905C11.3279 19.5059 8.19369 18.7748 8.19369 17.9C8.19369 17.0262 11.3279 16.2951 15.527 16.1072V18.9627C15.8012 18.9821 16.5875 19.0286 17.6739 19.0286C18.9776 19.0286 19.631 18.9746 19.7529 18.9638V16.1093C23.9433 16.2962 27.0699 17.0273 27.0699 17.9C27.0699 18.7748 23.9433 19.5038 19.7529 19.6895ZM19.7529 15.8123V13.257H25.6001V9.36035H9.67977V13.257H15.527V15.8112C10.7749 16.0293 7.20117 16.9712 7.20117 18.0987C7.20117 19.2261 10.7749 20.1669 15.527 20.3861V28.5747H19.7529V20.384C24.4974 20.1657 28.0625 19.2251 28.0625 18.0987C28.0625 16.9722 24.4974 16.0316 19.7529 15.8123Z" fill="white"/>
                        <path d="M27.75 36C32.3063 36 36 32.3063 36 27.75C36 23.1937 32.3063 19.5 27.75 19.5C23.1937 19.5 19.5 23.1937 19.5 27.75C19.5 32.3063 23.1937 36 27.75 36Z" fill="#FF060A"/>
                        <path d="M32.7355 25.6188C32.2114 25.1348 31.4862 24.3956 30.8955 23.8713L30.8605 23.8469C30.8025 23.8002 30.7369 23.7635 30.6666 23.7386C29.2425 23.4729 22.6144 22.2339 22.4851 22.2497C22.4488 22.2548 22.4142 22.2678 22.3837 22.2881L22.3506 22.3143C22.3096 22.3559 22.2786 22.406 22.2597 22.4612L22.251 22.4838V22.6079V22.6271C22.9971 24.7049 25.9432 31.5113 26.5234 33.1083C26.5584 33.2168 26.6248 33.4229 26.7489 33.4334H26.7768C26.8432 33.4334 27.1263 33.0594 27.1263 33.0594C27.1263 33.0594 32.1868 26.9223 32.6989 26.2688C32.7652 26.1884 32.8237 26.1017 32.8737 26.0102C32.8864 25.9386 32.8804 25.865 32.8561 25.7963C32.832 25.7277 32.7904 25.6665 32.7355 25.6188ZM28.4247 26.3334L30.5845 24.5424L31.8514 25.7097L28.4247 26.3334ZM27.5859 26.2164L23.8674 23.1689L29.8837 24.2784L27.5859 26.2164ZM27.9214 27.015L31.7274 26.4017L27.3762 31.644L27.9214 27.015ZM23.3623 23.4729L27.2748 26.793L26.7087 31.6475L23.3623 23.4729Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1275_6842">
                            <rect width="36" height="36" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div className="name">USDT <span>TRC-20</span></div>
        </div>

        {
            !active 
            ? <div className="status">Soon</div>
            : <div className="status bonus">Bonus <b>+7%</b></div>
        }
    </div>
)