import types from '../types';
import API from '../../../lib/api';
import ModalsLib from '../../../lib/modals';
import {toast} from 'react-toastify';

export const activate = data => async dispatch => {
    // Disable button and clear error
    dispatch({
        type: types.bonuses.update,
        payload: {
            error: null,
            message: null,
            button: true
        }
    });

    // Make API request
    const response = await API.call({
        path: '/vouchers/activate',
        method: 'POST',
        body: data
    });

    if(!response.success) {
        dispatch({
            type: types.bonuses.update,
            payload: {
                error: response.error,
                button: false
            }
        });
    } else {
        dispatch({
            type: types.bonuses.update,
            payload: {
                message: response.message,
                button: false
            }
        });
    }
}
