import {getWelcomeBonusTimestamp} from '../../../utils/user';
import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.isAuth,
    props.user.pending_voucher,
    (isAuth, voucher) => {
        return {
            isAuth,
            active: isAuth ? ((voucher && voucher.code === "WELCOME") ? true : false) : true,
            timestamp: isAuth ? voucher.expire_at : getWelcomeBonusTimestamp()
        }
    }
)