import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.user.logins,
    props.games.games,
    props.modals.data.login_ready,
    props.user.user,
    (logins, games, login_ready, user) => {
        const login = logins.filter(l => l.game === login_ready.game)[0] || false,
              game = games.filter(g => g.slug === login_ready.game)[0] || false;

        return {
            login,
            game,
            user
        }
    }
)