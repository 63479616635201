import {NumericFormat} from 'react-number-format';

export default ({
    value,
    prefix = '$',
    suffix = '',
    fixedDecimalScale = 2,
    className = ''
}) => {
    return <NumericFormat 
                className={className}
                displayType="text" 
                value={value}
                prefix={prefix} 
                suffix={suffix}
                decimalSeparator="." 
                decimalScale={2} 
                fixedDecimalScale={fixedDecimalScale}
                thousandSeparator=" " />
}