import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.invite.loaded,
    (loaded) => {
        return {
            loaded
        }
    }
)