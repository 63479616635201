import {useSelector} from 'react-redux';
import Selector from '../../../selectors/pages/testyourluck/button';
import ModalsLib from '../../../lib/modals';
import {navigate} from '../../../utils/navigation';
import jQuery from 'jquery';

export default () => {
    const {testyourluck, user} = useSelector(Selector),
          deposits = testyourluck ? testyourluck.deposits.filter(d => d.status === 2).length : 0;

    const homePageScrollToGamesHandler = () => {
        const games_container = document.querySelector('.home .games');

        if(games_container) {
            jQuery('html').animate({
                scrollTop: games_container.offsetTop - 100
            }, {
                duration: 200,
                easing: 'linear'
            });
        }
    }

    return (
        <div className="button-block">
            {testyourluck.bonus > 0 ? (
                <>
                    <div className="button" onClick={e => {
                        ModalsLib.open('changeGame', {
                            handler: e => {
                                ModalsLib.open('testyourluck_add', {
                                    game: e
                                })
                            }
                        });
                    }}>Claim ${testyourluck.bonus}</div>
                    {deposits === 3 &&  <div className="status">If you lose another deposit, your bonus amount will increase to <span>$10</span></div>}
                    {deposits === 4 &&  <div className="status">If you lose another deposit, your bonus amount will increase to <span>$15</span></div>}
                    {deposits >= 5 &&  <div className="status">You have reached the <span>maximum</span> bonus amount</div>}
                </>
            ) : (
                <>
                    <div className="button" onClick={e => {
                        if(user.game)
                            navigate(`/game/${user.game}`);
                        else
                            navigate('/', homePageScrollToGamesHandler);
                    }}>
                        Play
                    </div>

                    <div className="status">
                        If you lose <span>{3 - deposits}</span> more deposits, you will receive a free game for <span>$5</span> with the option to withdraw $100.
                    </div>
                </>
            )}
        </div>
    )
}