import {useState} from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../../../selectors/pages/wheel/details';

import NoDeposit from './noDeposit';
import HasSpin from './hasSpin';
import Spin from './spin';

export default () => {
    const {status} = useSelector(Selector);

    

    return (
        <>

            <div className="details">
                <div className="title">SPIN THE WHEEL</div>
                <div className="description">Get prizes every day in the win-win lottery wheel of luck!</div>
            </div>
            {status.status === 0 && <Spin />}
            {status.status === 1 && <NoDeposit />}
            {status.status === 2 && <HasSpin timestamp={status.timestamp} deposits={status.deposits} />}
        </>
    )
}