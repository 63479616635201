import {useEffect, useState, useRef} from 'react';
import Slider from './slider';
import Tabs from './tabs';

export default () => {
    const [scale, setScale] = useState(1),
          [sliderScale, setSliderScale] = useState(1),
          [bottomMargin, setBottomMargin] = useState(0),
          [tabsMargin, setTabsMargin] = useState(0),
          leftRef = useRef(),
          topRef = useRef(),
          location = "/";

    const resizeHandler = () => {
        const width = document.body.offsetWidth,
              width_mobile = leftRef.current ? leftRef.current.offsetWidth : 0,
              width_desktop = topRef.current ? topRef.current.offsetWidth : 0,
              koef = width_mobile / 400;

        // Top block scale
        if(width <= 1267 && width > 800) {
            const nextScale = width_desktop / 1218;

            setScale(nextScale);
            setBottomMargin((413 * nextScale) - 413);
        } else {
            setScale(1);
            setBottomMargin(0);
        }

        // Mobile slider scale
        if(width <= 800) {
            setSliderScale(width_mobile / 400);
            setTabsMargin((268 * koef) - 268);
        } else {
            setSliderScale(1);
            setTabsMargin(0);
        }
    }

    useEffect(() => {
        resizeHandler();
        
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [location]);

    return (
        <div className="top" style={{transform: `scale(${scale}`, marginBottom: `${bottomMargin}px`}} ref={topRef}>
            <div className="left" ref={leftRef}>
                <Slider scale={sliderScale} />
            </div>
            <div className="right" style={{marginTop: `${tabsMargin}px`}}>
                <div className="row-2">
                    <Tabs.VIP />
                    <Tabs.Wheel />    
                </div>
                <div className="row">
                    <Tabs.Refer />
                </div>
            </div>
        </div>
    )
}