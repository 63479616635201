import {navigate} from '../../../../../../utils/navigation';

export default () => {

    return (
        <div className="slide vip size-params">
            <div className="size-params-layer layer0"><div className="silver"></div></div>
            <div className="layer-margin size-params-layer layer1"><div className="gold"></div></div>
            <div className="layer-margin size-params-layer layer2"><div className="platinum"></div></div>
            <div className="layer-margin size-params slide-details">
                <div className="title">
                    Upgrade your<br />VIP status
                </div>
                <div className="descriptions-short">Unlock exclusive benefits! Enjoy bigger bonuses and maximize your earnings with every step!</div>
                <div className="details">
                    <div className="button" onClick={e => navigate('/vip')}>View more</div>
                </div>
            </div>
        </div>
    )
}