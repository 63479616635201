import types from '../types';
import API from '../../../lib/api';
import ModalsLib from '../../../lib/modals';
import {toast} from 'react-toastify';

export const initialize = () => async dispatch => {
    const response = await API.call({
        path: '/payments/client/cashapp_p2p/deposit/initialize',
        method: "POST"
    });

    if(response.success) {
        dispatch({
            type: types.cashapp_deposit.initialize,
            payload: response.status
        });
    } else {
        toast.dismiss();
        toast.error(`The information on the CashApp method could not be obtained. Try again later...`);

        dispatch({
            type: types.preset,
            payload: {
                mode: 'tabs',
                tab: 'deposit'
            }
        });
    }
}

export const createOrder = (data) => async dispatch => {
    // Disable button and clear error
    dispatch({
        type: types.cashapp_deposit.updateStageData,
        payload: {
            error: null,
            ...data, // Save new data
            button: true
        }
    });

    // Make API request
    const response = await API.call({
        path: '/payments/client/cashapp_p2p/deposit/createOrder',
        method: 'POST',
        body: data
    });

    if(!response.success) {
        dispatch({
            type: types.cashapp_deposit.updateStageData,
            payload: {
                error: response.error,
                button: false
            }
        });
    } else {
        dispatch({
            type: types.cashapp_deposit.order,
            payload: response.order
        });

        dispatch({
            type: types.cashapp_deposit.updateStageData,
            payload: {
                button: false
            }
        });

        dispatch({
            type: types.cashapp_deposit.setStage,
            payload: 'confirm'
        });
    }
}

export const confirmOrder = (paymentId, order) => async dispatch => {
    // Disable button and clear error
    dispatch({
        type: types.cashapp_deposit.updateStageData,
        payload: {
            error: null,
            button: true
        }
    });

    // Make API request
    const response = await API.call({
        path: '/payments/client/cashapp_p2p/deposit/approveOrder',
        method: 'POST',
        body: {
            paymentId
        }
    });

    if(!response.success) {
        dispatch({
            type: types.cashapp_deposit.updateStageData,
            payload: {
                error: response.error,
                button: false
            }
        });
    } else {
        dispatch({
            type: types.cashapp_deposit.updateStageData,
            payload: {
                button: false
            }
        });

        dispatch({
            type: types.cashapp_deposit.setStage,
            payload: 'confirmed'
        });
    }
}

export const setStage = stage => {
    return {
        type: types.cashapp_deposit.setStage,
        payload: stage
    }
}

export const clearStages = () => {
    return {
        type: types.cashapp_deposit.clear
    }
}