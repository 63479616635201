import {createSelector} from 'reselect';
import props from '../../../props';

export default createSelector(
    props.vip.profile,
    props.vip.buttons.redeem_monthly,
    props.user.user,
    props.vip.levels,
    (profile, button, user, levels) => {
        const level = user ? levels.filter(lvl => lvl.xp.from <= user.xp && lvl.xp.to > user.xp)[0] || false : false;

        return {
            profile: profile.monthly,
            button,
            level
        }
    }
)