import {useEffect, useState} from 'react';
import Timer from '../../../../utils/timer';

export default ({expire_at, timerKey}) => {
    const [time, setTime] = useState(expire_at - Date.now()),
          timer = Timer.parse(time);

    useEffect(() => {
        if(time > 0)
            window[`expire_timeout_${timerKey}`] = setTimeout(() => {
                setTime(expire_at - Date.now());
            }, 500);

        return () => clearTimeout(window[`expire_timeout_${timerKey}`]);
    }, [time]);

    if(time <= 0)
        return <></>;

    return `${timer.hours != '00' ? `${timer.hours}H` : ''}${timer.hours != '00' ? ` ${timer.minutes}M` : (timer.minutes === '00' ? '' : ` ${timer.minutes}M`)} ${timer.seconds}S`
}