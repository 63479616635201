import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../selectors/pages/page-container';
import {useLocation, useNavigate} from 'react-router-dom';
import jQuery from 'jquery';
import ModalsLib from '../../lib/modals';
import {getNotificationModalTimestamp} from '../../utils/user';

export default ({children}) => {
    const {notifications, user} = useSelector(Selector);

    const pageContainer = useRef(),
          location = useLocation(),
          navigate = useNavigate();

    const locationChangeHandler = e => {
        if(e.detail.path === null && typeof e.detail.afterChange === 'undefined') {
            jQuery('html').animate({
                scrollTop: -10
            }, {
                duration: 490,
                easing: 'linear'
            });
        } else if(e.detail.path !== null) {
            if(pageContainer.current) {
                pageContainer.current.classList.remove('animate__fadeIn');
                pageContainer.current.classList.add('animate__fadeOut');
            }

            jQuery('html').animate({
                scrollTop: -10
            }, {
                duration: 490,
                easing: 'linear'
            });

            setTimeout(() => {
                navigate(e.detail.path);

                setTimeout(() => {
                    if(typeof e.detail.afterChange === 'function')
                        e.detail.afterChange();
                }, 400);
            }, 500);
        } else if(typeof e.detail.afterChange === 'function') {
            e.detail.afterChange();
        }
    }

    useEffect(() => {
        if(user && user.type !== 'telegram-webapp')
            if(notifications.supported && !notifications.registered)
                if(getNotificationModalTimestamp() < Date.now())
                    window.nmtimeout = setTimeout(() => {
                        ModalsLib.open('notifications');
                    }, 5e3);

        window.addEventListener('location.change', locationChangeHandler);
        
        return () => {
            window.removeEventListener('location.change', locationChangeHandler);
            clearTimeout(window.nmtimeout);
        }
    }, [location]);

    return (
        <div className="page-container animate__animated animate__faster animate__fadeIn" ref={pageContainer}>
            {children}
        </div>
    )
}