import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.user,
    props.invite.stats,
    props.vip.levels,
    props.invite.buttons.redeem,
    (user, stats, levels, button) => {
        const current = user ? levels.filter(lvl => lvl.xp.from <= user.xp && lvl.xp.to > user.xp)[0] || false : false;

        return {
            user,
            stats,
            level: current,
            button
        }
    }
)