import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.games.games,
    props.games.history,
    props.user.logins,
    props.games.sessions,
    props.modals.data.deposit_success,
    (games, history, logins, sessions, data) => {
        const transaction = history ? history.list.filter(h => h.transactionId === data.transactionId)[0] || false : false,
              game = transaction ? games.filter(g => g.slug === transaction.game)[0] || false : false,
              login = logins.filter(l => l.login === transaction.login)[0] || false;

        return {
            type: data.type,
            transaction,
            game,
            login,
            sessions
        }
    }
)