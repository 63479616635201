import {navigate} from '../../../utils/navigation';

export default ({onClick=() => {}}) => {

    return (
        <div className="information-button free-cash" onClick={e => {
            onClick();
            navigate('/freecash');
        }}>
            <img src={`${window.location.origin}/source/system/information-button/free-cash.png`} alt="" />

            <div className="details">
                <span>FREE CASH</span>

                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.8225 4.44751L15.375 9.00001L10.8225 13.5525" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2.625 9H15.2475" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    )
}