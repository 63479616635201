import cashapp_deposit from './cashapp_deposit';
import crypto from './crypto';
import card_deposit from './card_deposit';
import bitcoin_deposit from './crypto';

export default {
    cashapp_deposit,
    crypto,
    card_deposit,
    bitcoin_deposit
}