const getTokenInfo = (token) => {
    if(token === 'TRX') return {name: 'TRX', token: 'TRX', network: 'TRC-20'};
    if(token === 'BTC') return {name: 'Bitcoin', token: 'BTC', network: 'Mainnet'};
    if(token === 'ETH') return {name: 'Ethereum', token: 'ETH', network: 'ERC-20'};
    if(token === 'LTC') return {name: 'Litecoin', token: 'LTC', network: 'Mainnet'};
    if(token === 'USDT_TRC20') return {name: 'USDT', token: 'USDT', network: 'TRC-20'};
    if(token === 'USDT_ERC20') return {name: 'USDT', token: 'USDT', network: 'ERC-20'};
    if(token === 'USDC_ERC20') return {name: 'USDC', token: 'USDC', network: 'ERC-20'};
    if(token === 'BNB') return {name: 'BNB', token: 'BNB', network: 'BEP-20'};
    if(token === 'TON') return {name: 'TON', token: 'TON', network: 'Ton'};
    if(token === 'USDT_BSC') return {name: 'USDT', token: 'USDT', network: 'BEP-20'};
    if(token === 'USDT_TON') return {name: 'USDT', token: 'USDT', network: 'Ton'};
    return {name: "N/A", token: 'N/A', network: "N/A"};
}

export default {
    getTokenInfo
}