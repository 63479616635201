import {useState} from 'react';

export default () => {
    const [active_question, setActiveQuestion] = useState(null);

    return (
        <div className="faq">
            <div className="title">FAQ</div>

            <div className="faq-items">
                <div className={`faq-item  ${active_question === 'hiw' ? 'active' : ''}`} onClick={e => setActiveQuestion('hiw' === active_question ? null : 'hiw')}>
                    <div className="faq-question">
                        <span>How to get Cash Card?</span>

                        <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.71074 8.94998L11.9447 15.47C12.799 16.24 14.1969 16.24 15.0512 15.47L22.2852 8.94999" stroke="#30344F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className="faq-answer">
                        <span>
                            1. <b>Open Cash App</b>: Launch the app on your device.<br/>
                            2. <b>Go to the Cash Card Section</b>: Tap one the "<b>Cash Card</b>" option from the home screen or profile menu.<br/>
                            3. <b>Order Cash Card</b>: Tap "<b>Get Free Cash Card</b>".<br/>
                            4. <b>Choose a Design</b>: Select your preferred design for the virtual card.<br/>
                            5. <b>Enter shipping info</b>: You can skip the shipping address if you only want the virtual card.<br/>
                            6. <b>Confirm Order</b>: Complete the process to create your virtual Cash Card.<br/>
                            <br/>
                            <b>That's it!</b> You’ll now have a virtual Cash Card linked to your Cash App account.
                        </span>
                    </div>
                </div>

                <div className={`faq-item  ${active_question === 'hiw2' ? 'active' : ''}`} onClick={e => setActiveQuestion('hiw2' === active_question ? null : 'hiw2')}>
                    <div className="faq-question">
                        <span>How to pay with Cash Card?</span>

                        <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.71074 8.94998L11.9447 15.47C12.799 16.24 14.1969 16.24 15.0512 15.47L22.2852 8.94999" stroke="#30344F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className="faq-answer">
                        A deposit with a CashApp Card is made in the same way as a deposit with any other card.<br/>
                        <br/>
                        1. Specify the deposit amount and Email address for receipt of the check<br/>
                        2. Go to the payment page.<br/>
                        3. Fill in the required information.
                    </div>
                </div>
            </div>
        </div> 
    )
}