export default ({active, onClick}) => (
    <div className={`method ${!active ? 'not-available' : ''}`} onClick={e => {
        if(!active)
            return;

        onClick({
            name: 'USDT',
            network: 'BEP-20',
            token_name: "USDT",
            token: "USDT_BSC"
        });
    }}>
        <div className="info">
            <div className="icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1275_6859)">
                        <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#26A17B"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7529 19.6895V19.6874C19.6341 19.6959 19.0218 19.7327 17.6556 19.7327C16.5648 19.7327 15.797 19.7003 15.527 19.6874V19.6905C11.3279 19.5059 8.19369 18.7748 8.19369 17.9C8.19369 17.0262 11.3279 16.2951 15.527 16.1072V18.9627C15.8012 18.9821 16.5875 19.0286 17.6739 19.0286C18.9776 19.0286 19.631 18.9746 19.7529 18.9638V16.1093C23.9433 16.2962 27.0699 17.0273 27.0699 17.9C27.0699 18.7748 23.9433 19.5038 19.7529 19.6895ZM19.7529 15.8123V13.257H25.6001V9.36035H9.67977V13.257H15.527V15.8112C10.7749 16.0293 7.20117 16.9712 7.20117 18.0987C7.20117 19.2261 10.7749 20.1669 15.527 20.3861V28.5747H19.7529V20.384C24.4974 20.1657 28.0625 19.2251 28.0625 18.0987C28.0625 16.9722 24.4974 16.0316 19.7529 15.8123Z" fill="white"/>
                        <path d="M36 27.75C36 23.1937 32.3063 19.5 27.75 19.5C23.1937 19.5 19.5 23.1937 19.5 27.75C19.5 32.3063 23.1937 36 27.75 36C32.3063 36 36 32.3063 36 27.75Z" fill="#F3BA2F"/>
                        <path d="M28.6457 30.3587V31.2429L27.8747 31.6965L27.1263 31.2429V30.3587L27.8747 30.8121L28.6457 30.3587ZM24.5186 27.2973L25.2669 27.7509V29.2701L26.5595 30.0411V30.9255L24.5186 29.7237V27.2973ZM31.2308 27.2973V29.7237L29.1672 30.9255V30.0411L30.4598 29.2701V27.7509L31.2308 27.2973ZM29.1672 26.0955L29.9382 26.549V27.4334L28.6457 28.2044V29.7464L27.8973 30.1998L27.149 29.7464V28.2044L25.8111 27.4334V26.549L26.5821 26.0955L27.8747 26.8665L29.1672 26.0955ZM25.8111 28.0683L26.5595 28.5218V29.4062L25.8111 28.9527V28.0683ZM29.9382 28.0683V28.9527L29.1899 29.4062V28.5218L29.9382 28.0683ZM25.2669 25.3245L26.0379 25.778L25.2669 26.2316V27.116L24.5186 26.6624V25.778L25.2669 25.3245ZM30.4824 25.3245L31.2534 25.778V26.6624L30.4824 27.116V26.2316L29.7341 25.778L30.4824 25.3245ZM27.8747 25.3245L28.6457 25.778L27.8747 26.2316L27.1263 25.778L27.8747 25.3245ZM27.8747 23.8052L29.9382 25.007L29.1899 25.4606L27.8973 24.6896L26.5821 25.4606L25.8338 25.007L27.8747 23.8052Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1275_6859">
                            <rect width="36" height="36" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div className="name">USDT <span>BEP-20</span></div>
        </div>

        {
            !active 
            ? <div className="status">Soon</div>
            : <div className="status bonus">Bonus <b>+7%</b></div>
        }
    </div>
)