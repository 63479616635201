export default {
    levels_loaded: ({vip}) => vip.levels_loaded,
    levels: ({vip}) => vip.levels,

    loaded: ({vip}) => vip.loaded,
    profile: ({vip}) => vip.profile,
    history: ({vip}) => vip.history,

    buttons: {
        redeem_lvl_up: ({vip}) => vip.buttons.redeem_lvl_up,
        redeem_weekly: ({vip}) => vip.buttons.redeem_weekly,
        redeem_monthly: ({vip}) => vip.buttons.redeem_monthly
    }
}