import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/vip/progress';
import {getIcon} from '../levels';
import Numeric from '../../../components/numeric';
import tippy from 'tippy.js';

export default () => {
    const {current, next, user} = useSelector(Selector);

    useEffect(() => {
        tippy('#vip-progress', {
            placement: "bottom",
            animation: 'fade',
            theme: 'translucent',
            arrow: true,
            content: `You need to get <b>${parseInt(next.xp.from - user.xp)} XP</b> to advance to the next level!`,
            allowHTML: true
        });
    }, []);

    return (
        <div className={`progress ${current.group}`}>
            <div className="layer">
                <div className="current_level">
                    {getIcon(current.icon)}
                    <span>{current.name}</span>
                </div>
            </div>
            <div className="layer">
                <div className="level">
                    <div className="levels">
                        <div className="info">
                            {getIcon(current.icon)}
                            {current.name}
                        </div>
                        <div className="info">
                            {getIcon(next.icon)}
                            {next.name}
                        </div>
                    </div>
                    <div className="progress-bar">
                        <div className="line" id="vip-progress">
                            <div className="line-progress" style={{
                                width: `${(user.xp / current.xp.to) * 100}%`
                            }}></div>
                        </div>
                        <div className="values">
                            <div className="value">
                                <Numeric value={current.xp.from} fixedDecimalScale={0} prefix={''} /> XP
                            </div>
                            <div className="value">
                                <Numeric value={current.xp.to} fixedDecimalScale={0} prefix={''} /> XP
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}