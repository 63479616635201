export default {
    tab: "wallet.tab",
    preset: "wallet.preset",
    bonuses: {
        update: "wallet.bonuses.update",
        toggleButton: "wallet.bonuses.toggleButton"
    },
    history: {
        add: "wallet.history.add",
        edit: "wallet.history.edit",
        loaded: "wallet.history.loaded",
        toggle: "wallet.history.toggle",
        toggleButton: "wallet.history.toggle.button"
    },
    crypto_deposit: {
        loaded: "wallet.crypto_deposit.loaded",
        change: "wallet.crypto_deposit.change"
    },
    cards_deposit: {
        setStage: "wallet.cards_deposit.stage",
        toggleStageButton: "wallet.cards_deposit.toggle.stage.button",
        updateStageData: "wallet.cards_deposit.update.stage.data",
        order: "wallet.cards_deposit.order",
    },
    cashapp_deposit: {
        setStage: "wallet.cashapp_deposit.stage",
        toggleStageButton: "wallet.cashapp_deposit.toggle.stage.button",
        updateStageData: "wallet.cashapp_deposit.update.stage.data",
        order: "wallet.cashapp_deposit.order",
        initialize: "wallet.cashapp_deposit.initialize",
        clear: "wallet.cashapp_deposit.clear"
    },
    cashapp_cashout: {
        setStage: "wallet.cashout_deposit.stage",
        toggleStageButton: "wallet.cashout_deposit.toggle.stage.button",
        updateStageData: "wallet.cashout_deposit.update.stage.data",
        order: "wallet.cashout_deposit.order",
        initialize: "wallet.cashout_deposit.initialize",
        clear: "wallet.cashout_deposit.clear"
    }
}