import types from './types';
import globalTypes from '../types';

const InitialState = {
    loaded: false,
    games: null,
    game: null,
    sessions: null,
    testyourluck: null,
    history: {
        count: 0,
        list: []
    },
    winners: null,
    buttons: {
        requestLogin: false,
        removeLogin: false,
        requestDownloadCode: false,
        updateBalance: false,
        addCash: false,
        cashOut: false,
        resetPassword: false,
        testyourluck: false,
        freeplay: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.winner:
            return {
                ...state,
                winners: state.winners ? [
                    {...payload, timestamp: payload.timestamp + Date.now()},
                    ...state.winners
                ] : state.winners
            }
        case globalTypes.user.logout:
            return {
                ...state,
                game: null,
                testyourluck: null,
                history: []
            }
        case types.testyourluck:
            return {
                ...state,
                testyourluck: {
                    ...payload,
                    deposits: payload.deposits.sort((a,b) => {
                        if(a.timestamp < b.timestamp) return 1;
                        if(a.timestamp > b.timestamp) return -1;
                        return 0;
                    }),
                    timestamp: payload.timestamp + Date.now()
                }
            }
        case types.history.update:
            return {
                ...state,
                history: state.history ? {
                    ...state.history,
                    list: state.history.list.map(tx => {
                        return tx.transactionId === payload.transactionId ? {
                            ...payload,
                            timestamp: Date.now() + payload.timestamp
                        } : tx
                    })
                } : state.history
            }
        case types.history.add:
            return {
                ...state,
                history: state.history ? {
                    ...state.history,
                    count: state.history.count + 1,
                    list: [
                        {
                            ...payload,
                            timestamp: Date.now() + payload.timestamp
                        },
                        ...state.history.list
                    ]
                } : state.history
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                games: payload.games.sort((a, b) => {
                    if(a.maintenance && !b.maintenance) return 1;
                    if(!a.maintenance && b.maintenance) return -1;
                    return 0;
                }),
                sessions: payload.sessions,
                winners: payload.winners.map(winner => {
                    return {
                        ...winner,
                        timestamp: Date.now() + winner.timestamp
                    }
                }).sort((a,b) => {
                    if(a.timestamp > b.timestamp) return -1;
                    if(a.timestamp < b.timestamp) return 1;
                    return 0;
                })
            }
        case types.game:
            return {
                ...state,
                game: payload.game,
                history: payload.history ? {
                    ...payload.history,
                    list: payload.history.list.map(tx => {
                        return {
                            ...tx,
                            timestamp: Date.now() + tx.timestamp
                        }
                    })
                } : state.history,
                testyourluck: payload.testyourluck ? {
                    ...payload.testyourluck,
                    deposits: payload.testyourluck.deposits.sort((a,b) => {
                        if(a.timestamp < b.timestamp) return 1;
                        if(a.timestamp > b.timestamp) return -1;
                        return 0;
                    }),
                    timestamp: payload.testyourluck.timestamp + Date.now()
                } : state.testyourluck
            }
        default:
            return state;
    }
}