import {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/freecash/promocode';
import {activatePromocode} from '../../../reducers/freecash/actions';
import WhiteButton from '../../components/white-button';
import {toast} from 'react-toastify';

export default () => {
    const {button} = useSelector(Selector),
          dispatch = useDispatch();

    const [promocode, setPromocode] = useState('');

    return (
        <div className="block promocode">
            <div className="layer">
                <div className="coupon"></div>
                <div className="coupon"></div>
            </div>
            <div className="layer">
                <div className="content">
                    <div className="title">
                        ACTIVATE<br/>
                        PROMOCODE
                    </div>
                    <div className="descriptions">Exclusive Vouchers on Social Media.<br/>More Follows – More Chances to Win Big!</div>
                    <div className="input">
                        <input type="text" placeholder="Enter promocode" onInput={e => {
                            setPromocode(e.target.value.replace(/[^0-9a-z]/gi, ''))
                        }} />
                        <WhiteButton active={button} onClick={e => {
                            if(promocode.length < 1) {
                                toast.dismiss();
                                toast.warning(`You did not specify the voucher code.`);
                            } else {
                                dispatch(activatePromocode(promocode));
                            }
                        }}>Activate</WhiteButton>
                    </div>
                </div>
            </div>
        </div>
    )
}