import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.user,
    props.invite.stats,
    (user, stats) => {
        return {
            user,
            stats
        }
    }
)