import Types from '../reducers/types';
import {dispatch} from '../lib/store';
import ModalsLib from '../lib/modals';

let connection = null;

const state = {
    retries: 0,
    userId: null,
    userStringId: null,
    initialized: false,
    authorized: false
}

const log = log => console.log(`[WS] ${log}`),
      send = data => connection.send(JSON.stringify(data));

const connect = () => {
    if(state.retries >= 1)
        dispatch({
            type: Types.app.connection,
            payload: 'connecting'
        });

    connection = new WebSocket(`${window.location.origin.replace('http', 'ws')}/ws`);

    connection.onopen = () => {
        log(`The connection is established!`);

        if(state.retries >= 1)
            dispatch({
                type: Types.app.connection,
                payload: 'connected'
            });

        // Переподключение по UserID
        console.log(state.authorized, state.userId, state.userStringId);
        if(!state.authorized && state.userId !== null && state.userStringId !== null)
            authorize(state.userId, state.userStringId);
    }

    connection.onmessage = res => {
        try {
            res = JSON.parse(res.data);
        } catch(e) {
            return log(`Wrong response`, res);
        }

        switch(res.type) {
            case 'winner':
                return dispatch({
                    type: Types.games.winner,
                    payload: res.payload
                });
            case 'logout':
                return log(`Logged out of the user socket`);
            case 'got_ping':
                return log(`PING: ${Math.floor((Date.now() - window.ping_timestamp) / 2)}ms`);
            case 'ping':
                window.ping_timestamp = Date.now();
                return send({
                    type: 'pong'
                });
            case 'testyourluck':
                return dispatch({
                    type: Types.games.testyourluck,
                    payload: res.payload
                });
            case 'wheel.spin':
                return setTimeout(() => {
                    dispatch({
                        type: Types.wheel.history.all,
                        payload: res.payload
                    });
                }, 5e3);
            case 'vip.profile':
                return dispatch({
                    type: Types.vip.profile,
                    payload: res.payload
                });
            case 'voucher_pending':
                return dispatch({
                    type: Types.user.pending_voucher,
                    payload: res.payload
                });
            case 'wallet_deposit_success':
                return ModalsLib.open('wallet_deposit_success', {
                    ...res.payload,
                    transaction: {
                        ...res.payload.transaction,
                        timestamp: Date.now() + res.payload.transaction.timestamp
                    }
                });
            case 'wallet_deposit_failed':
                return ModalsLib.open('wallet_deposit_failed', {
                    ...res.payload,
                    transaction: {
                        ...res.payload.transaction,
                        timestamp: Date.now() + res.payload.transaction.timestamp
                    }
                });
            case 'vip.xp':
                return dispatch({
                    type: Types.user.vip.xp,
                    payload: res.payload
                });
            case 'history.add':
                return dispatch({
                    type: Types.wallet.history.add,
                    payload: res.payload
                });
            case 'history.edit':
                return dispatch({
                    type: Types.wallet.history.edit,
                    payload: res.payload
                });
            case 'downloadCode':
                return dispatch({
                    type: Types.modals.downloadCode,
                    payload: res.payload
                });
                // return window.alert(JSON.stringify(res));
            case 'cashout_success':
                return ModalsLib.open('cashout_success', res.payload);
            case 'cashout_failed':
                return ModalsLib.open('cashout_failed', res.payload);
            case 'deposit_failed':
                return ModalsLib.open('deposit_failed', res.payload);
            case 'deposit_success':
                return ModalsLib.open(`deposit_success`, res.payload);
            case 'login_reset_done':
                return ModalsLib.open('login_reset_done', res.payload);
            case 'authorized':
                state.authorized = true;
                log(`Connected the user to a socket`);
                break;
            case 'balance':
                return dispatch({
                    type: Types.user.balance,
                    payload: res.payload
                });
            case 'balance_timeout':
                return setTimeout(() => {
                    dispatch({
                        type: Types.user.balance,
                        payload: res.payload.payload
                    });
                }, res.payload.timeout);
            case 'games.history.update':
                return dispatch({
                    type: Types.games.history.update,
                    payload: res.payload
                })
            case 'login.update':
                if(res.payload.created)
                    ModalsLib.open('login_ready', {
                        game: res.payload.game
                    });

                return dispatch({
                    type: Types.user.logins.update,
                    payload: res.payload
                });
            default:
                return console.log(res.type, res.payload);  
        }
    }

    connection.onclose = () => {
        log(`Connection to the server is lost. Retry in 3 seconds...`);

        state.authorized = false;
        state.retries++;

        dispatch({
            type: Types.app.connection,
            payload: 'lost'
        });

        return setTimeout(connect, 3e3);
    }
}

const logout = () => send({type: 'logout'});

const authorize = (userId, userStringId) => {
    if(state.userId === null || state.userStringId === null) {
        state.userId = userId;
        state.userStringId = userStringId;
    }

    if(connection.readyState === WebSocket.OPEN)
        send({
            type: 'authorize',
            payload: {
                userId,
                userStringId
            }
        });
    else
        setTimeout(authorize, 3e3, userId, userStringId);
}

export default {
    connect,
    authorize,
    logout
}