import {useState, useEffect} from 'react';
import Timer from '../../../../../utils/timer';

export default ({timestamp, timer_key, children}) => {
    const [time, setTime] = useState(timestamp - Date.now());

    useEffect(() => {
        if(time > 0)
            window[`${timer_key}_timer_timeout`] = setTimeout(() => {
                setTime(timestamp - Date.now());
            }, 20e3);

        return () => clearTimeout(window[`${timer_key}_timer_timeout`]);
    }, [time]);

    const timer = Timer.parse(time, true, true);

    if(time <= 0)
        return children;

    return (
        <div className="timer">
            <div className="sector">
                <div className="time">{timer.days}</div>
                days
            </div>
            <div className="dots">
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
            <div className="sector">
                <div className="time">{timer.hours}</div>
                hours
            </div>
            <div className="dots">
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
            <div className="sector">
                <div className="time">{timer.minutes}</div>
                minutes
            </div>
        </div>
    )
}