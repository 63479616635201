export default {
    loaded: ({games}) => games.loaded,
    games: ({games}) => games.games,
    game: ({games}) => games.game,
    history: ({games}) => games.history,
    sessions: ({games}) => games.sessions,
    testyourluck: ({games}) => games.testyourluck,
    winners: ({games}) => games.winners,
    buttons: {
        requestLogin: ({games}) => games.buttons.requestLogin,
        requestDownloadCode: ({games}) => games.buttons.requestDownloadCode,
        removeLogin: ({games}) => games.buttons.removeLogin,
        updateBalance: ({games}) => games.buttons.updateBalance,
        addCash: ({games}) => games.buttons.addCash,
        cashOut: ({games}) => games.buttons.cashOut,
        resetPassword: ({games}) => games.buttons.resetPassword,
        testyourluck: ({games}) => games.buttons.testyourluck,
        freeplay: ({games}) => games.buttons.freeplay
    } 
}