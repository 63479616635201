import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.vip.history,
    (history) => {
        return {
            history
        }
    }
)