import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Wheel from './wheel';
import Details from './details';

export default () => {
    const [scale, setScale] = useState(1),
          [margin, setMargin] = useState(40),
          [marginBottom, setMarginBottom] = useState(0),
          location = useLocation();

    const resizeHandler = () => {
        const width = document.body.offsetWidth,
              coef = width / 1185,
              mobile_coef = width /  545;

        // Desktop scale
        if(width < 1185 && width > 800) {
            setScale(coef);
            setMargin((width / 1185) * 40);
            setMarginBottom((515 * coef) - 515);
        } else {
            if(width <= 800) {
                setMargin(0);
                setScale(width / (515 + 30));
                setMarginBottom((926 * mobile_coef) - 926);
            } else {
                setScale(1);
                setMargin(40);
                setMarginBottom(0);
            }
        }
    }

    useEffect(() => {
        resizeHandler();
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [location]);
 
    return (
        <div className="wheel-main" style={{transform: `scale(${scale})`, marginTop: `${margin}px`, marginBottom: `${marginBottom}px`}}>
            <div className="left">
                <div className="layer visible">
                    <div className="pretty-girl"></div>
                </div>
                <div className="layer content">
                    <Details />
                </div>
            </div>
            <div className="right">
                <Wheel />
            </div>
        </div>
    )
}