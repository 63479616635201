import {createSelector} from 'reselect';
import props from '../../../../props';
import { float } from '../../../../../utils/formats';

export default createSelector(
    props.wallet.methods.cashapp_cashout,
    props.user.user,
    (cashapp_cashout, user) => {
        return {
            props: cashapp_cashout[cashapp_cashout.stage],
            user,
            available_balance: user ? float(user.balance - user.wager) : 0
        }
    }
)