export default ({
    deposits_count
}) => {

    return (
        <div className="progress">
            <div className={`point ${deposits_count >= 1 ? 'active' : ''}`}>
                <div className="amount">$0</div>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2797 10.0333L8.93306 5.68664C8.41973 5.1733 7.57973 5.1733 7.06639 5.68664L2.71973 10.0333" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div className="num">1</div>
            </div>

            <div className="line-block">
                <div className="lore">Need more deposits</div>
                <div className="line">
                    <div className="line-progress" style={{width: deposits_count > 1 ? (deposits_count === 2 ? '50%' : '100%') : '0%'}}></div>
                </div>
            </div>

            <div className={`point ${deposits_count >= 3 ? 'active' : ''}`}>
            <div className={`amount ${deposits_count >= 3 ? 'won' : ''}`}>$5</div>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2797 10.0333L8.93306 5.68664C8.41973 5.1733 7.57973 5.1733 7.06639 5.68664L2.71973 10.0333" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div className="num">3</div>
            </div>

            <div className="line-block">
                <div className="lore"></div>
                <div className="line">
                    <div className="line-progress" style={{width: deposits_count >= 4 ? '100%' : '0%'}}></div>
                </div>
            </div>

            <div className={`point ${deposits_count >= 4 ? 'active' : ''}`}>
            <div className={`amount ${deposits_count >= 4 ? 'won' : ''}`}>$10</div>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2797 10.0333L8.93306 5.68664C8.41973 5.1733 7.57973 5.1733 7.06639 5.68664L2.71973 10.0333" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div className="num">4</div>
            </div>

            <div className="line-block">
                <div className="lore"></div>
                <div className="line">
                <div className="line-progress" style={{width: deposits_count >= 5 ? '100%' : '0%'}}></div>
                </div>
            </div>

            <div className={`point ${deposits_count >= 5 ? 'active' : ''}`}>
                <div className={`amount ${deposits_count >= 5 ? 'won' : ''}`}>$15</div>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2797 10.0333L8.93306 5.68664C8.41973 5.1733 7.57973 5.1733 7.06639 5.68664L2.71973 10.0333" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div className="num">5</div>
            </div>
        </div>
    )
}