import app from './app';
import user from './user';
import games from './games';
import modals from './modals';
import vip from './vip';
import wallet from './wallet';
import wheel from './wheel';
import invite from './invite';
import freecash from './freecash';

export default {
    app,
    user,
    games,
    modals,
    vip,
    wallet,
    wheel,
    invite,
    freecash
}