import {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/modals/wallet/bonuses';
import {activate} from '../../../../../reducers/wallet/actions/bonuses';
import Input from '../../../../components/form-input';
import ButtonBlue from '../../../../components/blue-button';

export default () => {
    const {error, message, button} = useSelector(Selector),
          dispatch = useDispatch();

    const [state, setState] = useState({
        code: "",
        validators: false
    });

    return (
        <>
            {(!error && !message) && (
                <div className="modal-message info center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5L11.5 14L14.75 11" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <span  style={{width: 'calc(100% - 24px - 10px)'}}>
                        If you have a promo code, you can activate it here!
                    </span>
                </div>
            )}

            {error && (
                <div class="modal-message warning center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <span dangerouslySetInnerHTML={{__html: error}} style={{width: 'calc(100% - 24px - 10px)'}}></span>
                </div>
            )}

            {message && (
                <div className="modal-message success center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0CDA50" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5L11.5 14L14.75 11" stroke="#0CDA50" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span dangerouslySetInnerHTML={{__html: message}} style={{width: 'calc(100% - 24px - 10px)'}}></span>
                </div>
            )}

            <div className="form-group">
                <Input
                    label={'Enter promcode'}
                    type={'text'}
                    placeholder={'Enter promocode'}
                    validators={[
                        val => val.length > 3
                    ]}
                    onChange={({value, validator}) => {
                        setState({
                            ...state,
                            code: value,
                            validators: validator
                        });
                    }}
                    />
            </div>

            <ButtonBlue
                disabled={!state.validators}
                active={button}
                onClick={e => {
                    dispatch(activate({
                        code: state.code
                    }))
                }}
                >
                Activate
            </ButtonBlue>
        </>
    )
}