export default {
    loaded: "wheel.loaded",
    wheel: null,
    unload: "wheel.unload",
    toggleButton: "wheel.toggle.button",
    rotate: "wheel.rotate",
    status: "wheel.status",
    spin: "wheel.spin",
    history: {
        all: "wheel.history.all",
        my: "wheel.history.my"
    }
}