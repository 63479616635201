export default {
    mobile: 'app.mobile',
    sidebar: "app.sidebar",
    connection: 'app.connection',
    init: "app.init",
    notifications: {
        init: "app.notifications.init",
        button: "app.notifications.button",
        update: "app.notifications.update"
    }
}