import types from './types';
import API from '../../lib/api';
import WS from '../../lib/websocket';
import {toast} from 'react-toastify';
import {requestForToken} from '../../lib/firebase';

export const setGame = game => {
    return {
        type: types.game,
        payload: game
    }
}

export const getUser = () => async dispatch => {
    const response = await API.call({
        path: '/auth/user/get'
    });

    if(!response.success)
        return toast.error(response.error);

    if(response.user)
        WS.authorize(response.user.userId, response.user.userStringId);

    dispatch({
        type: types.loaded,
        payload: {
            user: response.user,
            logins: response.logins,
            pending_voucher: response.pending_voucher ? {
                ...response.pending_voucher,
                expire_at: Date.now() + response.pending_voucher.expire_at
            } : response.pending_voucher
        }
    });

    if(response.user && response.user.type !== 'telegram-webapp') {
        // Authorize push token
        if(Notification.permission === 'granted') {
            const token = await requestForToken();

            if(token)
                await API.call({
                    path: '/push/authorize',
                    body: {
                        token
                    }
                });
        }
    }
}

export const getTelegramUser = query => async dispatch => {
    const response = await API.call({
        path: '/auth/user/telegram',
        body: {
            query
        }
    });

    if(!response.success)
        return toast.error(response.error);

    const userResponse = await API.call({
        path: '/auth/user/get'
    });

    if(!userResponse.success)
        return toast.error(userResponse.error);

    if(userResponse.user)
        WS.authorize(userResponse.user.userId, userResponse.user.userStringId);

    dispatch({
        type: types.loaded,
        payload: {
            user: userResponse.user,
            logins: userResponse.logins,
            pending_voucher: userResponse.pending_voucher ? {
                ...userResponse.pending_voucher,
                expire_at: Date.now() + userResponse.pending_voucher.expire_at
            } : userResponse.pending_voucher
        }
    });
}

export const logout = () => async dispatch => {
    const response = await API.call({
        path: '/auth/user/logout',
    });

    if(response.success) {
        dispatch({
            type: types.logout
        });

        WS.logout();
    }
}