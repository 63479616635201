import types from './types';
import API from '../../lib/api';
import {toast} from 'react-toastify';

export const loadVipLevels = () => async dispatch => {
    const response = await API.call({
        path: '/vip/getLevels'
    });

    if(response.success)
        dispatch({
            type: types.levels_loaded,
            payload: {
                levels: response.levels
            }
        });
}

export const loadVip = () => async dispatch => {
    const response = await API.call({
        path: '/vip/load'
    });

    if(response.success)
        dispatch({
            type: types.loaded,
            payload: response.data
        }); 
}

export const redeemCashback = type => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: `redeem_${type}`
    });

    const response = await API.call({
        path: '/vip/redeem',
        body: {
            type
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: `redeem_${type}`
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
    } else {
        toast.dismiss();
        toast.success(response.message);

        dispatch({
            type: types.history.add,
            payload: response.transaction
        });
    }
}