import {useEffect, useRef} from 'react';
import QRCodeStyling from "qr-code-styling";

const qrCode = new QRCodeStyling({
    width: 250,
    height: 250,
    margin: 0,
    backgroundOptions: {
        color: "#101626"
    },
    dotsOptions: {
        color: "#fff",
        type: "rounded"
    },
    cornersSquareOptions: {
        type: "extra-rounded",
        color: "#fff"
    },
    qrOptions: {
        typeNumber: 0,
        mode: 'Byte',
        errorCorrectionLevel: 'H',
    }
});

export default ({address}) => {
    const ref = useRef();

    useEffect(() => {
        qrCode.update({data: address});
    }, [address]);

    useEffect(() => {
        qrCode.append(ref.current);
    }, []);

    return <div className="modal-qrcode" ref={ref}></div>
}
