import {createSelector} from 'reselect';
import props from '../../../props';

export default createSelector(
    props.wheel.history.my,
    (history) => {
        return {
            history
        }
    }
)