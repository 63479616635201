import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.app.notifications.supported,
    props.app.notifications.registered,
    props.user.user,
    (supported, registered, user) => {
        return {
            notifications: {
                supported,
                registered
            },
            user
        }
    }
)