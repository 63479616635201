import {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/pages/wheel/wheel';
import {makeSpin} from '../../../../../reducers/wheel/actions';
import LightSector from './light-sector';
import DarkSector from './dark-sector';
import WheelSelector from './selector';

export default () => {
    const {wheel, button} = useSelector(Selector),
          dispatch = useDispatch(),
          circle_ref = useRef();

    useEffect(() => {
        if(wheel.spin)
            if(circle_ref.current) {
                circle_ref.current.style.transition = 'unset';
                circle_ref.current.style.transform = `rotate(${wheel.spin.from}deg)`;

                setTimeout(() => {
                    circle_ref.current.style.transition = '';
                    circle_ref.current.style.transform = `rotate(${wheel.spin.to}deg)`;
                }, 20); 
            }
    }, [wheel.spin]);

    return (
        <div className="wheel">
            <div className="selector"><WheelSelector /></div>
            <div className="lights neutral">
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
            </div>
            <div className="sectors">
                <div className="row" style={{transform: `rotate(${wheel.rotate}deg)`}} ref={circle_ref}>
                    <div className="sector light">
                        <LightSector />
                        <div className="values">
                            {wheel.sectors[1].map((sector, key) => {
                                if(key % 2 === 0)
                                    return (
                                        <div className="row" key={key}>
                                            <div className={`value ${wheel.sectors[1][key].type}`}>
                                                <div className="name">{wheel.sectors[1][key].name}</div>
                                                <div className="sector-value">{wheel.sectors[1][key].value}</div>
                                            </div>
                                            <div className={`value ${wheel.sectors[1][key+1].type}`}>
                                                <div className="name">{wheel.sectors[1][key+1].name}</div>
                                                <div className="sector-value">{wheel.sectors[1][key+1].value}</div>
                                            </div>
                                        </div>
                                    )
                                return <></>;
                            })}
                        </div>
                    </div>

                    <div className="sector dark">
                        <DarkSector />
                        <div className="values">
                            {wheel.sectors[0].map((sector, key) => {
                                if(key % 2 === 0)
                                    return (
                                        <div className="row" key={key}>
                                            <div className={`value ${wheel.sectors[0][key].type}`}>
                                                <div className="name">{wheel.sectors[0][key].name}</div>
                                                <div className="sector-value">{wheel.sectors[0][key].value}</div>
                                            </div>
                                            <div className={`value ${wheel.sectors[0][key+1].type}`}>
                                                <div className="name">{wheel.sectors[0][key+1].name}</div>
                                                <div className="sector-value">{wheel.sectors[0][key+1].value}</div>
                                            </div>
                                        </div>
                                    )
                                return <></>;
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-block">
                <div className="button-circle">
                    <div className={`button ${button ? 'active' : ''}`} onClick={e => {
                        if(button)
                            return;

                        dispatch(makeSpin());
                    }}>SPIN</div>
                </div>
            </div>
            <div className="circle"></div>
        </div>
    )
}