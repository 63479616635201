import types from './types';
import API from '../../lib/api';
import {toast} from 'react-toastify';

export const loadInvite = () => async dispatch => {
    const response = await API.call({
        path: '/invite/load'
    });

    if(response.success)
        dispatch({
            type: types.loaded,
            payload: response.data
        }); 
}

export const redeemBalance = () => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'redeem'
    });

    const response = await API.call({
        path: '/invite/redeem'
    });

    dispatch({
        type: types.toggleButton,
        payload: 'redeem'
    });

    if(response.success) {
        dispatch({
            type: types.resetAmount
        });

        toast.dismiss();
        toast.success(response.message);
    } else {
        toast.dismiss();
        toast.error(response.error);
    }
}