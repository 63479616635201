import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.modals.data.signup,
    props.app.referral_bonus,
    props.app.affiliate_bonus,
    (data, referral_bonus, affiliate_bonus) => {
        return {
            data,
            referral_bonus,
            affiliate_bonus
        }
    }
)