const parse = (timestamp, pretty = false, roundedMinutes = false) => {
    const days = Math.floor(timestamp / (24 * 60 * 60 * 1e3));
    timestamp -= days * (24 * 60 * 60 * 1e3);

    const hours = Math.floor(timestamp / (60 * 60 * 1e3));
    timestamp -= hours * (60 * 60 * 1e3);

    const minutes = roundedMinutes ? Math.ceil(timestamp / (60 * 1e3)) : Math.floor(timestamp / (60 * 1e3));
    timestamp -= minutes * (60 * 1e3);

    const seconds = Math.floor(timestamp / 1e3);

    return {
        days: (pretty && days < 10) ? `0${days}` : days,
        hours: (pretty && hours < 10) ? `0${hours}` : hours,
        minutes: (pretty && minutes < 10) ? `0${minutes}` : minutes,
        seconds: (pretty && seconds < 10) ? `0${seconds}` : seconds,
    }
}

export default {
    parse
}