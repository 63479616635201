import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.modals.data.changeGame,
    props.games.games,
    props.user.logins,
    (data, games, logins) => {
        const list = [];

        for(const game of games) {
            const login = logins.filter(l => l.game === game.slug)[0] || false;

            list.push({
                ...game,
                login
            });
        }

        return {
            data,
            games: list
        }
    }
)