import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/modals/wallet/methods/cards_deposit/stage';
import {setPreset} from '../../../../../reducers/wallet/actions';
import {setStage} from '../../../../../reducers/wallet/actions/cards_deposit';
import BlueButton from '../../../../components/blue-button';
import ModalsLib from '../../../../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

export default () => {
    const {props} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Debit card</div>
                    <div className="descriptions">Review the payment details, then click the «Pay» button to go to the payment page</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="payment-provider">
                    <div className="left">
                        <div className="icon cards">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.83325 11.5591H17.4166" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M17.4166 9.42332V15.9775C17.3891 18.59 16.6741 19.25 13.9516 19.25H5.29828C2.52995 19.25 1.83325 18.5625 1.83325 15.8308V9.42332C1.83325 6.94832 2.41075 6.15082 4.58325 6.02248C4.80325 6.01332 5.04161 6.00415 5.29828 6.00415H13.9516C16.7199 6.00415 17.4166 6.69165 17.4166 9.42332Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M20.1666 6.16917V12.5767C20.1666 15.0517 19.5891 15.8492 17.4166 15.9775V9.42333C17.4166 6.69167 16.7199 6.00417 13.9516 6.00417H5.29828C5.04161 6.00417 4.80325 6.01333 4.58325 6.0225C4.61075 3.41 5.32578 2.75 8.04828 2.75H16.7016C19.4699 2.75 20.1666 3.4375 20.1666 6.16917Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M4.8125 16.3258H6.38914" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M8.35083 16.3258H11.5042" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                        <div className="name">Debit Card</div>
                    </div>
                    <CopyToClipboard text={`Invoice for payment #${props.order.paymentId} in the amount of ${props.order.amounts.amount} for ${props.order.email}`} onCopy={e => {
                        toast.dismiss();
                        toast.info(`Invoice details has been copied to the clipboard`);
                    }}>
                        <div className="right">
                            #{props.order.paymentId}

                            <div className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                    <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                </svg>
                            </div>
                        </div>
                    </CopyToClipboard>
                </div>

                <div className="payment-details">
                    <div className="payment-detail">
                        <div className="row">
                            <div className="label">Payment ID</div>
                            <div className="value">#{props.order.paymentId}</div>
                        </div>
                        <div className="row">
                            <div className="label">Receipt email</div>
                            <div className="value">{props.order.email}</div>
                        </div>
                    </div>

                    {props.order.amounts.comission > 0 && (
                        <div className="payment-detail">
                            <div className="row">
                                <div className="label">Processing fee</div>
                                <div className="value">${props.order.amounts.comission}</div>
                            </div>
                        </div>
                    )}

                    <div className="payment-detail">
                        <div className="row">
                            <div className="label">Total amount</div>
                            <div className="value">${props.order.amounts.total}</div>
                        </div>
                    </div>
                </div>

                <div className="modal-message info">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5L11.5 14L14.75 11" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>You have 20 minutes to pay the bill, after which the payment will be automatically closed</span>
                </div>
            </div>
            <div className="modal-footer">
                <BlueButton active={props.button} onClick={e => {
                    window.open(props.order.url, '_blank')
                }}>
                    Pay
                </BlueButton>
                <div className="button back" onClick={e => dispatch(setStage('create'))}>
                    Back
                </div>
            </div>
        </>
    )
}