import {useSelector} from 'react-redux';
import Selector from '../../../selectors/modals/deposit_failed';

import Cash from './cash';
import TestYourLuck from './testyourluck';
import Freeplay from './freeplay';

export default () => {
    const {type} = useSelector(Selector);

    if(type === 'cash') return <Cash />
    if(type === 'testyourluck') return <TestYourLuck />
    if(type === 'freeplay') return <Freeplay />
    return <></>;
}