import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.games.testyourluck,
    props.games.games,
    (testyourluck, games) => {
        const deposits = testyourluck ? testyourluck.deposits.map((deposit) => {
            const game = games.filter(game => game.slug === deposit.game)[0] || false;

            return {
                ...deposit,
                game,
                timestamp: deposit.timestamp + Date.now(),
            }
        }) : [];

        return {
            testyourluck: testyourluck ? {
                ...testyourluck,
                deposits
            } : testyourluck
        }
    }
)