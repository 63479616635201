export default {
    wager: "You need to win back <b>25%</b> of your deposits, as well as all bonus money received, except for the referral system.",
    balances: {
        haveWager: (available, wager) => `There is an unfinished wagering of <b>$${wager}</b> on your account, so <b>$${available}</b> is available for withdrawal.`,
        full: `Your entire balance is available for withdrawal`
    },
    cashout_wager: wager => `There is an unfinished wagering of <b>$${wager}</b> on your account. When funds are withdrawn, these funds will be burned!`,
    testyourluck: rules => `You will be able to withdraw <b>$${rules.cashout}</b> when your balance reaches <b>$${rules.minimal_cashout} or more</b>. Funds after withdrawal do not fall under the wager and are available for withdrawal.`,
    freeplay: rules => `You will be able to withdraw <b>$${rules.cashout}</b> when your balance reaches <b>$${rules.minimal_cashout} or more</b>. Funds after withdrawal do not fall under the wager and are available for withdrawal.`,
    sessions: () => `
            <b>PLAY BIG - WIN BIG</b>
            <br/>
            <br/>
            $15 to $20 → Max cashout <b>$100</b> <br/>
            $20 to $30 → Max cashout <b>$150</b> <br/>
            $30 to $40 → Max cashout <b>$250</b> <br/>
            $40 to $50 → Max cashout <b>$350</b> <br/>
            $50 to $60 → Max cashout <b>$500</b> <br/>
            $60 to $100 → Max cashout <b>$700</b> <br/>
            $100 to $500 → Max cashout <b>$2 000</b> <br/>
            $500 to $1 000 → Max cashout <b>$5 000</b> <br/>
        `
}