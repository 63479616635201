import {useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../../selectors/modals/wallet/methods/cashapp_deposit/stage';
import {createOrder, setStage} from '../../../../../../reducers/wallet/actions/cashapp_deposit';
import {setPreset} from '../../../../../../reducers/wallet/actions';
import {NumericFormat} from 'react-number-format';
import FormInput from '../../../../../components/form-input';
import BlueButton from '../../../../../components/blue-button';
import ModalsLib from '../../../../../../lib/modals';
import FAQ from './faq';
import {toast} from 'react-toastify';

export default () => {
    const dispatch = useDispatch();

    const [method, setMethod] = useState("cashapp-card");

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">CashApp</div>
                    <div className="descriptions">Select an additional method to continue</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="modal-message info">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5L11.5 14L14.75 11" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <span>
                        Sometimes CashApp payments may receive the Failed status, so we recommend making a deposit using your <b>Cash App Card</b>.
                    </span>
                </div> 
                <div className="additional-methods">
                    <div className={`additional-method ${method === 'cashapp-card' ? 'active' : ''}`} onClick={e => setMethod('cashapp-card')}>
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_565_17)">
                                    <mask id="mask0_565_17" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="46" height="46">
                                        <path d="M32 0H14C6.26801 0 0 6.26801 0 14V32C0 39.732 6.26801 46 14 46H32C39.732 46 46 39.732 46 32V14C46 6.26801 39.732 0 32 0Z" fill="white"/>
                                    </mask>
                                    <g mask="url(#mask0_565_17)">
                                        <path d="M39.1 0H6.9C3.08924 0 0 3.08924 0 6.9V39.1C0 42.9107 3.08924 46 6.9 46H39.1C42.9108 46 46 42.9107 46 39.1V6.9C46 3.08924 42.9108 0 39.1 0Z" fill="#00D632"/>
                                    </g>
                                    <path d="M13 23.6099H30" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M30 21.28V28.43C29.97 31.28 29.19 32 26.22 32H16.78C13.76 32 13 31.25 13 28.27V21.28C13 18.58 13.63 17.71 16 17.57C16.24 17.56 16.5 17.55 16.78 17.55H26.22C29.24 17.55 30 18.3 30 21.28Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M33 17.73V24.72C33 27.42 32.37 28.29 30 28.43V21.28C30 18.3 29.24 17.55 26.22 17.55H16.78C16.5 17.55 16.24 17.56 16 17.57C16.03 14.72 16.81 14 19.78 14H29.22C32.24 14 33 14.75 33 17.73Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.25 28.81H17.97" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M20.1099 28.81H23.5499" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_565_17">
                                        <rect width="46" height="46" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <span>CashApp Card</span>
                    </div>
                    <div className={`additional-method ${method === 'cashapp' ? 'active' : ''}`} onClick={e => setMethod('cashapp')}>
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none"><g clip-path="url(#clip0_448_2081)"><path d="M39.1 6.10352e-05H6.9C3.08924 6.10352e-05 0 3.0893 0 6.90006V39.1001C0 42.9108 3.08924 46.0001 6.9 46.0001H39.1C42.9108 46.0001 46 42.9108 46 39.1001V6.90006C46 3.0893 42.9108 6.10352e-05 39.1 6.10352e-05Z" fill="#00D632"></path><path d="M29.2153 18.1462C29.513 18.4408 30.0116 18.4408 30.287 18.1462L31.7754 16.6142C32.088 16.3195 32.0731 15.7892 31.7382 15.4652C30.5698 14.4561 29.2005 13.6827 27.7269 13.1966L28.1958 10.9501C28.3 10.4567 27.9279 10 27.4292 10H24.5417C24.1696 10.0074 23.8496 10.2652 23.7752 10.6261L23.3584 12.6221C19.5183 12.8136 16.2587 14.7507 16.2587 18.7059C16.2587 22.1309 18.9527 23.5966 21.7956 24.613C24.4896 25.6295 25.9111 26.0051 25.9111 27.434C25.9111 28.9071 24.4896 29.7688 22.3909 29.7688C20.4783 29.7688 18.4764 29.1354 16.9285 27.596C16.6233 27.2941 16.1322 27.2941 15.8345 27.596L14.2344 29.187C13.9219 29.5037 13.9219 30.0045 14.2344 30.3212C15.4847 31.5439 17.0773 32.4278 18.8857 32.9212L18.4466 35.0278C18.3425 35.5212 18.7071 35.9705 19.2057 35.9779L22.1007 36C22.4803 36 22.8003 35.7422 22.8747 35.3739L23.2914 33.3705C27.913 33.0612 30.7261 30.5422 30.7261 26.8669C30.7261 23.4788 27.9204 22.0499 24.5194 20.8861C22.577 20.1717 20.8951 19.6782 20.8951 18.2125C20.8951 16.7836 22.4654 16.2164 24.0356 16.2164C26.0376 16.2164 27.9651 17.034 29.2228 18.1609L29.2153 18.1462Z" fill="white"></path></g><defs><clipPath id="clip0_448_2081"><rect y="6.10352e-05" width="46" height="46" rx="14" fill="white"></rect></clipPath></defs></svg>
                        </div>
                        <span>CashApp</span>
                    </div>
                </div>

                {method === 'cashapp-card' && <FAQ />}
            </div>
            <div className="modal-footer">
                <BlueButton onClick={e => {
                    if(!method) {
                        toast.dismiss();
                        toast.error(`Select method to continue`);
                        return;
                    }

                    if(method === 'cashapp')
                        dispatch(setStage('create'));
                    else
                        dispatch(setPreset({
                            method: 'cards-deposit'
                        }));
                }}>
                    Continue
                </BlueButton>
                <div className="button back" onClick={e => dispatch(setPreset({
                    mode: 'tabs',
                    method: null
                }))}>
                    Back
                </div> 
            </div>
        </>
    )
}