import {Component} from 'react';
import {connect} from 'react-redux';
import Selector from '../selectors/layout';
import {bindActionCreators} from 'redux';
import {setMobile, initialize, initializePushNotifications} from '../reducers/app/actions';
import {getUser, getTelegramUser} from '../reducers/user/actions';
import {loadVipLevels} from '../reducers/vip/actions';
import {loadGames} from '../reducers/games/actions';
import Components from './components';
import Pages from './pages';
import {Routes, Route} from 'react-router-dom';
import SecureRoute from './components/secureRoute';
import WS from '../lib/websocket';
import Modals from './modals';

class Layout extends Component {
    constructor() {
        super();
    }

    render() {
        const {isAuth} = this.props;

        return (
            <>
                <Components.Loader.Full />
                <Modals />

                {this.props.loaded && (
                    <>
                        <Components.Header />
                        <Components.MobileNav />
                        <Components.Sidebar />
                        <Components.LiveOperator />

                        <div className="container">
                            <Routes>
                                <Route exact path='/' element={<Pages.Home />} />
                                <Route exact path='/game/:game_slug' element={isAuth ? <Pages.Game /> : <SecureRoute />} />
                                <Route exact path='/wheel' element={isAuth ? <Pages.Wheel /> : <SecureRoute />} />
                                <Route exact path='/vip' element={isAuth ? <Pages.VIP /> : <SecureRoute />} />
                                <Route exact path='/testyourluck' element={isAuth ? <Pages.TestYourLuck /> : <SecureRoute />} />
                                <Route exact path='/invite' element={isAuth ? <Pages.Invite /> : <SecureRoute />} />
                                <Route exact path='/freecash' element={isAuth ? <Pages.FreeCash /> : <SecureRoute />} />
                                <Route path='*' element={<Pages.NotFound />} />
                            </Routes>
                            <Components.Connection />
                        </div> 
                    </>
                )}
            </>
        )
    }

    resizeHandler() {
        const isTablet = window.document.body.offsetWidth < 1024,
              isMobile = window.document.body.offsetWidth <= 800;

        let state = 'desktop'; // Laptop / Desktop
        if(isTablet) state = 'tablet';
        if(isMobile) state = 'mobile';

        this.props.setMobile(state);
    }

    componentDidUpdate(prevProps) {
        if(!this.props.loaded) {
            if(!this.props.user) {
                if(typeof window.Telegram.WebApp.initDataUnsafe.user !== "undefined") {
                    window.Telegram.WebApp.ready();
                    window.Telegram.WebApp.expand();
                    this.props.getTelegramUser(window.Telegram.WebApp.initData);
                } else {
                    this.props.getUser();
                }
            } else if(!this.props.games) {
                this.props.loadGames();
            } else if(!this.props.vip_levels) {
                this.props.loadVipLevels();
            } else if(!this.props.notifications) {
                this.props.initializePushNotifications();
            }
        }
    }


    componentDidMount() {
        WS.connect();
        window.addEventListener('resize', this.resizeHandler.bind(this));
        window.addEventListener('beforeinstallprompt', e => {
            console.clear();
            console.log(e);

            // e.userChoise.then(choise => {
            //     window.alert(choise);
            // })
        });

        this.resizeHandler();

        if(!this.props.initialized)
            this.props.initialize();
    }
}

export default connect(Selector, dispatch => bindActionCreators({
    getUser,
    getTelegramUser,
    loadGames,
    setMobile,
    initialize,
    initializePushNotifications,
    loadVipLevels
}, dispatch)) (Layout);