import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.pending_voucher,
    (voucher) => {
        return {
            voucher
        }
    }
)