import API from '../../lib/api';
import types from './types';
import globalTypes from '../types';
import {toast} from 'react-toastify';
import ModalsLib from '../../lib/modals';

export const activatePromocode = code => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'activate_promocode'
    });

    const response = await API.call({
        path: '/freecash/promocode',
        method: 'POST',
        body: {
            code
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: 'activate_promocode'
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
    } else {
        if(response.details.type === 'freeplay')
            ModalsLib.open('freeplay_add', response.details);

        if(['cash', 'deposit', 'deposit-cash'].includes(response.details.type)) {
            toast.dismiss();
            toast.success(response.message);

            if(['deposit', 'deposit-cash'].includes(response.details.type)) {
                dispatch({
                    type: globalTypes.wallet.preset,
                    payload: {
                        mode: 'tabs',
                        tab: 'deposit'
                    }
                });
                
                ModalsLib.open('wallet');
            }
        }
    }
}