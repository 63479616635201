import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/modals/deposit_success';
import ModalsLib from '../../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import GreenButton from '../../components/green-button';
import BlueButton from '../../components/blue-button';
import {getCashoutsRange} from '../../../utils/sessions';
import tippy from 'tippy.js';
import Tooltip from '../../../utils/tooltips';

export default () => {
    const {transaction, game, login, sessions} = useSelector(Selector),
          [showPassword, setShowPassword] = useState(false);

    const cashout_range = getCashoutsRange({type: login.balance_details.session, amount: 0, loginBalance: login.balance_details.session_amount, sessions});

    useEffect(() => {
        tippy('#cashoutRulesInfo', {
            placement: "top",
            animation: 'fade',
            theme: 'translucent',
            arrow: true,
            content: Tooltip.testyourluck(cashout_range.rules),
            allowHTML: true
        });

        if(!transaction || !game)
            ModalsLib.close();
    }, []);

    if(!transaction || !game)
        return <></>;

    return (
        <div className="modal animate__animated animate__fadeInUp animate__faster">
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1328_6825)">
                            <path d="M12.6619 11.5137C12.7629 11.7637 12.9241 11.9853 13.1315 12.1594C13.309 12.3086 13.5161 12.4195 13.7398 12.4851C13.9634 12.5507 14.1987 12.5696 14.4307 12.5405C19.1727 11.9477 21.3288 8.41331 21.3288 8.41331C22.1156 7.09599 21.6781 5.40446 20.3513 4.63387C19.7373 4.27749 18.9833 4.27726 18.3338 4.40709C18.2686 4.42005 18.201 4.41127 18.1416 4.38213C18.105 4.36474 18.0724 4.34013 18.0458 4.30981C18.0192 4.27949 17.9992 4.24409 17.987 4.20577C17.7868 3.58317 17.4209 2.93289 16.8069 2.57652C15.4802 1.80639 13.7664 2.24963 12.9795 3.56644C12.9794 3.56641 10.8884 7.13848 12.6619 11.5137Z" fill="url(#paint0_linear_1328_6825)"/>
                            <path d="M18.1416 4.38194L13.1318 12.1586L13.1315 12.1591C12.9241 11.9851 12.7629 11.7635 12.6619 11.5135C10.8885 7.1383 12.9795 3.56619 12.9795 3.56619C13.7158 2.33405 15.2636 1.86676 16.5464 2.4427C16.6355 2.48274 16.7225 2.52732 16.8069 2.57626C17.4208 2.93264 17.7868 3.58296 17.9869 4.20552C17.9992 4.24384 18.0192 4.27925 18.0458 4.30958C18.0724 4.33991 18.105 4.36453 18.1416 4.38194Z" fill="url(#paint1_linear_1328_6825)"/>
                            <path d="M12.5511 11.5421C12.584 11.8098 12.5504 12.0817 12.4534 12.3345C12.4525 12.3368 12.452 12.339 12.4509 12.3408C12.3676 12.556 12.2403 12.7521 12.077 12.9168C11.9137 13.0814 11.7179 13.2111 11.502 13.2977C7.06715 15.0784 3.46786 13.0326 3.46786 13.0326C2.38777 12.4053 1.89692 11.167 2.17628 10.018C2.24176 9.74918 2.34763 9.49135 2.49034 9.25315C2.85451 8.64379 3.51387 8.278 4.14482 8.07668C4.20816 8.05638 4.26302 8.01593 4.30083 7.96165C4.32442 7.92869 4.34099 7.89137 4.34955 7.85196C4.35811 7.81254 4.35847 7.77187 4.3506 7.73242C4.22379 7.09084 4.22857 6.34468 4.59273 5.73533C5.3797 4.41851 7.09343 3.97527 8.42016 4.7454C8.42015 4.74536 11.9808 6.85569 12.5511 11.5421Z" fill="url(#paint2_linear_1328_6825)"/>
                            <path d="M12.5529 11.5417C12.5857 11.8094 12.5521 12.0813 12.4552 12.3341C12.4542 12.3364 12.4537 12.3386 12.4526 12.3404C7.52767 14.0438 3.59862 11.7397 3.59862 11.7397C2.91708 11.3318 2.4323 10.7143 2.17799 10.0176C2.24347 9.74877 2.34934 9.49094 2.49205 9.25274C2.85622 8.64338 3.51558 8.27759 4.14653 8.07627C4.20987 8.05597 4.26473 8.01552 4.30254 7.96124C4.32612 7.92828 4.3427 7.89096 4.35126 7.85155C4.35982 7.81213 4.36018 7.77146 4.35231 7.73201C4.2255 7.09043 4.23028 6.34427 4.59444 5.73492C5.38141 4.4181 7.09514 3.97486 8.42187 4.74499C8.42186 4.74494 11.9825 6.85527 12.5529 11.5417Z" fill="url(#paint3_linear_1328_6825)"/>
                            <path d="M12.5513 11.5424C12.5841 11.81 12.5506 12.082 12.4536 12.3347L4.30097 7.9619C4.32456 7.92895 4.34113 7.89162 4.34969 7.85221C4.35825 7.8128 4.35861 7.77212 4.35074 7.73267C4.22393 7.09109 4.22871 6.34493 4.59287 5.73558C5.37984 4.41876 7.09357 3.97553 8.4203 4.74565C8.42029 4.74561 11.981 6.85594 12.5513 11.5424Z" fill="url(#paint4_linear_1328_6825)"/>
                            <path d="M18.1415 4.38222L13.1317 12.1588C12.1545 7.5756 14.1803 4.11463 14.1803 4.11463C14.7168 3.21749 15.5902 2.63823 16.5462 2.443C16.6354 2.48303 16.7223 2.52761 16.8067 2.57656C17.4207 2.93293 17.7866 3.58325 17.9868 4.20581C17.999 4.24413 18.0191 4.27953 18.0457 4.30986C18.0723 4.34018 18.1049 4.3648 18.1415 4.38222Z" fill="url(#paint5_linear_1328_6825)"/>
                            <path d="M14.4754 12.615C14.222 12.7165 13.9969 12.8773 13.8196 13.0834C13.6676 13.2599 13.5542 13.4654 13.4865 13.687C13.4187 13.9086 13.3982 14.1413 13.4262 14.3706C13.9964 19.0565 17.5571 21.1669 17.5571 21.1669C18.8843 21.9369 20.5976 21.4938 21.385 20.1768C21.7492 19.5675 21.7541 18.8218 21.6268 18.1803C21.6141 18.1159 21.6234 18.049 21.6532 17.9901C21.671 17.9538 21.6961 17.9214 21.7269 17.8949C21.7577 17.8684 21.7937 17.8484 21.8325 17.836C22.4633 17.6343 23.1232 17.2683 23.4873 16.659C24.2743 15.3422 23.8367 13.6501 22.5099 12.88C22.51 12.88 18.9108 10.8342 14.4754 12.615Z" fill="url(#paint6_linear_1328_6825)"/>
                            <path d="M21.652 17.9904L13.8191 13.0841L13.8185 13.0837C13.9958 12.8776 14.2208 12.7168 14.4743 12.6153C18.9095 10.8345 22.5088 12.8803 22.5088 12.8803C23.7503 13.6009 24.2132 15.1287 23.6229 16.4009C23.5819 16.4892 23.5362 16.5755 23.4862 16.6593C23.1221 17.2686 22.4622 17.6346 21.8314 17.8363C21.7926 17.8487 21.7566 17.8687 21.7258 17.8952C21.6949 17.9216 21.6699 17.954 21.652 17.9904Z" fill="url(#paint7_linear_1328_6825)"/>
                            <path d="M21.6542 17.9898L13.8213 13.0835C18.462 12.0888 21.9493 14.0708 21.9493 14.0708C22.8532 14.5959 23.4336 15.456 23.6251 16.4002C23.584 16.4886 23.5384 16.5749 23.4884 16.6587C23.1242 17.268 22.4644 17.6339 21.8336 17.8357C21.7947 17.8481 21.7588 17.8681 21.728 17.8946C21.6971 17.9211 21.6721 17.9535 21.6542 17.9898Z" fill="url(#paint8_linear_1328_6825)"/>
                            <path d="M13.2922 14.4053C13.1911 14.1553 13.0299 13.9337 12.8225 13.7597C12.645 13.6105 12.4379 13.4995 12.2143 13.4339C11.9906 13.3683 11.7553 13.3495 11.5234 13.3786C6.78132 13.9714 4.62526 17.5057 4.62526 17.5057C3.83843 18.823 4.27592 20.5146 5.60278 21.2852C6.21672 21.6415 6.97075 21.6418 7.62019 21.5119C7.68543 21.499 7.75304 21.5078 7.81242 21.5369C7.84903 21.5543 7.88162 21.5789 7.90822 21.6093C7.93481 21.6396 7.95485 21.675 7.96709 21.7133C8.16725 22.3359 8.53319 22.9862 9.14714 23.3426C10.4739 24.1127 12.1876 23.6695 12.9746 22.3526C12.9746 22.3526 15.0655 18.7806 13.2922 14.4053ZM14.217 14.0231C14.2239 13.8539 14.2435 13.7705 14.2629 13.9282C14.2684 13.9737 14.2499 14.0032 14.217 14.0231Z" fill="url(#paint9_linear_1328_6825)"/>
                            <path d="M7.81147 21.5369L12.8212 13.7603L12.8216 13.7597C13.0289 13.9338 13.1901 14.1553 13.2912 14.4053C15.0646 18.7806 12.9736 22.3527 12.9736 22.3527C12.2372 23.5848 10.6894 24.0521 9.40665 23.4762C9.31752 23.4361 9.23058 23.3916 9.14616 23.3426C8.53222 22.9862 8.16626 22.3359 7.96612 21.7134C7.95389 21.675 7.93386 21.6396 7.90728 21.6093C7.88069 21.5789 7.84809 21.5543 7.81147 21.5369Z" fill="url(#paint10_linear_1328_6825)"/>
                            <path d="M7.81135 21.537L12.8211 13.7603C13.7983 18.3436 11.7725 21.8045 11.7725 21.8045C11.236 22.7017 10.3626 23.2809 9.40658 23.4762C9.31745 23.4361 9.2305 23.3916 9.14609 23.3426C8.53214 22.9862 8.16619 22.3359 7.96604 21.7134C7.95379 21.675 7.93376 21.6396 7.90717 21.6093C7.88057 21.579 7.84797 21.5544 7.81135 21.537Z" fill="url(#paint11_linear_1328_6825)"/>
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_1328_6825" x1="13.2663" y1="16.012" x2="27.7846" y2="10.4494" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_1328_6825" x1="13.1389" y1="14.9974" x2="23.6086" y2="11.2884" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_1328_6825" x1="3.57197" y1="18.5191" x2="18.0901" y2="12.9565" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint3_linear_1328_6825" x1="3.66051" y1="17.5061" x2="17.6773" y2="12.0539" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint4_linear_1328_6825" x1="6.24724" y1="16.7801" x2="16.7171" y2="13.0711" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint5_linear_1328_6825" x1="13.4776" y1="14.7207" x2="23.3871" y2="11.1961" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint6_linear_1328_6825" x1="15.7583" y1="25.5488" x2="30.2772" y2="19.9858" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint7_linear_1328_6825" x1="15.6261" y1="21.7449" x2="28.7758" y2="16.2074" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint8_linear_1328_6825" x1="15.6275" y1="21.6282" x2="27.9171" y2="16.4825" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint9_linear_1328_6825" x1="5.99801" y1="28.0739" x2="21.8386" y2="21.8068" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint10_linear_1328_6825" x1="8.91034" y1="27.0787" x2="19.3801" y2="23.3697" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint11_linear_1328_6825" x1="8.72856" y1="26.3079" x2="18.6381" y2="22.7833" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <clipPath id="clip0_1328_6825">
                                <rect width="19.893" height="19.893" fill="white" transform="translate(0.879883 5.86084) rotate(-14.5012)"/>
                            </clipPath>
                        </defs>
                        </svg>

                        Test Your Luck
                    </div>
                    <div className="descriptions">The bonus has been successfully credited to your game login!</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="game-small">
                    <div className="game-details">
                        <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${game.slug}.png)`}}></div>
                        <div className="name">{game.name}</div>
                    </div>
                    <div className="tx-amount"><span>$</span>{transaction.amount}</div>
                </div>

                <div className="cashouts" id="cashoutRulesInfo">
                    <div className="rules">
                        Cashouts range
                    </div>
                    <div className="value">
                        <span>{cashout_range.text}</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-tip data-for="cashoutRules">
                            <rect width="20" height="20" rx="10" fill="#30344F"/>
                            <path d="M11.7697 12.8984C11.5994 12.9584 11.0599 13.1013 10.8139 12.9741C10.7799 12.9562 10.7513 12.9312 10.7304 12.9011C10.7095 12.8709 10.697 12.8367 10.694 12.8012C10.6167 12.3554 10.9842 11.3252 11.183 10.7723C11.2492 10.588 11.2965 10.4537 11.3154 10.3722C11.459 9.77067 11.388 9.31059 11.1041 9.00482C10.7624 8.6369 10.1564 8.56532 9.65928 8.6319C9.15374 8.69294 8.65972 8.81599 8.19081 8.99768C8.16903 9.00723 8.14999 9.02124 8.13521 9.0386C8.12043 9.05595 8.1103 9.07619 8.10563 9.0977L8.00311 9.54206C7.99756 9.56705 7.99946 9.59293 8.00861 9.61704C8.01776 9.64114 8.03382 9.66261 8.05516 9.67923C8.07676 9.69549 8.10264 9.7064 8.13028 9.71091C8.15793 9.71542 8.18638 9.71336 8.21289 9.70495C8.40059 9.64208 8.98735 9.50063 9.19713 9.67494C9.31543 9.77353 9.2823 9.98214 9.23498 10.1379C9.18609 10.3008 9.12773 10.4765 9.06779 10.6608C8.67977 11.8568 8.23813 13.2127 8.93845 13.6356C9.26979 13.8648 9.67309 13.9924 10.0899 14C10.5 14 11.0063 13.8828 11.735 13.6156C11.757 13.6079 11.7767 13.5958 11.7928 13.5802C11.8089 13.5646 11.821 13.546 11.8281 13.5256L11.9779 13.0727C11.9864 13.0469 11.9868 13.0194 11.979 12.9934C11.9712 12.9674 11.9555 12.9439 11.9338 12.9255C11.912 12.9075 11.8851 12.8954 11.856 12.8906C11.827 12.8858 11.7971 12.8885 11.7697 12.8984ZM10.858 6C10.6324 6.00014 10.4119 6.06087 10.2243 6.17452C10.0368 6.28816 9.89063 6.44962 9.80436 6.63848C9.71808 6.82735 9.69555 7.03514 9.7396 7.23561C9.78366 7.43607 9.89233 7.6202 10.0519 7.76473C10.2114 7.90926 10.4147 8.0077 10.636 8.04761C10.8573 8.08752 11.0867 8.06711 11.2952 7.98896C11.5037 7.9108 11.6819 7.77842 11.8073 7.60853C11.9328 7.43863 11.9998 7.23887 12 7.03447C11.9999 6.76014 11.8796 6.49706 11.6654 6.30307C11.4513 6.10909 11.1609 6.00008 10.858 6Z" fill="white"/>
                        </svg>
                    </div>
                </div>

                {login && (
                    <>
                        <div className="line-title">
                            <div className="line"></div>
                            <span>LOGIN DETAILS</span>
                            <div className="line"></div>
                        </div>

                        <div className="login-details">
                            <div className="input">
                                <div className="value">{login.login}</div>
                                <div className="icons">
                                    <CopyToClipboard text={login.login} onCopy={e => {
                                        toast.dismiss();
                                        toast.info(`Login copied to clipboard`);
                                    }}>
                                        <div className="icon">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                                <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                            </svg>
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>

                            <div className="input">
                                <div className="value">{showPassword ? login.password : '••••••'}</div>
                                <div className="icons">
                                    <div className={`icon ${showPassword ? 'active' : ''}`} onClick={e => setShowPassword(!showPassword)}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.3866 8.00007C10.3866 9.32007 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32007 5.61328 8.00007C5.61328 6.68007 6.67995 5.6134 7.99995 5.6134C9.31995 5.6134 10.3866 6.68007 10.3866 8.00007Z" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M7.9999 13.5134C10.3532 13.5134 12.5466 12.1267 14.0732 9.72671C14.6732 8.78671 14.6732 7.20671 14.0732 6.26671C12.5466 3.86671 10.3532 2.48004 7.9999 2.48004C5.64656 2.48004 3.45323 3.86671 1.92656 6.26671C1.32656 7.20671 1.32656 8.78671 1.92656 9.72671C3.45323 12.1267 5.64656 13.5134 7.9999 13.5134Z" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <CopyToClipboard text={login.password} onCopy={e => {
                                        toast.dismiss();
                                        toast.info(`Password copied to clipboard`);
                                    }}>
                                        <div className="icon">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                                <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                            </svg>
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className="modal-footer">
                {game.allows.web ? (
                    <GreenButton onClick={e => {
                        ModalsLib.open('game', {
                            game: game.slug
                        })
                    }}>
                        Play
                    </GreenButton>
                ) : (
                    <>
                        {game.allows.download && (
                            <BlueButton onClick={e => {
                                ModalsLib.open('download', {
                                    game: game.slug
                                })
                            }}>
                                Download
                            </BlueButton>
                        )}
                    </>
                )}

                <div className="button close" onClick={e => ModalsLib.close()}>
                    Close
                </div>
            </div>
        </div>
    )
}