import {useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/modals/wallet/methods/cards_deposit/stage';
import {createOrder} from '../../../../../reducers/wallet/actions/cards_deposit';
import {setPreset} from '../../../../../reducers/wallet/actions';
import {NumericFormat} from 'react-number-format';
import FormInput from '../../../../components/form-input';
import BlueButton from '../../../../components/blue-button';
import ModalsLib from '../../../../../lib/modals';

export default () => {
    const {props} = useSelector(Selector),
          dispatch = useDispatch();

    const inputRef = useRef();
    const [state, setState] = useState({
        amount: props.amount,
        email: props.email,
        validator: false
    });

    const setAmount = amount => {
        setState({
            ...state,
            amount
        });
    }

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Debit Card</div>
                    <div className="descriptions">You can use any cards issued in the USA</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="amount-block">
                    <div className="label">Amount</div>
                    <div className="input">
                        <NumericFormat  
                            displayType='input'
                            type='tel'
                            value={state.amount === 0 ? '' : state.amount}
                            placeholder='$0.00'
                            prefix={'$'} 
                            decimalSeparator="." 
                            decimalScale={0} 
                            thousandSeparator=" "
                            maxLength={8}
                            getInputRef={inputRef}
                            onValueChange={(values) => {
                                setState({
                                    ...state,
                                    amount: values.floatValue
                                })
                            }} />

                        <div className="clear" onClick={e => setAmount(0)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>

                    <div className="buttons">
                        <div className="amounts">
                            <div className={`amount ${state.amount === 20 ? 'active' : ''}`} onClick={e => setAmount(20)}>$20</div>
                            <div className={`amount hot ${state.amount === 40 ? 'active' : ''}`} onClick={e => setAmount(40)}>
                                <div className="hot">
                                    <span>HOT</span>
                                </div>
                                $40
                            </div>
                            <div className={`amount ${state.amount === 60 ? 'active' : ''}`} onClick={e => setAmount(60)}>$60</div>
                            <div className={`amount ${state.amount === 80 ? 'active' : ''}`} onClick={e => setAmount(80)}>$80</div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="form-group">
                    <FormInput
                        type={'text'}
                        label={'Email'}
                        placeholder={'Email for receipt'}
                        defaultValue={state.email}
                        validators={[
                            (value) => {
                                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                return regex.test(value);
                            }
                        ]}
                        onChange={({value, validator}) => {
                            setState({...state, email: value, validator});
                        }} />
                </div>

                {props.error && (
                    <div class="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <span dangerouslySetInnerHTML={{__html: props.error}}></span>
                    </div>
                )}
            </div>
            <div className="modal-footer">
                <BlueButton disabled={!state.validator && state.amount > 0} active={props.button} onClick={e => {
                    dispatch(createOrder({
                        amount: state.amount,
                        email: state.email
                    }));
                }}>
                    Continue
                </BlueButton>
                <div className="button back" onClick={e => dispatch(setPreset({
                    mode: 'tabs',
                    method: null
                }))}>
                    Back
                </div>
            </div>
        </>
    )
}