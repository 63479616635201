export default ({active, onClick}) => (
    <div className={`method ${!active ? 'not-available' : ''}`} onClick={e => {
        if(!active)
            return;

        onClick({
            name: 'USDT',
            network: 'ERC-20',
            token_name: "USDT",
            token: "USDT_ERC20"
        });
    }}>
        <div className="info">
            <div className="icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1275_6848)">
                        <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#26A17B"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7529 19.6895V19.6874C19.6341 19.6959 19.0218 19.7327 17.6556 19.7327C16.5648 19.7327 15.797 19.7003 15.527 19.6874V19.6905C11.3279 19.5059 8.19369 18.7748 8.19369 17.9C8.19369 17.0262 11.3279 16.2951 15.527 16.1072V18.9627C15.8012 18.9821 16.5875 19.0286 17.6739 19.0286C18.9776 19.0286 19.631 18.9746 19.7529 18.9638V16.1093C23.9433 16.2962 27.0699 17.0273 27.0699 17.9C27.0699 18.7748 23.9433 19.5038 19.7529 19.6895ZM19.7529 15.8123V13.257H25.6001V9.36035H9.67977V13.257H15.527V15.8112C10.7749 16.0293 7.20117 16.9712 7.20117 18.0987C7.20117 19.2261 10.7749 20.1669 15.527 20.3861V28.5747H19.7529V20.384C24.4974 20.1657 28.0625 19.2251 28.0625 18.0987C28.0625 16.9722 24.4974 16.0316 19.7529 15.8123Z" fill="white"/>
                        <path d="M27.75 36C32.3064 36 36 32.3064 36 27.75C36 23.1936 32.3064 19.5 27.75 19.5C23.1936 19.5 19.5 23.1936 19.5 27.75C19.5 32.3064 23.1936 36 27.75 36Z" fill="#627EEA"/>
                        <path d="M27.416 21.4819V25.8726L31.1272 27.5308L27.416 21.4819Z" fill="white" fill-opacity="0.602"/>
                        <path d="M27.6646 21.4819L23.9531 27.5308L27.6646 25.8726V21.4819Z" fill="white"/>
                        <path d="M27.416 30.5664V33.5498L31.1296 28.4121L27.416 30.5664Z" fill="white" fill-opacity="0.602"/>
                        <path d="M27.6646 33.5498V30.5658L23.9531 28.4121L27.6646 33.5498Z" fill="white"/>
                        <path d="M27.416 29.7485L31.1272 27.5939L27.416 25.9365V29.7485Z" fill="white" fill-opacity="0.2"/>
                        <path d="M23.9531 27.5939L27.6646 29.7485V25.9365L23.9531 27.5939Z" fill="white" fill-opacity="0.602"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1275_6848">
                            <rect width="36" height="36" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div className="name">USDT <span>ERC-20</span></div>
        </div>

        {
            !active 
            ? <div className="status">Soon</div>
            : <div className="status bonus">Bonus <b>+7%</b></div>
        }
    </div>
)