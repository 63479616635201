import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/game';
import {loadGame, clearGame} from '../../../reducers/games/actions';
import {useParams, useLocation} from 'react-router-dom';
import PageContainer from '../page-container';
import Compontens from '../../components';
import Loader from '../../components/loader';
import NotFoundComponent from '../notFound/component';

import Game from './game';
import TestYourLuck from './testyourluck';
import Actions from './actions';

export default () => {
    const {isMobile, game, login, user} = useSelector(Selector),
          dispatch = useDispatch(),
          {game_slug} = useParams(),
          location = useLocation();

    let slug = game_slug ? game_slug : user.game;

    useEffect(() => {
        if(!game) {
            dispatch(loadGame(slug));
        }

        if(game) {
            console.clear();
            console.log('Loaded');
            Loader.close();
            Loader.closePage();
        }
    }, [game]);

    useEffect(() => {
        return () => setTimeout(() => {
            dispatch(clearGame());
        }, 500);
    }, [location]);

    return (
        <PageContainer key={game_slug}>
            <Loader.Page key={game_slug} />
            {game && (
                <>
                    {game.notfound && <NotFoundComponent />}
                    {!game.notfound && (
                        <div className="page game">
                            {isMobile === 'mobile' && <Compontens.Winners />}
                            <div className="game-container">
                                {isMobile === 'mobile' && <TestYourLuck />}
                                <Game game={game} login={login} />
                                <div className="right">
                                    {isMobile !== 'mobile' && <TestYourLuck />}
                                    <Actions login={login ? login.login : null} />
                                </div>
                            </div>
                            {isMobile === 'desktop' && <Compontens.Winners />}
                            <Compontens.DepositBonus />
                            <Compontens.Footer />
                        </div>
                    )}
                </>
            )}
        </PageContainer>
    )
} 