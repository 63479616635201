import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/modals/wallet/methods/cashapp_deposit/stage';
import {confirmOrder, setStage} from '../../../../../reducers/wallet/actions/cashapp_deposit';
import {setPreset} from '../../../../../reducers/wallet/actions';
import BlueButton from '../../../../components/blue-button';
import GreenButton from '../../../../components/green-button';
import ModalsLib from '../../../../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';

export default () => {
    const {props} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">CashApp</div>
                    <div className="descriptions">Send the money to the recipient and confirm the transfer</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="cashapp-recipient">
                    <div className="avatar">
                        <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1265_6035)">
                                <path d="M103.76 -80.0625C131.972 -80.0625 146.078 -80.0625 161.507 -75.2135C178.258 -69.042 191.042 -56.2582 197.213 -39.507C202.063 -24.0783 202.062 -9.97207 202.062 18.2404V103.76C202.062 131.972 202.063 146.078 197.213 161.507C191.042 178.258 178.258 191.042 161.507 197.213C146.078 202.063 131.972 202.062 103.76 202.062H18.2404C-9.97207 202.062 -24.0783 202.063 -39.507 197.213C-56.2582 191.042 -69.042 178.258 -75.2135 161.507C-80.0625 146.078 -80.0625 131.972 -80.0625 103.76V18.2404C-80.0625 -9.97207 -80.0625 -24.0783 -75.2135 -39.507C-69.042 -56.2582 -56.2582 -69.042 -39.507 -75.2135C-24.0783 -80.0625 -9.97207 -80.0625 18.2404 -80.0625H103.76Z" fill="#00D632"/>
                                <path d="M75.3465 48.5714C76.0599 49.2857 77.2013 49.2857 77.9147 48.5714L81.4816 44.8571C82.195 44.1429 82.195 42.8571 81.3389 42.1429C78.4854 39.7143 75.3465 37.8571 71.7797 36.7143L72.9211 31.2857C73.2064 30.1429 72.2077 29 71.0663 29H64.2179C63.3618 29 62.5058 29.5714 62.3631 30.5714L61.3644 35.4286C52.2331 35.8571 44.386 40.5714 44.386 50.1429C44.386 58.4286 50.8064 62 57.6548 64.4286C64.0752 66.8571 67.4994 67.8571 67.4994 71.2857C67.4994 74.8571 64.0752 77 59.0816 77C54.5159 77 49.665 75.4286 45.9554 71.7143C45.242 71 44.1006 71 43.3873 71.7143L39.535 75.5714C38.8217 76.2857 38.8217 77.5714 39.535 78.2857C42.5312 81.2857 46.3835 83.4286 50.6637 84.5714L49.665 89.7143C49.3796 90.8571 50.2357 92 51.5198 92H58.5109C59.3669 92 60.223 91.4286 60.3656 90.4286L61.3644 85.5714C72.3504 84.8571 79.1988 78.7143 79.1988 69.8571C79.1988 61.7143 72.493 58.1429 64.3605 55.4286C59.6523 53.7143 55.6573 52.5714 55.6573 49C55.6573 45.5714 59.3669 44.1429 63.2191 44.1429C67.6421 43.8571 72.3504 45.8571 75.3465 48.5714Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1265_6035">
                                    <rect width="122" height="122" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="name">{props.order.recipient.name}</div>
                    <div className="cashtag">
                        {props.order.recipient.cashtag}

                        <CopyToClipboard text={props.order.recipient.cashtag} onCopy={e => {
                            toast.dismiss();
                            toast.info(`The recipient's $cashtag has been copied to the clipboard!`);
                        }}>
                            <div className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                    <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                </svg>
                            </div>
                        </CopyToClipboard>

                    </div>
                </div> 

                <div className="cashapp-amount">
                    <div className="amount">
                        <span>$</span>{props.order.amounts.amount}
                    </div>
                    <div className="desc">Amount to send</div>
                </div>

                <div className="modal-message info">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5L11.5 14L14.75 11" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>To complete the deposit request, you need to transfer <b>${props.order.amounts.amount}</b> from your account <b>{props.order.sender}</b> to the <b>{props.order.recipient.name}</b> recipient. <u>Don't forget to click «<b>I sent</b>» otherwise the deposit will not be processed!</u></span>
                </div>

                {props.error && (
                    <div class="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <span dangerouslySetInnerHTML={{__html: props.error}}></span>
                    </div>
                )}

                <div className="tx-details">
                    <div className="label">Payment ID</div>
                    <CopyToClipboard text={`CashApp deposit request #${props.order.paymentId} for $${props.order.amounts.amount} from ${props.order.sender} to ${props.order.recipient.name} (${props.order.recipient.cashtag})`} onCopy={e => {
                        toast.dismiss();
                        toast.info(`The transaction information has been copied to the clipboard.`);
                    }}>
                        <div className="value">
                            #{props.order.paymentId} 
                        
                            <div className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                    <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                </svg>
                            </div>
                        </div>
                    </CopyToClipboard>
                </div>

                <div className="button cashapp-pay" onClick={e => window.open(`https://cash.app/${props.order.sender}/${props.order.amount.toFixed(2)}`, '_blank')}>
                    Open CashApp to Pay
                </div>
            </div>
            <div className="modal-footer">
                <BlueButton active={props.button} onClick={e => dispatch(confirmOrder(props.order.paymentId, props.order))}>
                    I sent
                </BlueButton>
                <div className="button back" onClick={e => dispatch(setStage('create'))}>
                    Back
                </div>
            </div>
        </>
    )
}