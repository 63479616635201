import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.modals.data.profile_complete,
    props.user.user,
    (data, user) => {
        return {
            data,
            user
        }
    }
)