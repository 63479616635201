import {useState, useRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/modals/wallet/methods/cashapp_cashout/stage';
import {createOrder} from '../../../../../reducers/wallet/actions/cashapp_cashout';
import {setPreset} from '../../../../../reducers/wallet/actions';
import {NumericFormat} from 'react-number-format';
import Numeric from '../../../../components/numeric';
import FormInput from '../../../../components/form-input';
import BlueButton from '../../../../components/blue-button';
import ModalsLib from '../../../../../lib/modals';
import tippy from 'tippy.js';
import { float } from '../../../../../utils/formats';
import Tooltips from '../../../../../utils/tooltips';

export default () => {
    const {props, user, available_balance} = useSelector(Selector),
          dispatch = useDispatch();

    const inputRef = useRef();
    const [state, setState] = useState({
        amount: props.amount,
        cashtag: props.cashtag,
        validator: false
    });

    const setAmount = amount => {
        setState({
            ...state,
            amount
        });
    }

    useEffect(() => {
        if(user.wager > 0)
            tippy('#wager-info', {
                placement: "bottom-end",
                animation: 'fade',
                theme: 'translucent',
                arrow: true,
                content: Tooltips.wager,
                allowHTML: true
            });

        tippy('#balance-tooltip', {
            placement: "bottom-end",
            animation: 'fade',
            theme: 'translucent',
            arrow: true,
            content: user.wager > 0 ? Tooltips.balances.haveWager(available_balance, user.wager) : Tooltips.balances.full,
            allowHTML: true
        });
    }, [user]); 

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">CashApp</div>
                    <div className="descriptions">Fill in all the fields to create a withdrawal request.</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                {user.wager > 0 && (
                    <div className="wager-message">
                        <div className="icon">
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.61891 0C5.60513 0 6.58773 0 7.57396 0C7.51595 0.625053 7.45431 1.2535 7.39992 1.87856C8.54931 1.95669 9.66969 2.30318 10.6777 2.81614C10.2426 3.84204 9.80385 4.86794 9.36512 5.89384C8.51668 5.21783 7.39992 4.84416 6.28679 4.85775C6.0076 4.86454 5.71753 4.9155 5.48548 5.06497C5.3332 5.16348 5.22079 5.31635 5.18816 5.4862C5.16278 5.62208 5.17003 5.77495 5.2498 5.89724C5.34045 6.03312 5.49998 6.11465 5.65952 6.16561C5.98585 6.27431 6.33755 6.2811 6.67838 6.31507C7.47969 6.40679 8.28462 6.53588 9.04242 6.80425C9.62981 7.01486 10.1918 7.33079 10.5943 7.79278C10.975 8.21741 11.1962 8.75414 11.2796 9.30446C11.3775 9.9397 11.3485 10.5987 11.1382 11.217C10.975 11.713 10.6958 12.1749 10.3332 12.5656C9.58267 13.3809 8.48042 13.8701 7.34916 13.9958C7.38542 14.665 7.42893 15.3308 7.46881 16C6.53335 16 5.60151 16 4.66967 16C4.70593 15.3376 4.75306 14.6752 4.78569 14.0127C4.03515 13.989 3.29185 13.8327 2.59207 13.5847C1.91403 13.3435 1.26864 13.0242 0.666748 12.6505C1.1091 11.5839 1.55145 10.5172 1.99743 9.44713C2.50142 9.84459 3.03804 10.2149 3.62543 10.4968C4.25632 10.7992 4.95611 10.9928 5.66677 10.9996C6.00397 11.003 6.35206 10.986 6.67113 10.8773C6.86692 10.8093 7.05909 10.6904 7.14611 10.5036C7.23676 10.3066 7.22951 10.0586 7.09172 9.88195C6.99383 9.76306 6.85605 9.68153 6.71101 9.62378C6.51522 9.54904 6.31217 9.50828 6.1055 9.47771C5.34407 9.36221 4.57902 9.26709 3.84298 9.04289C3.25197 8.86285 2.67546 8.61147 2.2041 8.2242C1.90678 7.98302 1.66023 7.68747 1.48619 7.35457C1.22513 6.8586 1.12723 6.29809 1.13448 5.74777C1.13448 5.13291 1.26864 4.50786 1.59496 3.96773C1.87778 3.48535 2.30925 3.0913 2.79511 2.78896C3.3825 2.42548 4.03877 2.17749 4.70593 1.97707C4.67692 1.31805 4.64791 0.659023 4.61891 0Z" fill="#F99F3C"/>
                            </svg>
                        </div>

                        <div className="text">You need to play for <b>${user.wager}</b>, otherwise they will burn during withdrawal.</div>

                        <div className="info-icon" id="wager-info">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1265_6027)">
                                    <path d="M13.1038 14.8034C12.9013 14.8822 12.26 15.0697 11.9675 14.9028C11.9271 14.8794 11.8931 14.8465 11.8683 14.807C11.8434 14.7674 11.8286 14.7225 11.825 14.6759C11.7331 14.0909 12.17 12.7391 12.4063 12.0134C12.485 11.7716 12.5413 11.5953 12.5638 11.4884C12.7344 10.6991 12.65 10.0953 12.3125 9.69407C11.9064 9.21126 11.186 9.11732 10.595 9.2047C9.99405 9.28479 9.40679 9.44627 8.84938 9.6847C8.82348 9.69723 8.80086 9.71561 8.78329 9.73839C8.76572 9.76117 8.75368 9.78772 8.74813 9.81595L8.62625 10.3991C8.61966 10.4319 8.62192 10.4658 8.63279 10.4975C8.64367 10.5291 8.66277 10.5573 8.68813 10.5791C8.71381 10.6004 8.74457 10.6147 8.77743 10.6206C8.81029 10.6266 8.84412 10.6239 8.87563 10.6128C9.09875 10.5303 9.79625 10.3447 10.0456 10.5734C10.1863 10.7028 10.1469 10.9766 10.0906 11.1809C10.0325 11.3947 9.96313 11.6253 9.89188 11.8672C9.43063 13.4366 8.90563 15.2159 9.73813 15.7709C10.132 16.0717 10.6114 16.2391 11.1069 16.2491C11.5944 16.2491 12.1963 16.0953 13.0625 15.7447C13.0886 15.7345 13.1121 15.7187 13.1312 15.6982C13.1504 15.6778 13.1647 15.6533 13.1731 15.6266L13.3513 15.0322C13.3613 14.9983 13.3618 14.9623 13.3525 14.9282C13.3432 14.894 13.3246 14.8632 13.2988 14.8391C13.2729 14.8154 13.2409 14.7995 13.2064 14.7932C13.1719 14.7869 13.1363 14.7904 13.1038 14.8034ZM12.02 5.75095C11.7518 5.75113 11.4896 5.83083 11.2667 5.97996C11.0437 6.12909 10.87 6.34097 10.7675 6.58881C10.6649 6.83665 10.6381 7.10933 10.6905 7.37239C10.7429 7.63545 10.872 7.87708 11.0617 8.06674C11.2514 8.2564 11.493 8.38559 11.7561 8.43796C12.0191 8.49033 12.2918 8.46355 12.5396 8.36099C12.7875 8.25843 12.9994 8.0847 13.1485 7.86176C13.2976 7.63882 13.3773 7.37667 13.3775 7.10845C13.3774 6.74845 13.2343 6.40322 12.9798 6.14866C12.7252 5.8941 12.38 5.75105 12.02 5.75095Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1265_6027">
                                        <rect width="4.75494" height="10.4981" fill="white" transform="translate(8.62256 5.75095)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                )}
                
                <div className="amount-block">
                    <div className="label">Cashout Amount</div>
                    <div className="input">
                        <NumericFormat  
                            displayType='input'
                            type='tel'
                            value={state.amount === 0 ? '' : state.amount}
                            placeholder='$0.00'
                            prefix={'$'} 
                            decimalSeparator="." 
                            decimalScale={2} 
                            thousandSeparator=" "
                            maxLength={8}
                            getInputRef={inputRef}
                            onValueChange={(values) => {
                                setState({
                                    ...state,
                                    amount: values.floatValue
                                })
                            }} />

                        <div className="clear" onClick={e => setAmount(0)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>

                    <div className="buttons">
                        <div className="amounts">
                            <div className={`amount ${state.amount === float(available_balance * 0.25) ? 'active' : ''}`} onClick={e => setAmount(float(available_balance * 0.25))}>25%</div>
                            <div className={`amount ${state.amount === float(available_balance * 0.5) ? 'active' : ''}`} onClick={e => setAmount(float(available_balance * 0.5))}>50%</div>
                            <div className={`amount ${state.amount === float(available_balance * 0.75) ? 'active' : ''}`} onClick={e => setAmount(float(available_balance * 0.75))}>75%</div>
                            <div className={`amount ${state.amount === float(available_balance) ? 'active' : ''}`} onClick={e => setAmount(available_balance)}>100%</div>
                        </div>
                    </div>

                    <div className="available-balance">
                        <div className="label">Available balance</div>
                        <div className="value">
                            <Numeric value={available_balance} fixedDecimalScale={0} />
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" id="balance-tooltip">
                                <g clip-path="url(#clip0_1328_6858)">
                                    <rect width="16" height="16" rx="8" fill="#30344F"/>
                                    <path d="M9.76971 10.8984C9.59936 10.9584 9.05993 11.1013 8.81387 10.9741C8.77992 10.9562 8.75127 10.9312 8.73038 10.9011C8.7095 10.8709 8.69701 10.8367 8.69399 10.8012C8.6167 10.3554 8.98422 9.32524 9.18296 8.77228C9.2492 8.58796 9.29652 8.45365 9.31545 8.37221C9.45898 7.77067 9.38801 7.31059 9.10409 7.00482C8.76245 6.6369 8.15645 6.56532 7.65928 6.6319C7.15374 6.69294 6.65972 6.81599 6.19081 6.99768C6.16903 7.00723 6.14999 7.02124 6.13521 7.0386C6.12043 7.05595 6.1103 7.07619 6.10563 7.0977L6.00311 7.54206C5.99756 7.56705 5.99946 7.59293 6.00861 7.61704C6.01776 7.64114 6.03382 7.66261 6.05516 7.67923C6.07676 7.69549 6.10264 7.7064 6.13028 7.71091C6.15793 7.71542 6.18638 7.71336 6.21289 7.70495C6.40059 7.64208 6.98735 7.50063 7.19713 7.67494C7.31543 7.77353 7.2823 7.98214 7.23498 8.13788C7.18609 8.30077 7.12773 8.47651 7.06779 8.66083C6.67977 9.85676 6.23813 11.2127 6.93845 11.6356C7.26979 11.8648 7.67309 11.9924 8.08988 12C8.49998 12 9.0063 11.8828 9.73501 11.6156C9.75697 11.6079 9.77673 11.5958 9.79284 11.5802C9.80895 11.5646 9.82099 11.546 9.82807 11.5256L9.97792 11.0727C9.98641 11.0469 9.98677 11.0194 9.97897 10.9934C9.97117 10.9674 9.95551 10.9439 9.93375 10.9255C9.912 10.9075 9.88506 10.8954 9.85605 10.8906C9.82703 10.8858 9.79711 10.8885 9.76971 10.8984ZM8.85803 4C8.63239 4.00014 8.41187 4.06087 8.22432 4.17452C8.03678 4.28816 7.89063 4.44962 7.80436 4.63848C7.71808 4.82735 7.69555 5.03514 7.7396 5.23561C7.78366 5.43607 7.89233 5.6202 8.05188 5.76473C8.21143 5.90926 8.4147 6.0077 8.63599 6.04761C8.85729 6.08752 9.08667 6.06711 9.29517 5.98896C9.50366 5.9108 9.68189 5.77842 9.80735 5.60853C9.9328 5.43863 9.99984 5.23887 10 5.03447C9.99992 4.76014 9.87958 4.49706 9.66543 4.30307C9.45129 4.10909 9.16087 4.00008 8.85803 4Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1328_6858">
                                        <rect width="16" height="16" rx="8" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="form-group">
                    <FormInput
                        type={'text'}
                        label='Your $cashtag'
                        placeholder={'$example'}
                        defaultValue={state.cashtag}
                        validators={[
                            (value) => {
                                value = value.replace(/ /gi, '');
                                return value.length >= 4;
                            }
                        ]}
                        onChange={({value, validator}) => {
                            setState({...state, cashtag: value, validator});
                        }} />
                </div>

                {props.error && (
                    <div class="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <span dangerouslySetInnerHTML={{__html: props.error}}></span>
                    </div>
                )}
            </div>
            <div className="modal-footer">
                <BlueButton disabled={!state.validator || state.amount < 1} active={props.button} onClick={e => {
                    dispatch(createOrder({
                        amount: state.amount,
                        cashtag: state.cashtag
                    }));
                }}>
                    Continue
                </BlueButton>
                <div className="button back" onClick={e => dispatch(setPreset({
                    mode: 'tabs',
                    method: null
                }))}>
                    Back
                </div>
            </div>
        </>
    )
}