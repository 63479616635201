import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.user,
    props.user.logins,
    props.games.games,
    props.modals.data.freeplay_add,
    props.games.buttons.updateBalance,
    props.games.buttons.freeplay,
    props.games.sessions,
    (user, logins, games, data, updateBalance, freePlayButton, sessions) => {
        const login = logins.filter(l => l.game === data.game)[0] || false,
              game = games.filter(g => g.slug === data.game)[0] || false;

        return {
            user,
            login,
            data,
            game,
            sessions,
            buttons: {
                update: updateBalance,
                add: freePlayButton
            }
        }
    }
)