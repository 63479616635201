import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.freecash.buttons.activate_promocode,
    (button) => {
        return {
            button
        }
    }
)