import {navigate} from '../../../../../../utils/navigation';

export default () => {

    return (
        <div className="slide testyourluck size-params">
            <div className="size-params-layer layer0"><div className="money1"></div></div>
            <div className="layer-margin size-params-layer layer1"><div className="money2"></div></div>
            <div className="layer-margin size-params-layer layer2"><div className="clover"></div></div>
            <div className="layer-margin size-params slide-details">
                <div className="title">
                    Test<br/>
                    Your luck
                </div>
                {/* <div className="descriptions">Get +50% up to 600 USD</div> */}
                <div className="descriptions-short">If you've been plagued by setbacks for several gaming sessions, we'll help you! Test your luck with Lucky!</div>
                <div className="details">
                    <div className="button" onClick={e => navigate('/testyourluck')}>How it works?</div>
                </div>
            </div>
        </div>
    )
}