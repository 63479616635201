import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/testyourluck';
import {loadTestYourLuck} from '../../../reducers/games/actions';
import PageContainer from '../page-container';
import Components from '../../components';
import FAQ from './faq';
import Progress from './progress';
import Deposits from './deposits';
import DateLib from '../../../lib/date';
import Button from './button';

export default () => {
    const {testyourluck} = useSelector(Selector),
          dispatch = useDispatch();

    useEffect(() => {
        if(testyourluck) {
            Components.Loader.close();
            Components.Loader.closePage();
        } else {
            dispatch(loadTestYourLuck());
        }
    }, [testyourluck]);

    return (
        <PageContainer>
            <Components.Loader.Page />
            {testyourluck && (
                <div className="tyl-page">
                    <Components.Winners />

                    <div className="tyl-container">
                        <div className="tyl-top">
                            <div className="layer">
                                <div className="dot"><div></div></div>
                                <div className="dot big"><div></div></div>
                            </div>
                            <div className="layer">
                                <div className="money"></div>
                                <div className="money"></div>
                            </div>
                            <div className="layer">
                                <div className="clover"></div>
                            </div>
                            <div className="layer">
                                <div className="content">
                                    <div className="title">Test your luck</div>
                                    <div className="description">If you've been plagued by setbacks for several gaming sessions, we'll help you! Test your luck with Lucksy!</div>
                                </div>
                            </div>
                        </div>

                        <div className="hiw">
                            <div className="title">How it works?</div>
                            <div className="text">If you are unlucky 3 to 5 times in a row, you can get a bonus. We will count the loss of the game session if its balance falls below <b>$2</b>.</div>

                            {testyourluck.status == 0 && (
                                <div className="text">The bonus is calculated based on deposits made in the last <b>24 hours</b>, starting from <b>{DateLib.parse(testyourluck.timestamp)}</b></div>
                            )}

                            {testyourluck.status == 1 && (
                                <div className="text">The bonus is calculated based on deposits made after your last game withdrawal, starting from <b>{DateLib.parse(testyourluck.timestamp)}</b></div>
                            )}

                            {testyourluck.status == 2 && (
                                <div className="text">The bonus is calculated based on deposits made after the last bonus receive, starting from <b>{DateLib.parse(testyourluck.timestamp)}</b></div>
                            )}

                        </div>

                        <Progress deposits_count={testyourluck.deposits.filter(d => d.status === 2).length} />
                        <Button />

                        <div className="tyl-deposits">
                            <div className="title">Counted deposits</div>
                            <Deposits deposits={testyourluck.deposits} />
                        </div>

                        {/* <FAQ /> */}
                    </div>

                    <Components.DepositBonus />
                    <Components.Footer />
                </div>
            )}
        </PageContainer> 
    )
}