import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.user,
    props.user.logins,
    props.games.games,
    props.modals.data.testyourluck_add,
    props.games.buttons.updateBalance,
    props.games.buttons.testyourluck,
    props.games.sessions,
    props.games.testyourluck,
    (user, logins, games, data, updateBalance, redeemTestYourLuck, sessions, testyourluck) => {
        const login = logins.filter(l => l.game === data.game)[0] || false,
              game = games.filter(g => g.slug === data.game)[0] || false;

        return {
            user,
            login,
            data,
            game,
            sessions,
            buttons: {
                update: updateBalance,
                add: redeemTestYourLuck
            },
            testyourluck
        }
    }
)