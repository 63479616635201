import card_deposit from './card_deposit';
import cashapp_deposit from './cashapp_deposit';
import cashapp_cashout from './cashapp_cashout';
import crypto from './crypto';
import bitcoin_deposit from './crypto';

export default {
    card_deposit,
    cashapp_deposit,
    cashapp_cashout,
    crypto,
    bitcoin_deposit
}