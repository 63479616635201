import {useState, useRef, useEffect} from 'react';
import InputMask from "react-input-mask";

export default ({
    label = "Empty label",
    name="",
    type,
    placeholder,
    defaultValue = "",
    validators = [],
    onChange = e => e,
    isPhone = false
}) => {
    const getValidatorsMap = (value) => {
        let map = [];
        for(let i = 0; i < validators.length; i++) map.push(validators[i](value) || false);
        return map;
    }

    const getValidatorResult = value => {
        const map = getValidatorsMap(value);
        return map.length === 0 ? true : map.every(v => v === true);
    }

    const ref = useRef(),
          [value, setValue] = useState(defaultValue),
          [validatorsResults, setValidatorsResults] = useState(getValidatorsMap(defaultValue)),
          [showPassword, setShowPassword] = useState(false),
          [autoFillScanner, setAutoFillScanner] = useState(true);

    useEffect(() => {
        setValidatorsResults(getValidatorsMap(value));
        
        onChange({
            value,
            validator: getValidatorResult(value)
        });
    }, [value]);

    // const handleAutoFill = () => {
    //     if(ref.current) {
    //         if(value != ref.current.value) {
    //             onChange({
    //                 value: ref.current.value,
    //                 validator: getValidatorResult(ref.current.value)
    //             });
    //         }
    //     }
    // }

    // useEffect(() => {
    //     if(autoFillScanner)
    //         window[`input_${name}_scanner`] = setInterval(handleAutoFill, 250);

    //     return () => clearInterval(window[`input_${name}_scanner`]);
    // }, [autoFillScanner]);

    return (
        <div className="form-input">
            {label && <div className="label">{label}</div>}
            <div className={`input ${type === "password" ? "password" : ""}`} onClick={e => {
                if(ref.current)
                    ref.current.focus();
            }}>
                {isPhone && (
                    <div className="phone-mark">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_325_4028)">
                                <path d="M23.9469 13.1224H0.0541382V13.1226H23.9469V13.1224Z" fill="#C42126"/>
                                <path d="M22.7015 6.57156H1.29858V6.5718H22.7015V6.57156Z" fill="#C42126"/>
                                <path d="M23.5518 8.75482H0.447449V8.75506H23.5518V8.75482Z" fill="#C42126"/>
                                <path d="M5.15762 21.8559H18.8426L18.8434 21.8551H5.15686L5.15762 21.8559Z" fill="#C42126"/>
                                <path d="M11.9999 0C11.7642 0 11.5304 0.00851213 11.2983 0.021787H12.7017C12.4694 0.00851213 12.2356 0 11.9999 0Z" fill="#C42126"/>
                                <path d="M23.9499 10.9386H0.0495605V10.9388H23.9499V10.9386Z" fill="#C42126"/>
                                <path d="M15.5467 4.38826H21.2768C20.5962 3.5597 19.8052 2.82624 18.9288 2.20531H14.626C14.9821 2.83171 15.2883 3.57085 15.5467 4.38826Z" fill="#E7E7E7"/>
                                <path d="M14.626 2.2053H18.9288C17.15 0.944748 15.0145 0.155349 12.7017 0.0217896H12.3323C13.4208 0.158794 13.9208 0.964407 14.626 2.2053Z" fill="#DC3027"/>
                                <path d="M23.5525 8.75502C23.3392 7.9941 23.0531 7.26403 22.7014 6.57207H16.1092C16.2428 7.27295 16.3481 8.00504 16.4262 8.75502H23.5525Z" fill="#E7E7E7"/>
                                <path d="M15.7384 6.57172H22.7012C22.3045 5.79129 21.8278 5.05895 21.2768 4.38841H15.1945C15.4089 5.06731 15.591 5.79965 15.7384 6.57172Z" fill="#DC3027"/>
                                <path d="M16.6786 13.1219H23.9459C23.9804 12.7526 23.9999 12.3784 23.9999 12.0002C23.9999 11.6423 23.9811 11.2889 23.9504 10.9388H16.6803C16.704 11.6648 16.7036 12.3962 16.6786 13.1219Z" fill="#E7E7E7"/>
                                <path d="M23.9507 10.9384C23.8849 10.1886 23.7498 9.45915 23.5526 8.75543H16.1201C16.1947 9.4703 16.244 10.2017 16.2681 10.9384H23.9507Z" fill="#DC3027"/>
                                <path d="M15.9838 17.4887H22.67C23.0266 16.7975 23.3172 16.0668 23.5351 15.3054H16.3071C16.2275 16.0565 16.1195 16.7884 15.9838 17.4887Z" fill="#E7E7E7"/>
                                <path d="M23.5353 15.3052C23.7368 14.6017 23.8766 13.8722 23.9461 13.1223H16.4522C16.4266 13.8595 16.3758 14.5907 16.2997 15.3052H23.5353Z" fill="#DC3027"/>
                                <path d="M18.8433 21.8552C19.7324 21.2367 20.5346 20.5025 21.226 19.6722H15.5958C15.3326 20.493 15.0201 21.231 14.6577 21.8552H18.8433Z" fill="#E7E7E7"/>
                                <path d="M15.3839 19.672H21.226C21.7834 19.0023 22.2673 18.2699 22.6699 17.4887H15.9347C15.7852 18.2614 15.6013 18.9941 15.3839 19.672Z" fill="#DC3027"/>
                                <path d="M11.5651 23.9915C11.5408 23.9907 11.5166 23.9896 11.4923 23.9888C11.5166 23.9896 11.5408 23.9907 11.5651 23.9915Z" fill="#DC3027"/>
                                <path d="M11.9999 24C14.544 24 16.9013 23.2059 18.8426 21.8559H14.4997C13.7289 23.1839 13.2203 24 11.9999 24Z" fill="#DC3027"/>
                                <path d="M12 24C11.8542 24 11.7095 23.9966 11.5651 23.9915C11.711 23.9964 11.8559 24 12 24Z" fill="#C42126"/>
                                <path d="M15.0233 2.20531H5.07139C4.19489 2.82624 3.40402 3.55965 2.72321 4.38826H15.9438C15.6854 3.57085 15.3794 2.83171 15.0233 2.20531Z" fill="#F3F4F5"/>
                                <path d="M5.07141 2.2053H15.0234C14.318 0.964407 13.4208 0.158794 12.3323 0.0217896H11.2983C8.98557 0.155349 6.84999 0.944748 5.07141 2.2053Z" fill="#E73625"/>
                                <path d="M0.447632 8.75502H16.8046C16.7265 8.00489 16.6212 7.27295 16.4876 6.57207H1.29874C0.947061 7.26408 0.660992 7.9941 0.447632 8.75502Z" fill="#F3F4F5"/>
                                <path d="M2.72321 4.38826C2.17261 5.05879 1.69552 5.79109 1.29895 6.57157H16.4876C16.3404 5.79965 16.1583 5.06736 15.9438 4.38826H2.72321Z" fill="#E73625"/>
                                <path d="M0.00012207 12.0002C0.00012207 12.3784 0.0198317 12.7526 0.0541336 13.1219H16.9513C16.9763 12.3962 16.9767 11.665 16.953 10.9389H0.0495735C0.018869 11.2889 0.00012207 11.6423 0.00012207 12.0002Z" fill="#F3F4F5"/>
                                <path d="M0.0495605 10.9384H16.9528C16.9287 10.2015 16.8793 9.47035 16.8048 8.75543H0.447452C0.250204 9.4593 0.115124 10.1886 0.0495605 10.9384Z" fill="#E73625"/>
                                <path d="M16.7987 15.3052H0.464844C0.682764 16.0669 0.973596 16.7973 1.32999 17.4885H16.4755C16.6111 16.7884 16.7189 16.0565 16.7987 15.3052Z" fill="#F3F4F5"/>
                                <path d="M0.464899 15.3052H16.7988C16.8748 14.5907 16.9257 13.8593 16.9513 13.1223H0.0541382C0.123654 13.8724 0.263699 14.6017 0.464899 15.3052Z" fill="#E73625"/>
                                <path d="M5.15681 21.8552H14.9867C15.3492 21.2308 15.6618 20.493 15.9249 19.6722H2.77417C3.46558 20.5025 4.26759 21.2367 5.15681 21.8552Z" fill="#F3F4F5"/>
                                <path d="M1.3302 17.4887C1.73285 18.2699 2.21673 19.0022 2.77417 19.672H15.9249C16.142 18.9941 16.326 18.2614 16.4755 17.4887H1.3302Z" fill="#E73625"/>
                                <path d="M11.4923 23.9888C11.5166 23.9896 11.5408 23.9907 11.565 23.9914C11.7094 23.9966 11.8542 24 11.9999 24C13.2203 24 14.2154 23.1839 14.9864 21.8559H5.15759C6.9688 23.1156 9.14284 23.8909 11.4923 23.9888Z" fill="#E73625"/>
                                <path d="M11.7519 0.00622559C5.60252 0.131324 0.590492 4.87932 0.0510864 10.9188H11.7521V0.00622559H11.7519Z" fill="#283991"/>
                                <path d="M2.40114 6.7989L2.58395 7.36182H3.17625L2.69709 7.70991L2.8801 8.27323L2.40114 7.92494L1.92233 8.27323L2.10499 7.70991L1.62598 7.36182H2.21828L2.40114 6.7989Z" fill="#EFEFEF"/>
                                <path d="M2.40114 8.78766L2.58395 9.35093H3.17625L2.69709 9.69901L2.8801 10.2623L2.40114 9.91405L1.92233 10.2623L2.10499 9.69901L1.62598 9.35093H2.21828L2.40114 8.78766Z" fill="#EFEFEF"/>
                                <path d="M4.39023 3.81505L4.57309 4.37817H5.16519L4.68618 4.72645L4.86904 5.28937L4.39023 4.94149L3.91122 5.28937L4.09408 4.72645L3.61487 4.37817H4.20717L4.39023 3.81505Z" fill="#EFEFEF"/>
                                <path d="M4.39023 5.80415L4.57309 6.36727H5.16519L4.68618 6.71515L4.86904 7.27847L4.39023 6.93059L3.91122 7.27847L4.09408 6.71515L3.61487 6.36727H4.20717L4.39023 5.80415Z" fill="#EFEFEF"/>
                                <path d="M4.39023 7.79326L4.57309 8.35658H5.16519L4.68618 8.70426L4.86904 9.26758L4.39023 8.91934L3.91122 9.26758L4.09408 8.70426L3.61487 8.35658H4.20717L4.39023 7.79326Z" fill="#EFEFEF"/>
                                <path d="M0.890986 9.26759L0.707976 8.70427L1.18699 8.35638H0.595038L0.581763 8.31529C0.516757 8.51634 0.458033 8.71982 0.40387 8.92558L0.41223 8.91935L0.890986 9.26759Z" fill="#EFEFEF"/>
                                <path d="M1.92232 6.28413L2.40113 5.9359L2.88014 6.28413L2.69713 5.72081L3.17614 5.37258H2.58384L2.40098 4.80966L2.21812 5.37258H1.99985C1.95931 5.43399 1.9201 5.49575 1.88068 5.55807L2.10503 5.72081L1.92232 6.28413Z" fill="#EFEFEF"/>
                                <path d="M4.39024 2.95238L4.86905 3.30027L4.68619 2.73735L5.165 2.38911H4.82299C4.53252 2.60627 4.25228 2.8361 3.98303 3.0786L3.91083 3.30027L4.39024 2.95238Z" fill="#EFEFEF"/>
                                <path d="M5.90033 2.30593L6.37934 1.95769L6.85815 2.30593L6.67529 1.74261L7.1541 1.39472H6.562L6.53717 1.31892C6.33653 1.42142 6.13796 1.52808 5.9439 1.64157L6.08299 1.74255L5.90033 2.30593Z" fill="#EFEFEF"/>
                                <path d="M6.37932 2.82071L6.56218 3.38347H7.15428L6.67527 3.73171L6.85812 4.29503L6.37932 3.94674L5.90031 4.29503L6.08317 3.73171L5.604 3.38347H6.19626L6.37932 2.82071Z" fill="#EFEFEF"/>
                                <path d="M6.37932 4.80981L6.56218 5.37258H7.15428L6.67527 5.72081L6.85812 6.28414L6.37932 5.93585L5.90031 6.28414L6.08317 5.72081L5.604 5.37258H6.19626L6.37932 4.80981Z" fill="#EFEFEF"/>
                                <path d="M6.37932 6.7989L6.56218 7.36182H7.15428L6.67527 7.70991L6.85812 8.27323L6.37932 7.92494L5.90031 8.27323L6.08317 7.70991L5.604 7.36182H6.19626L6.37932 6.7989Z" fill="#EFEFEF"/>
                                <path d="M6.37932 8.78766L6.56218 9.35093H7.15428L6.67527 9.69901L6.85812 10.2623L6.37932 9.91405L5.90031 10.2623L6.08317 9.69901L5.604 9.35093H6.19626L6.37932 8.78766Z" fill="#EFEFEF"/>
                                <path d="M8.36845 1.82596L8.55131 2.38908H9.14341L8.6646 2.73736L8.84726 3.30028L8.36845 2.9524L7.88929 3.30028L8.0723 2.73736L7.59314 2.38908H8.18539L8.36845 1.82596Z" fill="#EFEFEF"/>
                                <path d="M8.36845 3.81505L8.55131 4.37817H9.14341L8.6646 4.72645L8.84726 5.28937L8.36845 4.94149L7.88929 5.28937L8.0723 4.72645L7.59314 4.37817H8.18539L8.36845 3.81505Z" fill="#EFEFEF"/>
                                <path d="M8.36845 5.80415L8.55131 6.36727H9.14341L8.6646 6.71515L8.84726 7.27847L8.36845 6.93059L7.88929 7.27847L8.0723 6.71515L7.59314 6.36727H8.18539L8.36845 5.80415Z" fill="#EFEFEF"/>
                                <path d="M8.36845 7.79326L8.55131 8.35658H9.14341L8.6646 8.70426L8.84726 9.26758L8.36845 8.91934L7.88929 9.26758L8.0723 8.70426L7.59314 8.35658H8.18539L8.36845 7.79326Z" fill="#EFEFEF"/>
                                <path d="M10.3575 0.831604L10.5404 1.39472H11.1325L10.6535 1.7426L10.8363 2.30593L10.3575 1.95764L9.87837 2.30593L10.0614 1.7426L9.58221 1.39472H10.1743L10.3575 0.831604Z" fill="#EFEFEF"/>
                                <path d="M10.3575 2.82071L10.5404 3.38347H11.1325L10.6535 3.73171L10.8363 4.29503L10.3575 3.94674L9.87837 4.29503L10.0614 3.73171L9.58221 3.38347H10.1743L10.3575 2.82071Z" fill="#EFEFEF"/>
                                <path d="M10.3575 4.80981L10.5404 5.37258H11.1325L10.6535 5.72081L10.8363 6.28414L10.3575 5.93585L9.87837 6.28414L10.0614 5.72081L9.58221 5.37258H10.1743L10.3575 4.80981Z" fill="#EFEFEF"/>
                                <path d="M10.3575 6.7989L10.5404 7.36182H11.1325L10.6535 7.70991L10.8363 8.27323L10.3575 7.92494L9.87837 8.27323L10.0614 7.70991L9.58221 7.36182H10.1743L10.3575 6.7989Z" fill="#EFEFEF"/>
                                <path d="M10.3575 8.78766L10.5404 9.35093H11.1325L10.6535 9.69901L10.8363 10.2623L10.3575 9.91405L9.87837 10.2623L10.0614 9.69901L9.58221 9.35093H10.1743L10.3575 8.78766Z" fill="#EFEFEF"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_325_4028">
                                    <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>

                        <hr />
                    </div>
                )}

                {
                    isPhone
                    ? <input name={name} type={type} placeholder={placeholder} defaultValue={defaultValue} ref={ref} onInput={e => setValue(e.target.value)} onChange={e => setValue(e.target.value)} onBlur={e => setAutoFillScanner(true)} onFocus={e => setAutoFillScanner(false)} />
                    : <input name={name} type={type === "password" ? (showPassword ? "text" : "password") : type} placeholder={placeholder} defaultValue={defaultValue} ref={ref} onInput={e => setValue(e.target.value)} onChange={e => setValue(e.target.value)} onBlur={e => setAutoFillScanner(true)} onFocus={e => setAutoFillScanner(false)} />
                }

                <div className="icons">
                    <div className={`icon ${showPassword ? 'active' : ''}`} onClick={e => setShowPassword(!showPassword)}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3866 8.00007C10.3866 9.32007 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32007 5.61328 8.00007C5.61328 6.68007 6.67995 5.6134 7.99995 5.6134C9.31995 5.6134 10.3866 6.68007 10.3866 8.00007Z" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.9999 13.5134C10.3532 13.5134 12.5466 12.1267 14.0732 9.72671C14.6732 8.78671 14.6732 7.20671 14.0732 6.26671C12.5466 3.86671 10.3532 2.48004 7.9999 2.48004C5.64656 2.48004 3.45323 3.86671 1.92656 6.26671C1.32656 7.20671 1.32656 8.78671 1.92656 9.72671C3.45323 12.1267 5.64656 13.5134 7.9999 13.5134Z" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>

                {validators.length > 0 && (
                    <div className="validators">
                        {validatorsResults.map((result, key) => {
                            return <div className={`validator ${result ? 'okay' : 'failed'}`} key={key}></div>
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}