import {createSelector} from 'reselect';
import props from '../../../../props';

export default createSelector(
    props.wallet.methods.cashapp_cashout,
    props.user.user,
    (cashapp_cashout, user) => {
        return {
            stage: cashapp_cashout.stage,
            initialized: cashapp_cashout.initialized,
            works: cashapp_cashout.works,
            user
        }
    }
)