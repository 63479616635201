import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/wheel';
import {loadWheel, unloadWheel} from '../../../reducers/wheel/actions';
import PageContainer from '../page-container';
import Loader from '../../components/loader';
import History from './history';
import VIPPlug from './vip';
import FAQ from './faq';
import Components from '../../components';
import Plugs from './plugs';
import Main from './main';

export default () => {
    const {loaded} = useSelector(Selector),
          dispatch = useDispatch(),
          location = useLocation();

    useEffect(() => {
        if(loaded) {
            Loader.close();
            Loader.closePage();
        }
    }, [loaded]);

    useEffect(() => {
        if(!loaded)
            dispatch(loadWheel());

        return () => dispatch(unloadWheel());
    }, [location]);
 
    return (
        <PageContainer>
            <Components.Loader.Page />
            {loaded && (
                <div className="wheel-page">
                    <Components.Winners />

                    <div className="wheel-container">
                        <Main />
                        <Plugs />
                        <History />
                        <VIPPlug />
                        {/* <FAQ /> */}
                    </div>

                    <Components.DepositBonus />
                    <Components.Footer />
                </div>
            )}
        </PageContainer>
    )
}