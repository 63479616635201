import DateLib from '../../../lib/date';

export default ({deposits}) => {

    return (
        <div className={`table`}>
            {deposits.length < 1 ? (
                <div className={'plug'}>
                    <div className="icon">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.3337 15.0666V9.38664C27.3337 4.0133 26.0803 2.66663 21.0403 2.66663H10.9603C5.92033 2.66663 4.66699 4.0133 4.66699 9.38664V24.3999C4.66699 27.9466 6.61367 28.7866 8.97367 26.2533L8.98698 26.24C10.0803 25.08 11.747 25.1733 12.6936 26.4399L14.0403 28.24" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M24.2667 28.5333C26.6231 28.5333 28.5333 26.6231 28.5333 24.2667C28.5333 21.9103 26.6231 20 24.2667 20C21.9103 20 20 21.9103 20 24.2667C20 26.6231 21.9103 28.5333 24.2667 28.5333Z" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M29.3333 29.3333L28 28" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.667 9.33337H21.3337" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 14.6666H20" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>

                    <span>You do not have any game deposits<br/>participating in the bonus.</span>
                </div>
            ) : (
                <>
                    <div className="head">
                        <div className="col id">ID</div>
                        <div className="col login">Login</div>
                        <div className="col amount">Amount</div>
                        <div className="col status">Status</div>
                        <div className="col date">Date</div>
                    </div>
                    <div className="body">
                        {deposits.map((deposit, key) => {
                            return (
                                <div className={`tr`} key={key}>
                                    <div className="col id">{deposit.transactionId}</div>
                                    <div className="col game">
                                        {deposit.login}
                                    </div>
                                    <div className="col amount">
                                        ${deposit.amount}
                                    </div>
                                    <div className="col status">
                                        {deposit.status === 0 && <span className="in-game">In Game</span>}
                                        {deposit.status === 2 && <span className="counted">Counted</span>}
                                    </div>
                                    <div className="col date" dangerouslySetInnerHTML={{__html: DateLib.parse(deposit.timestamp, '<br/>')}}></div>
                                </div>
                            )
                        })}
                    </div>  
                </>
            )}
        </div>
    )
}