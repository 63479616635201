import WelcomeBonus from './welcome_bonus';
import TestYourLuck from './test_your_luck';
import Wheel from './wheel';
import Invite from './invite';
import VIP from './vip';

export default {
    WelcomeBonus,
    TestYourLuck,
    Wheel,
    Invite,
    VIP
}