import types from './types';

const InitialState = {
    buttons: {
        activate_promocode: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        default:
            return state;
    }
}