import Header from './header';
import Footer from './footer';
import DepositBonus from './deposit_bonus';
import MobileNav from './mobile-nav';
import Connection from './connection';
import Loader from './loader';
import Sidebar from './sidebar';
import Winners from './winners';
import LiveOperator from './live-operator';

export default {
    Header,
    Footer,
    DepositBonus,
    MobileNav,
    Connection,
    Loader,
    Sidebar,
    Winners,
    LiveOperator
}