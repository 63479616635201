import types from './types';
import globalTypes from '../types';

const InitialState = {
    levels_loaded: false,
    levels: [],

    loaded: false,
    profile: null,
    history: [],

    buttons: {
        redeem_lvl_up: false,
        redeem_weekly: false,
        redeem_monthly: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case globalTypes.user.logout:
            return {
                ...state,
                loaded: false,
                profile: null,
                history: []
            };
        case types.history.add:
            return {
                ...state,
                history: [
                    {
                        ...payload,
                        timestamp: payload.timestamp + Date.now()
                    },
                    ...state.history
                ]
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        case types.profile:
            return {
                ...state,
                profile: {
                    ...payload,
                    weekly: {
                        ...payload.weekly,
                        timestamp: Date.now() + payload.weekly.timestamp
                    },
                    monthly: {
                        ...payload.monthly,
                        timestamp: Date.now() + payload.monthly.timestamp
                    }
                }
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                profile: {
                    ...payload.profile,
                    weekly: {
                        ...payload.profile.weekly,
                        timestamp: Date.now() + payload.profile.weekly.timestamp
                    },
                    monthly: {
                        ...payload.profile.monthly,
                        timestamp: Date.now() + payload.profile.monthly.timestamp
                    }
                },
                history: payload.history.map(tx => {
                    return {
                        ...tx,
                        timestamp: tx.timestamp + Date.now()
                    }
                }).sort((a,b) => {
                    if(a.timestamp < b.timestamp) return 1;
                    if(a.timestamp > b.timestamp) return -1;
                    return 0
                })
            }
        case types.levels_loaded:
            return {
                ...state,
                levels_loaded: true,
                levels: payload.levels
            }
        default:
            return state;
    }
}