import {useEffect} from 'react';
import {navigate} from '../../utils/navigation';
import ModalsLib from '../../lib/modals';
import {useLocation} from 'react-router-dom';
import PageContainer from '../pages/page-container';

export default () => {
    const location = useLocation();

    useEffect(() => {
        navigate('/', () => ModalsLib.open('signin'));
    }, [location]);

    return (
        <PageContainer>
            
        </PageContainer>
    )
}