import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.app.notifications.supported,
    props.app.notifications.registered,
    props.app.notifications.button,
    (supported, registered, button) => {
        return {
            supported,
            registered,
            button
        }
    }
)