import types from './types';
import globalTypes from '../types';

const InitialState = {
    mode: "tabs",
    tab: "deposit",
    method: null,
    bonuses: {
        error: null,
        message: null,
        button: false
    },
    history: {
        loaded: false,
        list: [],
        count: 0
    },
    methods: {
        crypto_deposit: {
            loaded: false,
            name: null,
            network: null,
            token: null,
            address: null,
            minimal: null
        },
        cards_deposit: {
            stage: "create",
            create: {
                error: "",
                email: "",
                amount: 20,
                button: false
            },
            confirm: {
                error: "",
                order: {
                    amount: 0,
                    paymentId: "Hello world"
                },
                button: false
            }
        },
        cashapp_cashout: {
            initialized: false,
            works: true,
            stage: "create",
            create: {
                error: "",
                amount: "",
                cashtag: '',
                button: false
            },
            confirm: {
                error: "",
                order: {
                    paymentId: "31231",
                    cashtag: "$qdqwqd",
                    amounts: {
                        amount: 0,
                        comission: 0,
                        total: 0,
                    },
                    wager_burned: 0
                },
                button: false
            },
            confirmed: {
                order: {
                    paymentId: "31231",
                    cashtag: "$qdqwqd",
                    amounts: {
                        amount: 0,
                        comission: 0,
                        total: 0,
                    },
                    wager_burned: 0
                }
            }
        },
        cashapp_deposit: {
            initialized: false,
            works: true,
            stage: "choose",
            choose: {
                error: ""
            },
            create: {
                error: "",
                amount: 15,
                cashtag: '',
                button: false
            },
            confirm: {
                error: "",
                order: {
                    sender: "",
                    recipient: {
                        name: "",
                        cashtag: ""
                    },
                    amount: 0,
                    paymentId: ""
                },
                button: false
            },
            confirmed: {
                order: {
                    sender: "",
                    recipient: {
                        name: "",
                        cashtag: ""
                    },
                    amount: 0,
                    paymentId: ""                    
                }
            }
        }
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {      
        case globalTypes.user.logout:
            return InitialState;
        case types.cashapp_cashout.clear:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_cashout: InitialState.methods.cashapp_cashout
                }
            }
        case types.cashapp_cashout.order:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_cashout: {
                        ...state.methods.cashapp_cashout,
                        confirm: {
                            ...state.methods.cashapp_cashout.confirm,
                            order: payload
                        },
                        confirmed: {
                            ...state.methods.cashapp_cashout.confirmed,
                            order: payload
                        }
                    }
                }
            }
        case types.cashapp_cashout.initialize:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_cashout: {
                        ...state.methods.cashapp_cashout,
                        initialized: true,
                        works: payload
                    }
                }
            }
        case types.cashapp_cashout.setStage:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_cashout: {
                        ...state.methods.cashapp_cashout,
                        stage: payload,
                        create: {
                            ...state.methods.cashapp_cashout.create,
                            error: ""
                        },
                        confirm: {
                            ...state.methods.cashapp_cashout.confirm,
                            error: ""
                        },
                        confirmed: {
                            ...state.methods.cashapp_cashout.confirmed,
                            error: ""
                        }
                    }
                }
            }

        case types.cashapp_cashout.toggleStageButton:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_cashout: {
                        ...state.methods.cashapp_cashout,
                        [state.methods.cashapp_cashout.stage]: {
                            ...state.methods.cashapp_cashout[state.methods.cashapp_cashout.stage],
                            button: !state.methods.cashapp_cashout[state.methods.cashapp_cashout.stage].button
                        }
                    }
                }
            }

        case types.cashapp_cashout.updateStageData:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_cashout: {
                        ...state.methods.cashapp_cashout,
                        [state.methods.cashapp_cashout.stage]: {
                            ...state.methods.cashapp_cashout[state.methods.cashapp_cashout.stage],
                            ...payload
                        }
                    }
                }
            }
        case types.bonuses.toggleButton:
            return {
                ...state,
                bonuses: {
                    ...state.bonuses,
                    button: !state.bonuses.button
                }
            }
        case types.bonuses.update:
            return {
                ...state,
                bonuses: {
                    ...state.bonuses,
                    ...payload
                }
            }
        case types.history.edit:
            return {
                ...state,
                history: {
                    ...state.history,
                    list: state.history.loaded ? state.history.list.map(tx => {
                        return tx.paymentId === payload.paymentId ? {
                            ...payload,
                            active: false,
                            button: false,
                            timestamp: Date.now() + payload.timestamp
                        } : tx;
                    }) : state.history.list
                }
            }
        case types.history.add:
            return {
                ...state,
                history: {
                    ...state.history,
                    list: [
                        {
                            ...payload,
                            active: false,
                            button: false,
                            timestamp: Date.now() + payload.timestamp
                        },
                        ...state.history.list
                    ]
                }
            }
        case types.history.toggleButton:
            return {
                ...state,
                history: {
                    ...state.history,
                    list: state.history.list.map(tx => {
                        return tx.paymentId === payload ? {
                            ...tx,
                            button: !tx.button
                        } : tx
                    })
                }
            }
        case types.history.toggle:
            return {
                ...state,
                history: {
                    ...state.history,
                    list: state.history.list.map(tx => {
                        return tx.paymentId === payload ? {
                            ...tx,
                            active: !tx.active
                        } : tx
                    })
                }
            }
        case types.history.loaded:
            return {
                ...state,
                history: {
                    loaded: true,
                    list: payload.list.map(tx => {
                        return {
                            ...tx,
                            timestamp: Date.now() + tx.timestamp,
                            active: false,
                            button: false
                        }
                    }).sort((a, b) => {
                        if(a.timestamp > b.timestamp) return -1;
                        if(a.timestamp < b.timestamp) return 1;
                        return 0
                    }),
                    count: payload.count
                }
            }
        // Cards deposit
        case types.cards_deposit.order:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cards_deposit: {
                        ...state.methods.cards_deposit,
                        confirm: {
                            ...state.methods.cards_deposit.confirm,
                            order: payload
                        }
                    }
                }
            }
        case types.cards_deposit.setStage:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cards_deposit: {
                        ...state.methods.cards_deposit,
                        stage: payload
                    }
                }
            }

        case types.cards_deposit.toggleStageButton:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cards_deposit: {
                        ...state.methods.cards_deposit,
                        [state.methods.cards_deposit.stage]: {
                            ...state.methods.cards_deposit[state.methods.cards_deposit.stage],
                            button: !state.methods.cards_deposit[state.methods.cards_deposit.stage].button
                        }
                    }
                }
            }
        case types.cards_deposit.updateStageData:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cards_deposit: {
                        ...state.methods.cards_deposit,
                        [state.methods.cards_deposit.stage]: {
                            ...state.methods.cards_deposit[state.methods.cards_deposit.stage],
                            ...payload
                        }
                    }
                }
            }
        // Crypto deposit
        case types.crypto_deposit.loaded:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    crypto_deposit: {
                        ...state.methods.crypto_deposit,
                        ...payload,
                        loaded: true
                    }
                }
            }
        case types.crypto_deposit.change:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    crypto_deposit: (state.methods.crypto_deposit.token !== payload.token) ? {
                        loaded: false,
                        name: payload.name,
                        network: payload.network,
                        token: payload.token
                    } : state.methods.crypto_deposit
                }
            }
        // Cashapp Deposit
        case types.cashapp_deposit.clear:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_deposit: InitialState.methods.cashapp_deposit
                }
            }
        case types.cashapp_deposit.order:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_deposit: {
                        ...state.methods.cashapp_deposit,
                        confirm: {
                            ...state.methods.cashapp_deposit.confirm,
                            order: payload
                        },
                        confirmed: {
                            ...state.methods.cashapp_deposit.confirmed,
                            order: payload
                        }
                    }
                }
            }
        case types.cashapp_deposit.initialize:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_deposit: {
                        ...state.methods.cashapp_deposit,
                        initialized: true,
                        works: payload
                    }
                }
            }
        case types.cashapp_deposit.setStage:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_deposit: {
                        ...state.methods.cashapp_deposit,
                        stage: payload,
                        create: {
                            ...state.methods.cashapp_deposit.create,
                            error: ""
                        },
                        confirm: {
                            ...state.methods.cashapp_deposit.confirm,
                            error: ""
                        },
                        confirmed: {
                            ...state.methods.cashapp_deposit.confirmed,
                            error: ""
                        }
                    }
                }
            }

        case types.cashapp_deposit.toggleStageButton:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_deposit: {
                        ...state.methods.cashapp_deposit,
                        [state.methods.cashapp_deposit.stage]: {
                            ...state.methods.cashapp_deposit[state.methods.cashapp_deposit.stage],
                            button: !state.methods.cashapp_deposit[state.methods.cashapp_deposit.stage].button
                        }
                    }
                }
            }

        case types.cashapp_deposit.updateStageData:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_deposit: {
                        ...state.methods.cashapp_deposit,
                        [state.methods.cashapp_deposit.stage]: {
                            ...state.methods.cashapp_deposit[state.methods.cashapp_deposit.stage],
                            ...payload
                        }
                    }
                }
            }
        // 
        case types.preset:
            return {
                ...state,
                mode: payload.mode || state.mode,
                tab: payload.tab || state.tab,
                method: payload.method || state.method
            }
        case types.tab:
            return {
                ...state,
                tab: payload
            }
        default:
            return state;
    }
}