import types from '../types';
import API from '../../../lib/api';
import {toast} from 'react-toastify';

export const loadWallet = (token) => async dispatch => {
    const response = await API.call({
        path: '/payments/client/crypto/deposit/getAddress',
        method: 'POST',
        body: {
            token
        }
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);

        dispatch({
            type: types.preset,
            payload: {
                mode: 'tabs'
            }
        });

        return;
    }

    dispatch({
        type: types.crypto_deposit.loaded,
        payload: response.data
    });
}

export const changeCoin = ({
    name,
    network,
    token
}) => {
    return {
        type: types.crypto_deposit.change,
        payload: {
            name,
            network,
            token
        }
    }
}
