import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.wheel.status,
    (status) => {
        return {
            status
        }
    }
)