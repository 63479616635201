import {useSelector} from 'react-redux';
import Selector from '../../../selectors/pages/vip/invite';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import {navigate} from '../../../utils/navigation';
import {getInviteLink} from '../../../utils/user';

export default () => {
    const {user} = useSelector(Selector);

    return (
        <div className="invite-plug">
            <div className="layer"><div className="money"></div></div>
            <div className="layer"><div className="money"></div></div>
            <div className="layer"><div className="package"></div></div>
            <div className="layer">
                <div className="content">
                    <div className="left">
                        <div className="title">Friends will pump it up!</div>
                        <div className="description">Get XP from your friends' deposits! Just invite them via your referral link and earn from their deposits!</div>
                    </div>
                    <div className="right">
                        <div className="referral">
                            <div className="text">Share your referral link with your friends and earn money with them!</div>
                            
                            <CopyToClipboard text={`Get free $5 and a +50% first deposit bonus on my referral link ${getInviteLink(user.type, user.referral_code)}`} onCopy={e => {
                                toast.dismiss();
                                toast.info(`The referral link has been copied to the clipboard`);
                            }}>
                                <div className="link">
                                    <span>{getInviteLink(user.type, user.referral_code)}</span>
                                    <div className="copy">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.6654 8.60007V11.4001C10.6654 13.7334 9.73203 14.6667 7.3987 14.6667H4.5987C2.26536 14.6667 1.33203 13.7334 1.33203 11.4001V8.60007C1.33203 6.26674 2.26536 5.3334 4.5987 5.3334H7.3987C9.73203 5.3334 10.6654 6.26674 10.6654 8.60007Z" fill="#59EF79"/>
                                            <path d="M11.3992 1.3334H8.59916C6.53249 1.3334 5.59916 2.06674 5.39916 3.80007C5.3325 4.20007 5.66583 4.46674 6.06583 4.46674H7.46583C10.2658 4.46674 11.5325 5.7334 11.5325 8.5334V10.0001C11.5325 10.4001 11.8658 10.6667 12.1992 10.6667C13.9325 10.4667 14.6658 9.5334 14.6658 7.46674V4.60007C14.6658 2.26674 13.7325 1.3334 11.3992 1.3334Z" fill="#59EF79"/>
                                        </svg>
                                    </div>
                                </div>
                            </CopyToClipboard>
                        </div>

                        <div className="button" onClick={e => navigate('/invite')}>View more</div>
                    </div>
                </div>
            </div>
        </div>
    )
} 