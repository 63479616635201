import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.wheel.wheel,
    props.wheel.buttons.spin,
    (wheel, button) => {
        return {
            wheel,
            button
        }
    }
)