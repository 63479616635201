import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.modals.modal,
    props.user.isAuth,
    (modal, isAuth) => {
        return {
            modal,
            isAuth
        }
    }
)