export default ({
    className,
    children,
    active,
    disabled = false,
    onClick = e => e
}) => {
    if(active) 
        return (
            <div className={`white-button inactive ${className}`}>
                <div className="button-dots">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </div>
        )

    return (
        <div className={`white-button ${className}`} style={{
            opacity: disabled ? 0.8 : 1,
            cursor: disabled ? 'no-drop' : 'pointer',
        }} onClick={e => {
            if(disabled)
                return;

            return onClick(e);
        }}>
            {children}
        </div>
    );
}