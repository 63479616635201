import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/components/movile-nav';
import {toggleSidebar} from '../../reducers/app/actions';
import {setGame} from '../../reducers/user/actions';
import {navigate} from '../../utils/navigation';
import jQuery from 'jquery';

export default () => {
    const dispatch = useDispatch(),
          location = useLocation(),
          [active, setActive] = useState(null),
          {user, game} = useSelector(Selector);

    const getActiveElement = () => {
        if(location.pathname === '/')
            return 'home';

        if(location.pathname === '/game' || location.pathname.indexOf('/game/') === 0)
            return 'game';

        if(location.pathname === '/freecash')
            return 'freecash';

        if(location.pathname === '/invite')
            return 'invite';

        if(location.pathname === '/vip')
            return 'vip';

        return null;
    }

    const homePageScrollToGamesHandler = () => {
        const games_container = document.querySelector('.home .games');

        if(games_container) {
            jQuery('html').animate({
                scrollTop: games_container.offsetTop - 100
            }, {
                duration: 200,
                easing: 'linear'
            });
        }
    }

    useEffect(() => {
        setActive(getActiveElement());
    }, [location]);


    return (
        <div className="mobile-nav animate__animated animate__fadeInRight">
            <div className="navigation">
                <div className={`link ${active == 'home' ? 'active' : ''}`} onClick={e => {
                    setActive('home');
                    navigate('/');
                }}>
                    <div className="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.02 2.83998L3.63 7.03998C2.73 7.73998 2 9.22998 2 10.36V17.77C2 20.09 3.89 21.99 6.21 21.99H17.79C20.11 21.99 22 20.09 22 17.78V10.5C22 9.28998 21.19 7.73998 20.2 7.04998L14.02 2.71998C12.62 1.73998 10.37 1.78998 9.02 2.83998Z" fill="#DFDFEC" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 17.99V14.99" stroke="#242737" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className="active"></div>
                </div>
                <div className={`link ${active == 'game' ? 'active' : ''}`} onClick={e => {
                    setActive('game');

                    if(game)
                        navigate(`/game/${game.slug}`);
                    else
                        navigate('/', homePageScrollToGamesHandler);
                }}>
                    {game ? (
                        <div className="game">
                            <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${game.slug}.png)`}}></div>
                        </div>
                    ) : (
                        <div className="icon">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.33325 11.6672C2.33325 8.21611 5.31302 5.51945 8.74702 5.86285L13.3034 6.31849C13.7666 6.36481 14.2332 6.36481 14.6964 6.31849L19.2528 5.86285C22.6868 5.51945 25.6666 8.21611 25.6666 11.6672V18.6667C25.6666 22.8588 20.188 24.4477 17.945 20.9061C16.3463 18.3818 12.6936 18.2975 10.9801 20.7454L10.6485 21.2191C8.08577 24.8802 2.33325 23.0668 2.33325 18.598V11.6672Z" fill="#DFDFEC" stroke="#DFDFEC" stroke-width="2.33333"/>
                                <circle cx="21.0001" cy="11.6381" r="0.758333" fill="black" stroke="#1C1F2D" stroke-width="1.51667"/>
                                <circle cx="18.6668" cy="15.1382" r="0.7" fill="black" stroke="#1C1F2D" stroke-width="1.4"/>
                                <path d="M9.33325 15.7215L9.33325 11.0548" stroke="#1C1F2D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.00008 13.3882H11.6667" stroke="#1C1F2D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>  
                    )}
                    <div className="active"></div>
                </div>
                <div className={`link ${active == 'freecash' ? 'active' : ''}`} onClick={e => {
                    setActive('freecash');
                    navigate('/freecash')
                }}>
                    <div className="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.2501 7.75H12.0001C11.8824 7.74521 11.7673 7.7131 11.6641 7.65622C11.5609 7.59934 11.4724 7.51924 11.4055 7.42225C11.3385 7.32526 11.2951 7.21404 11.2785 7.09737C11.262 6.9807 11.2728 6.86178 11.3101 6.75C11.6601 5.87 11.9801 4.97 12.2401 4.09C12.4601 3.41 13.3201 1.28 15.4801 1.28C16.3219 1.25302 17.1401 1.56112 17.755 2.13667C18.3699 2.71223 18.7314 3.50823 18.7601 4.35C18.7601 6.28 16.5701 7.07 15.7601 7.35C14.6149 7.64883 13.4331 7.7835 12.2501 7.75ZM15.4701 2.75C14.3201 2.75 13.8001 4.08 13.6701 4.49C13.4901 5.07 13.3001 5.65 13.0901 6.23C13.807 6.22544 14.5201 6.12454 15.2101 5.93C15.7801 5.73 17.2101 5.19 17.2101 4.32C17.1793 3.88412 16.9809 3.47712 16.6564 3.1844C16.332 2.89167 15.9068 2.73598 15.4701 2.75Z" fill="#DFDFEC"/>
                            <path d="M11.72 7.75C10.5627 7.77039 9.40824 7.62902 8.29004 7.33C5.80004 6.52 5.29004 5.25 5.29004 4.33C5.29422 3.94142 5.377 3.5577 5.53339 3.20196C5.68978 2.84621 5.91655 2.5258 6.20004 2.26C6.83044 1.62582 7.68587 1.2664 8.58004 1.26C10.26 1.26 11.11 2.83 11.74 3.95C12.1494 4.86814 12.4839 5.81791 12.74 6.79C12.771 6.90031 12.7763 7.01626 12.7555 7.12893C12.7347 7.24161 12.6884 7.34802 12.62 7.44C12.5538 7.53289 12.467 7.6092 12.3664 7.66297C12.2658 7.71675 12.1541 7.74653 12.04 7.75H11.72ZM8.47004 2.75C7.99516 2.77046 7.54687 2.97488 7.22004 3.32C7.0775 3.4457 6.96224 3.5993 6.8814 3.77129C6.80057 3.94329 6.75586 4.13004 6.75004 4.32C6.75004 4.93 7.49004 5.52 8.75004 5.93C9.48526 6.12004 10.2407 6.22077 11 6.23C10.8383 5.69746 10.6412 5.1763 10.41 4.67C9.83004 3.56 9.27004 2.77 8.50004 2.75H8.47004Z" fill="#DFDFEC"/>
                            <path d="M21.25 10.25V9C21.25 8.27065 20.9603 7.57118 20.4445 7.05546C19.9288 6.53973 19.2293 6.25 18.5 6.25H5.5C4.77065 6.25 4.07118 6.53973 3.55546 7.05546C3.03973 7.57118 2.75 8.27065 2.75 9V10.25H21.25ZM12.75 11.75V22.75H18.5C19.2293 22.75 19.9288 22.4603 20.4445 21.9445C20.9603 21.4288 21.25 20.7293 21.25 20V11.75H12.75ZM11.25 11.75H2.75V20C2.75 20.7293 3.03973 21.4288 3.55546 21.9445C4.07118 22.4603 4.77065 22.75 5.5 22.75H11.25V11.75Z" fill="#DFDFEC"/>
                        </svg>
                    </div>
                    <div className="active"></div>
                </div>
                <div className={`link ${active == 'invite' ? 'active' : ''}`} onClick={e => {
                    setActive('invite');
                    navigate('/invite');
                }}>
                    <div className="icon">
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_506_1034)">
                                <path d="M4.33325 9.75C6.12818 9.75 7.58325 8.29493 7.58325 6.5C7.58325 4.70507 6.12818 3.25 4.33325 3.25C2.53833 3.25 1.08325 4.70507 1.08325 6.5C1.08325 8.29493 2.53833 9.75 4.33325 9.75Z" fill="#DFDFEC"/>
                                <path d="M7.8975 11.9925C6.80712 12.6729 5.90784 13.6196 5.28431 14.7435C4.66078 15.8674 4.33352 17.1314 4.33333 18.4167H2.16667C1.59256 18.415 1.04245 18.1861 0.636496 17.7802C0.230538 17.3742 0.00171462 16.8241 0 16.25L0 14.0833C0.00257193 13.2222 0.345808 12.397 0.954744 11.7881C1.56368 11.1791 2.38884 10.8359 3.25 10.8333H5.41667C5.88962 10.8344 6.35664 10.9387 6.78512 11.1389C7.21361 11.3391 7.59324 11.6304 7.8975 11.9925Z" fill="#DFDFEC"/>
                                <path d="M21.6667 9.75C23.4617 9.75 24.9167 8.29493 24.9167 6.5C24.9167 4.70507 23.4617 3.25 21.6667 3.25C19.8718 3.25 18.4167 4.70507 18.4167 6.5C18.4167 8.29493 19.8718 9.75 21.6667 9.75Z" fill="#DFDFEC"/>
                                <path d="M26 14.0833V16.25C25.9983 16.8241 25.7695 17.3742 25.3635 17.7802C24.9576 18.1861 24.4075 18.415 23.8334 18.4167H21.6667C21.6665 17.1314 21.3393 15.8674 20.7157 14.7435C20.0922 13.6196 19.1929 12.6729 18.1025 11.9925C18.4068 11.6304 18.7864 11.3391 19.2149 11.1389C19.6434 10.9387 20.1104 10.8344 20.5834 10.8333H22.75C23.6112 10.8359 24.4364 11.1791 25.0453 11.7881C25.6542 12.397 25.9975 13.2222 26 14.0833Z" fill="#DFDFEC"/>
                                <path d="M13.0001 11.9167C15.3933 11.9167 17.3334 9.97657 17.3334 7.58333C17.3334 5.1901 15.3933 3.25 13.0001 3.25C10.6068 3.25 8.66675 5.1901 8.66675 7.58333C8.66675 9.97657 10.6068 11.9167 13.0001 11.9167Z" fill="#DFDFEC"/>
                                <path d="M19.5 18.4167V19.5C19.4974 20.3612 19.1542 21.1863 18.5453 21.7953C17.9363 22.4042 17.1112 22.7474 16.25 22.75H9.75C8.88884 22.7474 8.06368 22.4042 7.45474 21.7953C6.84581 21.1863 6.50257 20.3612 6.5 19.5V18.4167C6.5 16.9801 7.07068 15.6023 8.0865 14.5865C9.10233 13.5707 10.4801 13 11.9167 13H14.0833C15.5199 13 16.8977 13.5707 17.9135 14.5865C18.9293 15.6023 19.5 16.9801 19.5 18.4167Z" fill="#DFDFEC"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_506_1034">
                                    <rect width="26" height="26" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="active"></div>
                </div>
                <div className={`link vip ${active == 'vip' ? 'active' : ''}`} onClick={e => {
                    setActive('vip');
                    navigate('/vip')
                }}>
                    <div className="icon">
                        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.8334 5.3925C22.5249 5.18339 22.1607 5.07161 21.788 5.07161C21.4153 5.07161 21.0511 5.18339 20.7426 5.3925L17.4926 7.4075C17.4603 7.42216 17.4252 7.42975 17.3897 7.42975C17.3542 7.42975 17.3191 7.42216 17.2868 7.4075C17.2145 7.38795 17.1524 7.34143 17.1134 7.2775L13.6468 1.0375C13.4783 0.757075 13.24 0.52503 12.9553 0.363934C12.6705 0.202837 12.3489 0.118172 12.0218 0.118172C11.6946 0.118172 11.373 0.202837 11.0883 0.363934C10.8035 0.52503 10.5653 0.757075 10.3968 1.0375L6.8001 7.26667C6.7611 7.33059 6.69906 7.37712 6.62677 7.39667C6.59444 7.41133 6.55935 7.41892 6.52386 7.41892C6.48836 7.41892 6.45327 7.41133 6.42094 7.39667L3.22511 5.34917C2.91571 5.15336 2.55548 5.05304 2.18941 5.06075C1.82334 5.06845 1.46765 5.18384 1.16677 5.3925C0.857982 5.60349 0.618933 5.90157 0.480037 6.24881C0.341141 6.59605 0.308676 6.97676 0.386771 7.3425L2.20677 15.89C2.29618 16.3153 2.52899 16.697 2.86624 16.9711C3.20348 17.2453 3.62467 17.3952 4.05927 17.3958H19.9734C20.408 17.3952 20.8292 17.2453 21.1665 16.9711C21.5037 16.697 21.7365 16.3153 21.8259 15.89L23.6459 7.3425C23.7207 6.9735 23.6833 6.59054 23.5385 6.24299C23.3937 5.89544 23.1481 5.59922 22.8334 5.3925Z" fill="#FFD014"/>
                        </svg>
                    </div>
                    <div className="active"></div>
                </div>
            </div>

            <div className="hamburger" onClick={e => dispatch(toggleSidebar())}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 7H21" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M3 12H21" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M3 17H13" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
            </div>
        </div>
    )
}