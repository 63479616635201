import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/modals/wallet/history';
import {getHistory, toggleHistoryTransaction, setTab} from '../../../../../reducers/wallet/actions';
import Types from './types';
import BlueButton from '../../../../components/blue-button';

export default () => {
    const {loaded, list, count} = useSelector(Selector),
          dispatch = useDispatch();

    useEffect(() => {
        if(!loaded)
            dispatch(getHistory());
    }, [loaded]);

    if(!loaded)
        return (
            <div className="modal-loader">
                <div className="element"></div>
            </div>
        )

    return (
        <div className="payments-history">
            {list.length < 1 ? (
                <div className="empty-plug">
                    <div className="empty-plug-content">
                        <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M78.1733 58.7167C76.3533 60.4933 75.3132 63.05 75.5732 65.78C75.9632 70.46 80.2532 73.8833 84.9332 73.8833H93.1666V79.04C93.1666 88.01 85.8432 95.3334 76.8732 95.3334H27.1266C18.1566 95.3334 10.8333 88.01 10.8333 79.04V49.8768C10.8333 40.9068 18.1566 33.5834 27.1266 33.5834H76.8732C85.8432 33.5834 93.1666 40.9068 93.1666 49.8768V56.1168H84.4132C81.9866 56.1168 79.7766 57.07 78.1733 58.7167Z" stroke="#DFDFEC" stroke-width="5.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.8333 53.7766V33.9735C10.8333 28.8169 13.9966 24.2233 18.8066 22.4033L53.2132 9.40335C58.5866 7.36668 64.3499 11.3535 64.3499 17.1168V33.5834" stroke="#DFDFEC" stroke-width="5.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M97.7548 60.5374V69.4643C97.7548 71.8476 95.8481 73.7974 93.4214 73.8841H84.9281C80.2481 73.8841 75.9581 70.4608 75.5681 65.7808C75.3081 63.0508 76.3481 60.4941 78.1681 58.7174C79.7714 57.0708 81.9814 56.1176 84.4081 56.1176H93.4214C95.8481 56.2042 97.7548 58.154 97.7548 60.5374Z" stroke="#DFDFEC" stroke-width="5.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M30.3333 52H60.6666" stroke="#DFDFEC" stroke-width="5.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <span>
                            You haven't made any transactions yet! 
                            <b>It's time to start!</b>
                        </span>

                        <BlueButton onClick={e => {
                            dispatch(setTab('deposit'));
                        }}>Make deposit</BlueButton>
                    </div>
                </div>
            ) : (
                <>
                    {list.map((tx, key) => {
                        if(typeof Types[tx.type] !== 'undefined') {
                            const Template = Types[tx.type];
                            
                            return <Template key={key} tx={tx} {...tx} onClick={e => {
                                dispatch(toggleHistoryTransaction(tx.paymentId))
                            }} />
                        }

                        return '';
                    })}
                </>
            )}
        </div>
    )
}