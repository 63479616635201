import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.games.testyourluck,
    (testyourluck) => {
        return {
            deposits_count: testyourluck ? testyourluck.deposits.filter(d => d.status === 2).length : 0,
            bonus: testyourluck.bonus
        }
    }
)