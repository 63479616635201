import {useState} from 'react';

import All from './all';
import My from './my';

export default () => {
    const [tab, setTab] = useState('my');

    return (
        <div className="wheel-history">
            <div className="top">
                <div className="title">History</div>
                <div className="tabs">
                    {/* <span className={`${tab === 'all' ? 'active' : ''}`} onClick={e => setTab('all')}>Live winners</span> */}
                    <span className={`${tab === 'my' ? 'active' : ''}`} onClick={e => setTab('my')}>My history</span>
                </div>
            </div>

            {/* {tab === 'all' && <All />} */}
            {tab === 'my' && <My />}
        </div>
    )
}