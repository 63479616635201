import {useSelector} from 'react-redux';
import Selector from '../../../../../../../selectors/pages/home/welcome_bonus';
import ModalsLib from '../../../../../../../lib/modals';
import Timer from './timer';

export default () => {
    const {isAuth, active, timestamp} = useSelector(Selector);

    if(!active)
        return <></>;
    
    return (
        <div className="slide deposit size-params">
            <div className="size-params-layer layer0">
                <div className="gift"></div>
            </div>
            <div className="layer-margin size-params-layer layer1">
                <div className="gift"></div>
            </div>
            <div className="layer-margin size-params-layer layer2">
                <div className="girl"></div>
            </div>
            <div className="layer-margin size-params slide-details">
                <div className="title">
                    Welcome<br/>
                    to Lucksy
                </div>
                <div className="descriptions">Get +50% up to 1 000 USD</div>
                <div className="descriptions-short">Collect your first deposit<br />bonus right now</div>
                <div className="details">
                    <div className="button" onClick={e => {
                        if(isAuth)
                            ModalsLib.open('wallet')
                        else
                            ModalsLib.open('signin')
                    }}>Claim now</div>
                    <Timer timestamp={timestamp} />
                </div>
            </div>
        </div>
    )
}