import app from './app/types';
import user from './user/types';
import games from './games/types';
import modals from './modals/types';
import wallet from './wallet/types';
import vip from './vip/types';
import wheel from './wheel/types';
import invite from './invite/types';

export default {
    app,
    user,
    games,
    modals,
    wallet,
    vip,
    wheel,
    invite
}