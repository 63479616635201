import types from './types';
import globalTypes from '../types';

const InitialState = {
    loaded: false,
    stats: null,
    referrals: [],
    buttons: {
        redeem: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.resetAmount:
            return {
                ...state,
                stats: state.stats ? {
                    ...state.stats,
                    amount: 0
                } : state.stats
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        case globalTypes.user.logout:
            return InitialState;
        case types.loaded:
            return {
                ...state,
                loaded: true,
                stats: payload.stats,
                referrals: payload.referrals.map(r => {
                    return {
                        ...r,
                        timestamp: r.timestamp + Date.now()
                    }
                })
            }
        default:
            return state;
    }
}