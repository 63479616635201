export default () => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1275_6793)">
            <mask id="mask0_1275_6793" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
                <path d="M36 0H0V36H36V0Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_1275_6793)">
                <path d="M18.0015 36.0014C27.9426 36.0014 36.0015 27.9425 36.0015 18.0014C36.0015 8.06022 27.9426 0.00134277 18.0015 0.00134277C8.06034 0.00134277 0.00146484 8.06022 0.00146484 18.0014C0.00146484 27.9425 8.06034 36.0014 18.0015 36.0014Z" fill="#26A17B"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7524 19.6877V19.6856C19.6336 19.6941 19.0213 19.7309 17.6551 19.7309C16.5643 19.7309 15.7964 19.6985 15.5264 19.6856V19.6887C11.3274 19.5041 8.1932 18.773 8.1932 17.8982C8.1932 17.0244 11.3274 16.2932 15.5264 16.1054V18.9608C15.8008 18.9803 16.5869 19.0268 17.6734 19.0268C18.977 19.0268 19.6304 18.9728 19.7524 18.962V16.1075C23.9428 16.2944 27.0694 17.0255 27.0694 17.8982C27.0694 18.773 23.9428 19.502 19.7524 19.6877ZM19.7524 15.8105V13.2552H25.5995V9.35852H9.67928V13.2552H15.5264V15.8094C10.7744 16.0275 7.20068 16.9694 7.20068 18.0968C7.20068 19.2243 10.7744 20.165 15.5264 20.3843V28.5728H19.7524V20.3822C24.4969 20.1639 28.0619 19.2233 28.0619 18.0968C28.0619 16.9704 24.4969 16.0298 19.7524 15.8105Z" fill="white"/>
                <path d="M18.0015 36.0014C27.9464 36.0014 36.0015 27.9405 36.0015 18.0014C36.0015 8.06212 27.9464 0.00134277 18.0015 0.00134277C8.05664 0.00134277 0.00146484 8.06212 0.00146484 18.0014C0.00146484 27.9405 8.06354 36.0014 18.0015 36.0014Z" fill="#FF060A"/>
                <path d="M28.123 14.7671C27.13 13.8459 25.7506 12.4425 24.6334 11.4493L24.5644 11.4061C24.4541 11.3197 24.3299 11.2478 24.1988 11.1974C21.4954 10.6936 8.91615 8.34011 8.67477 8.36891C8.60582 8.3761 8.53685 8.4049 8.48168 8.44088L8.41961 8.49125C8.34374 8.57042 8.28167 8.66399 8.24718 8.77195L8.2334 8.81513V9.05263V9.08861C9.64719 13.0326 15.2402 25.9443 16.3438 28.9815C16.4126 29.1901 16.5368 29.5788 16.7713 29.6004H16.8265C16.9507 29.6004 17.4886 28.8879 17.4886 28.8879C17.4886 28.8879 27.0955 17.2429 28.0679 16.005C28.192 15.8539 28.3024 15.6883 28.3988 15.5157C28.4264 15.3789 28.4126 15.2421 28.3645 15.1126C28.3162 14.9831 28.2265 14.8607 28.123 14.7671ZM19.9438 16.1274L24.0403 12.7304L26.4472 14.9471L19.9438 16.1274ZM18.3506 15.9043L11.2955 10.1178L22.7161 12.2266L18.3506 15.9043ZM18.9851 17.4157L26.2058 16.2498L17.9506 26.2033L18.9851 17.4157ZM10.3368 10.7008L17.7644 16.9983L16.6885 26.2105L10.3368 10.7008Z" fill="white"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_1275_6793">
                <rect width="36" height="36" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)