import types from '../types';
import API from '../../../lib/api';
import ModalsLib from '../../../lib/modals';
import {toast} from 'react-toastify';

export const initialize = () => async dispatch => {
    const response = await API.call({
        path: '/payments/client/cashapp_p2p/cashout/initialize',
        method: "POST"
    });

    if(response.success) {
        dispatch({
            type: types.cashapp_cashout.initialize,
            payload: response.status
        });
    } else {
        toast.dismiss();
        toast.error(response.error);

        dispatch({
            type: types.preset,
            payload: {
                mode: 'tabs',
                tab: 'cashout'
            }
        });
    }
}

export const createOrder = (data) => async dispatch => {
    // Disable button and clear error
    dispatch({
        type: types.cashapp_cashout.updateStageData,
        payload: {
            error: null,
            ...data, // Save new data
            button: true
        }
    });

    // Make API request
    const response = await API.call({
        path: '/payments/client/cashapp_p2p/cashout/createOrder',
        method: 'POST',
        body: data
    });

    if(!response.success) {
        dispatch({
            type: types.cashapp_cashout.updateStageData,
            payload: {
                error: response.error,
                button: false
            }
        });
    } else {
        dispatch({
            type: types.cashapp_cashout.order,
            payload: response.order
        });

        dispatch({
            type: types.cashapp_cashout.updateStageData,
            payload: {
                button: false
            }
        });

        dispatch({
            type: types.cashapp_cashout.setStage,
            payload: 'confirm'
        });
    }
}

export const confirmOrder = (paymentId) => async dispatch => {
    // Disable button and clear error
    dispatch({
        type: types.cashapp_cashout.updateStageData,
        payload: {
            error: null,
            button: true
        }
    });

    // Make API request
    const response = await API.call({
        path: '/payments/client/cashapp_p2p/cashout/approveOrder',
        method: 'POST',
        body: {
            paymentId
        }
    });

    if(!response.success) {
        dispatch({
            type: types.cashapp_cashout.updateStageData,
            payload: {
                error: response.error,
                button: false
            }
        });
    } else {
        dispatch({
            type: types.cashapp_cashout.updateStageData,
            payload: {
                button: false
            }
        });

        dispatch({
            type: types.cashapp_cashout.order,
            payload: response.order
        });

        dispatch({
            type: types.cashapp_cashout.setStage,
            payload: 'confirmed'
        });
    }
}

export const cancelCashout = paymentId => async dispatch => {
    dispatch({
        type: types.history.toggleButton,
        payload: paymentId
    });

    // Make API request
    const response = await API.call({
        path: '/payments/client/cashapp_p2p/cashout/cancelCashout',
        method: 'POST',
        body: {
            paymentId
        }
    });

    dispatch({
        type: types.history.toggleButton,
        payload: paymentId
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
    } else {
        toast.dismiss();
        toast.success(response.message);
    }
}

export const setStage = stage => {
    return {
        type: types.cashapp_cashout.setStage,
        payload: stage
    }
}

export const clearStages = () => { 
    return {
        type: types.cashapp_cashout.clear
    }
}