export const getCashoutsRange = ({
    type,
    amount,
    loginBalance,
    sessions
}) => {
    const session_amount = amount + loginBalance,
          minimal_cashout = parseInt(session_amount * 4);

    if(!type)
        return {
            minimal_cashout: 50,
            text: '$50'
        }

    if(type === 'cash') {
        const rules = sessions.filter(s => s.type === 'cash').filter(s => s.amounts.from <= session_amount && s.amounts.to >= session_amount)[0] || false;

        if(!rules)
            return {
                rules,
                text: 'from $50 to $50'
            };

        if(minimal_cashout < 50)
            return {
                rules,
                text: `from $50 to $${rules.maximal_cashout}`
            };

        return {
            rules,
            text: `from $${Math.floor(session_amount * 4)} to $${rules.maximal_cashout}`
        };
    } else if(type === 'testyourluck') {
        const rules = sessions.filter(s => s.type === 'testyourluck')[0] || false;

        if(!rules)
            return {
                rules,
                text: 'N/A'
            };

        if(rules.cashout === rules.minimal_cashout)
            return {
                rules,
                text: `$${rules.cashout}`
            };

        return {
            rules,
            text: `$${rules.cashout} when you reach $${rules.minimal_cashout}`
        };
    } else if(type === 'freeplay') {
        const rules = sessions.filter(s => s.type === 'freeplay')[0] || false;

        if(!rules)
            return {
                rules,
                text: 'N/A'
            };

        if(rules.cashout === rules.minimal_cashout)
            return {
                rules,
                text: `$${rules.cashout}`
            };

        return {
            rules,
            text: `$${rules.cashout} when you reach $${rules.minimal_cashout}`
        };
    }

    return {
        rules: null,
        text: 'N/F'
    }
}

