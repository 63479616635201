import {createSelector} from 'reselect';
import props from '../../../props';

export default createSelector(
    props.wallet.bonuses.error,
    props.wallet.bonuses.message,
    props.wallet.bonuses.button,
    (error, message, button) => {
        return {
            error,
            message,
            button
        }
    }
)