import {useRef, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/pages/vip/levels';
import {useLocation} from 'react-router-dom';
import {getIcon} from './levels/index';
import {Swiper, SwiperSlide} from 'swiper/react';
import Numeric from '../../components/numeric';
import tippy from 'tippy.js';

export default () => {
    const {levels, current, next} = useSelector(Selector),
          [active, setActive] = useState(current.icon),
          location = useLocation(),
          swiperRef = useRef();

    const selected_level = levels.filter(lvl => lvl.icon === active)[0] || false;

    const getSlidesCount = () => {
        let slides_count = 'auto';
    
        if(document.body.offsetWidth >= 1024) {
            slides_count = 5; // * - 1024
        } else if(document.body.offsetWidth >= 882) {
            slides_count = 4; // 1023 - 882
        } else if(document.body.offsetWidth >= 732) {
            slides_count = 3; // 881 - 732
        } else if(document.body.offsetWidth >= 580) {
            slides_count = 2; // 731 - 500
        } else {
            slides_count = 'auto';
        }
    
        return slides_count;
    }

    const resizeHandler = e => {
        if(swiperRef.current)
            swiperRef.current.params.slidesPerView = getSlidesCount();
    }

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [location]);

    useEffect(() => {
        tippy('#wheel-sectors', {
            placement: "bottom-end",
            animation: 'fade',
            theme: 'translucent',
            arrow: true,
            content: selected_level.preset.wheels_sectors,
            allowHTML: true
        });
    }, [selected_level]);

    return (
        <div className="vip-levels">
            <div className="top">
                <div className="title">VIP Levels</div>
            </div>
            <div className="levels">
                {selected_level && (
                    <div className="details">
                        <div className="level_info">
                            <div className="level">
                                {getIcon(selected_level.icon)}
                                <div className="info">
                                    <div className="name">{selected_level.name}</div>
                                    {
                                        selected_level.default
                                        ? <div className="xp">Default level</div>
                                        : <div className="xp">From <b><Numeric value={selected_level.xp.from} fixedDecimalScale={0} prefix={''} /> XP</b></div>
                                    }
                                </div>
                            </div> 
                            {!selected_level.default && (
                                <div className="bonus">
                                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.14536 5.02085H6.99953C6.93084 5.01805 6.86375 4.99932 6.80355 4.96614C6.74335 4.93296 6.69169 4.88624 6.65265 4.82966C6.61361 4.77308 6.58827 4.7082 6.57861 4.64014C6.56896 4.57209 6.57526 4.50272 6.59703 4.43751C6.80119 3.92418 6.98786 3.39918 7.13953 2.88585C7.26786 2.48918 7.76953 1.24668 9.02953 1.24668C9.52059 1.23094 9.99784 1.41067 10.3565 1.74641C10.7153 2.08215 10.9261 2.54648 10.9429 3.03751C10.9429 4.16335 9.66536 4.62418 9.19286 4.78751C8.52485 4.96183 7.83546 5.04039 7.14536 5.02085ZM9.02369 2.10418C8.35286 2.10418 8.04953 2.88001 7.97369 3.11918C7.86869 3.45751 7.75786 3.79585 7.63536 4.13418C8.05356 4.13152 8.46951 4.07266 8.87203 3.95918C9.20453 3.84251 10.0387 3.52751 10.0387 3.02001C10.0207 2.76575 9.90497 2.52833 9.71573 2.35758C9.52648 2.18682 9.27846 2.096 9.02369 2.10418Z" fill="#F99737"/>
                                        <path d="M6.83677 5.02082C6.16167 5.03271 5.48822 4.95025 4.83594 4.77582C3.38344 4.30332 3.08594 3.56249 3.08594 3.02582C3.08838 2.79915 3.13666 2.57531 3.22789 2.36779C3.31912 2.16027 3.4514 1.97336 3.61677 1.81832C3.9845 1.44838 4.4835 1.23872 5.0051 1.23499C5.9851 1.23499 6.48094 2.15082 6.84844 2.80415C7.08726 3.33973 7.28234 3.89376 7.43177 4.46082C7.44984 4.52517 7.45293 4.5928 7.44079 4.65853C7.42866 4.72426 7.40163 4.78633 7.36177 4.83999C7.32313 4.89417 7.27249 4.93868 7.2138 4.97005C7.1551 5.00142 7.08996 5.0188 7.02344 5.02082H6.83677ZM4.94094 2.10415C4.66392 2.11608 4.40242 2.23533 4.21177 2.43665C4.12862 2.50998 4.06139 2.59957 4.01423 2.69991C3.96708 2.80024 3.941 2.90918 3.9376 3.01999C3.9376 3.37582 4.36927 3.71999 5.10427 3.95915C5.53315 4.07001 5.97383 4.12877 6.41677 4.13415C6.32242 3.8235 6.20744 3.51949 6.0726 3.22415C5.73427 2.57665 5.4076 2.11582 4.95844 2.10415H4.94094Z" fill="#F99737"/>
                                        <path d="M12.3952 6.47915V5.74998C12.3952 5.32453 12.2262 4.9165 11.9253 4.61566C11.6245 4.31482 11.2165 4.14581 10.791 4.14581H3.20768C2.78223 4.14581 2.3742 4.31482 2.07337 4.61566C1.77253 4.9165 1.60352 5.32453 1.60352 5.74998V6.47915H12.3952ZM7.43685 7.35415V13.7708H10.791C11.2165 13.7708 11.6245 13.6018 11.9253 13.301C12.2262 13.0001 12.3952 12.5921 12.3952 12.1666V7.35415H7.43685ZM6.56185 7.35415H1.60352V12.1666C1.60352 12.5921 1.77253 13.0001 2.07337 13.301C2.3742 13.6018 2.78223 13.7708 3.20768 13.7708H6.56185V7.35415Z" fill="#F99737"/>
                                    </svg>

                                    ${selected_level.bonus}
                                </div>
                            )}
                        </div>
                        <div className="benefits">
                            BENEFITS <hr />
                        </div>
                        <div className="list">
                            <div className="item">
                                <div className="name">Weekly Cashback</div>
                                <div className="value green1">{selected_level.preset.cashback.weekly}%</div>
                            </div>

                            <div className="item">
                                <div className="name">Monthly Cashback</div>
                                <div className="value blue1">{selected_level.preset.cashback.monthly}%</div>
                            </div>

                            <div className="item">
                                <div className="name">Referral Royalty</div>
                                <div className="value green2">{selected_level.preset.referrals.royalty}%</div>
                            </div>

                            <div className="item wheel-sectors">
                                <div className="name">Wheel Cash</div>
                                <div className="value" id="wheel-sectors">
                                    More info

                                    <div className="info-circle">
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.76971 11.2441C9.59936 11.3041 9.05993 11.447 8.81387 11.3198C8.77992 11.3019 8.75127 11.2769 8.73038 11.2468C8.7095 11.2166 8.69701 11.1824 8.69399 11.1469C8.6167 10.7011 8.98422 9.67094 9.18296 9.11798C9.2492 8.93366 9.29652 8.79936 9.31545 8.71791C9.45898 8.11638 9.38801 7.65629 9.10409 7.35053C8.76245 6.9826 8.15645 6.91102 7.65928 6.9776C7.15374 7.03864 6.65972 7.16169 6.19081 7.34338C6.16903 7.35293 6.14999 7.36694 6.13521 7.3843C6.12043 7.40166 6.1103 7.42189 6.10563 7.4434L6.00311 7.88776C5.99756 7.91276 5.99946 7.93863 6.00861 7.96274C6.01776 7.98685 6.03382 8.00831 6.05516 8.02493C6.07676 8.04119 6.10264 8.05211 6.13028 8.05661C6.15793 8.06112 6.18638 8.05907 6.21289 8.05065C6.40059 7.98778 6.98735 7.84633 7.19713 8.02065C7.31543 8.11923 7.2823 8.32784 7.23498 8.48358C7.18609 8.64647 7.12773 8.82222 7.06779 9.00654C6.67977 10.2025 6.23813 11.5584 6.93845 11.9814C7.26979 12.2105 7.67309 12.3381 8.08988 12.3457C8.49998 12.3457 9.0063 12.2285 9.73501 11.9613C9.75697 11.9536 9.77673 11.9415 9.79284 11.9259C9.80895 11.9103 9.82099 11.8917 9.82807 11.8713L9.97792 11.4184C9.98641 11.3926 9.98677 11.3651 9.97897 11.3391C9.97117 11.3131 9.95551 11.2896 9.93375 11.2712C9.912 11.2532 9.88506 11.2411 9.85605 11.2363C9.82703 11.2315 9.79711 11.2342 9.76971 11.2441ZM8.85803 4.3457C8.63239 4.34584 8.41187 4.40658 8.22432 4.52022C8.03678 4.63386 7.89063 4.79532 7.80436 4.98419C7.71808 5.17305 7.69555 5.38085 7.7396 5.58131C7.78366 5.78177 7.89233 5.9659 8.05188 6.11043C8.21143 6.25497 8.4147 6.35341 8.63599 6.39332C8.85729 6.43323 9.08667 6.41282 9.29517 6.33466C9.50366 6.25651 9.68189 6.12412 9.80735 5.95423C9.9328 5.78434 9.99984 5.58457 10 5.38017C9.99992 5.10584 9.87958 4.84276 9.66543 4.64878C9.45129 4.45479 9.16087 4.34578 8.85803 4.3457Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="name">Personal Bonuses</div>
                                {
                                    selected_level.preset.personal_bonuses
                                    ? <div className="value green3">Yes</div>
                                    : <div className="value red">No</div>
                                }
                            </div>

                            <div className="item">
                                <div className="name">Personal Manager</div>
                                {
                                    selected_level.preset.personal_manager
                                    ? <div className="value green3">Yes</div>
                                    : <div className="value red">No</div>
                                }
                            </div>
                        </div>
                    </div>
                )}
                <div className="list">
                    <Swiper
                        speed={500}
                        slidesPerView={getSlidesCount()}
                        spaceBetween={20}
                        initialSlide={levels.findIndex(lvl => lvl.icon === current.icon)}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        >
                        {levels.map((lvl, key) => {
                            return (
                                <SwiperSlide className={`${active === lvl.icon ? 'active-slide' : ''}`}>
                                    <div className={`level ${lvl.icon.replace(/[^a-z]/gi, '').toLowerCase()} ${active === lvl.icon ? 'active' : ''}`} key={key} onClick={e => {
                                        if(swiperRef.current) {
                                            swiperRef.current.slideTo(levels.findIndex(l => l.icon === lvl.icon));
                                            setActive(lvl.icon);
                                        }
                                    }}>
                                        <div className="icon">
                                            {getIcon(lvl.icon)}
                                        </div>
                                        <span>{lvl.name}</span>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}