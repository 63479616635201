import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.games.buttons.requestDownloadCode,
    props.games.buttons.removeLogin,
    props.games.buttons.updateBalance,
    props.games.game,
    props.user.user,
    (downloadCode, removeLogin, updateBalance, game, user) => {
        return {
            buttons: {
                download: downloadCode,
                remove: removeLogin,
                update: updateBalance
            },
            game,
            user
        }
    }
)