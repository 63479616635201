import {useSelector} from 'react-redux';
import Selector from '../../../../selectors/modals/wallet/methods';

import CashappDeposit from './cashapp-deposit/index';
import CashappCashout from './cashapp-cashout/index';
import CryptoDeposit from './crypto-deposit/index';
import CardsDeposit from './cards-deposit/index';

export default () => {
    const {method} = useSelector(Selector);

    if(method === 'cashapp-deposit') return <CashappDeposit />
    if(method === 'cashapp-cashout') return <CashappCashout />
    if(method === 'crypto-deposit') return <CryptoDeposit />
    if(method === 'cards-deposit') return <CardsDeposit />
    return <></>;
}