export default () => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1275_6821)">
            <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#26A17B"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7529 19.6895V19.6874C19.6341 19.6959 19.0218 19.7327 17.6556 19.7327C16.5648 19.7327 15.797 19.7003 15.527 19.6874V19.6905C11.3279 19.5059 8.19369 18.7748 8.19369 17.9C8.19369 17.0262 11.3279 16.2951 15.527 16.1072V18.9627C15.8012 18.9821 16.5875 19.0286 17.6739 19.0286C18.9776 19.0286 19.631 18.9746 19.7529 18.9638V16.1093C23.9433 16.2962 27.0699 17.0273 27.0699 17.9C27.0699 18.7748 23.9433 19.5038 19.7529 19.6895ZM19.7529 15.8123V13.257H25.6001V9.36035H9.67977V13.257H15.527V15.8112C10.7749 16.0293 7.20117 16.9712 7.20117 18.0987C7.20117 19.2261 10.7749 20.1669 15.527 20.3861V28.5747H19.7529V20.384C24.4974 20.1657 28.0625 19.2251 28.0625 18.0987C28.0625 16.9722 24.4974 16.0316 19.7529 15.8123Z" fill="white"/>
            <path d="M27.75 36C32.3063 36 36 32.3063 36 27.75C36 23.1937 32.3063 19.5 27.75 19.5C23.1937 19.5 19.5 23.1937 19.5 27.75C19.5 32.3063 23.1937 36 27.75 36Z" fill="#0098EA"/>
            <path d="M30.5355 23.625H24.964C23.9397 23.625 23.2903 24.73 23.8057 25.6233L27.2442 31.5831C27.4686 31.9724 28.0309 31.9724 28.2552 31.5831L31.6944 25.6233C32.209 24.7314 31.5592 23.625 30.5355 23.625ZM27.2413 29.7958L26.4925 28.3465L24.6856 25.1149C24.5665 24.9081 24.7137 24.643 24.9633 24.643H27.2407L27.2413 29.7958ZM30.8124 25.1142L29.0062 28.3473L28.2573 29.7958V24.6423H30.5347C30.7843 24.6423 30.9315 24.9073 30.8124 25.1142Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_1275_6821">
                <rect width="36" height="36" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)