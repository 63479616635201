export default ({active, onClick}) => (
    <div className={`method ${!active ? 'not-available' : ''}`} onClick={e => {
        if(!active)
            return;

        onClick({
            mode: "methods",
            method: "cashapp-deposit"
        });
    }}>
        <div className="info">
            <div className="icon cashapp">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1277_6865)">
                        <path d="M30.6176 -23.625C38.9426 -23.625 43.1051 -23.625 47.6578 -22.1941C52.6008 -20.373 56.373 -16.6008 58.1941 -11.6578C59.625 -7.10508 59.625 -2.94258 59.625 5.38242V30.6176C59.625 38.9426 59.625 43.1051 58.1941 47.6578C56.373 52.6008 52.6008 56.373 47.6578 58.1941C43.1051 59.625 38.9426 59.625 30.6176 59.625H5.38242C-2.94258 59.625 -7.10508 59.625 -11.6578 58.1941C-16.6008 56.373 -20.373 52.6008 -22.1941 47.6578C-23.625 43.1051 -23.625 38.9426 -23.625 30.6176V5.38242C-23.625 -2.94258 -23.625 -7.10508 -22.1941 -11.6578C-20.373 -16.6008 -16.6008 -20.373 -11.6578 -22.1941C-7.10508 -23.625 -2.94258 -23.625 5.38242 -23.625H30.6176Z" fill="#00D632"/>
                        <path d="M22.2335 14.3325C22.444 14.5433 22.7808 14.5433 22.9913 14.3325L24.0438 13.2365C24.2544 13.0258 24.2544 12.6464 24.0017 12.4356C23.1597 11.719 22.2335 11.171 21.181 10.8337L21.5178 9.23185C21.602 8.89461 21.3073 8.55737 20.9705 8.55737H18.9496C18.697 8.55737 18.4444 8.72599 18.4023 9.02107L18.1076 10.4543C15.4132 10.5808 13.0976 11.9719 13.0976 14.7962C13.0976 17.2412 14.9922 18.2951 17.013 19.0117C18.9075 19.7283 19.918 20.0234 19.918 21.0351C19.918 22.089 18.9075 22.7213 17.434 22.7213C16.0868 22.7213 14.6553 22.2576 13.5607 21.1616C13.3502 20.9508 13.0134 20.9508 12.8029 21.1616L11.6662 22.2998C11.4557 22.5105 11.4557 22.8899 11.6662 23.1007C12.5503 23.9859 13.687 24.6183 14.9501 24.9555L14.6553 26.4731C14.5711 26.8103 14.8237 27.1475 15.2027 27.1475H17.2656C17.5182 27.1475 17.7708 26.9789 17.8129 26.6838L18.1076 25.2506C21.3494 25.0398 23.3702 23.2272 23.3702 20.6136C23.3702 18.2108 21.3915 17.1569 18.9917 16.356C17.6024 15.8501 16.4236 15.5129 16.4236 14.459C16.4236 13.4473 17.5182 13.0258 18.6549 13.0258C19.9601 12.9414 21.3494 13.5316 22.2335 14.3325Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1277_6865">
                            <rect width="36" height="36" rx="18" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div className="name">CashApp</div>
        </div>

        {
            !active 
            ? <div className="status">Soon</div>
            : <></>
        }
    </div>
)