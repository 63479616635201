import Progress from './progress';
import Cashback from './cashback';

export default () => {

    return (
        <div className="vip-top">
            <Progress />
            <div className="cashbacks">
                <Cashback.Weekly />
                <Cashback.Monthly />
            </div>
        </div>   
    )
}