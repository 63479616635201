import types from './types';
import API from '../../lib/api';
import {toast} from 'react-toastify';

export const loadWheel = () => async dispatch => {
    const response = await API.call({
        path: '/wheel/load'
    });

    if(response.success)
        dispatch({
            type: types.loaded,
            payload: response.data
        });
}

export const unloadWheel = () => {
    return {
        type: types.unload
    }
}

export const updateStatus = () => async dispatch => {
    const response = await API.call({
        path: '/wheel/status'
    });

    if(response.success)
        dispatch({
            type: types.status,
            payload: response.status
        });
}

export const makeSpin = () => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'spin'
    });

    const response = await API.call({
        path: '/wheel/spin'
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);

        dispatch({
            type: types.toggleButton,
            payload: 'spin'
        });
    } else {
        toast.dismiss();
        toast.success(`Spin...`);

        dispatch({
            type: types.spin,
            payload: response.wheel.spin
        });

        setTimeout(() => {
            dispatch({
                type: types.toggleButton,
                payload: 'spin'
            });

            dispatch({
                type: types.status,
                payload: response.wheel.status
            });

            dispatch({
                type: types.history.my,
                payload: response.tx
            });

            // window.alert(JSON.stringify(response.bonus));
        }, 5600);
    }
}