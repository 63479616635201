export default {
    loaded: ({wheel}) => wheel.loaded,
    wheel: ({wheel}) => wheel.wheel,
    status: ({wheel}) => wheel.status,
    history: {
        all: ({wheel}) => wheel.history.all,
        my: ({wheel}) => wheel.history.my
    },
    buttons: {
        spin: ({wheel}) => wheel.buttons.spin
    }
}