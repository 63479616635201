import BNB from './BNB';
import BTC from './BTC';
import ETH from './ETH';
import LTC from './LTC';
import TON from './TON';
import TRX from './TRX';
import USDC_ERC20 from './USDC_ERC20';
import USDT_BEP20 from './USDT_BEP20';
import USDT_ERC20 from './USDT_ERC20';
import USDT_TON from './USDT_TON';
import USDT_TRC20 from './USDT_TRC20';

export default {
    BNB,
    BTC,
    ETH,
    LTC,
    TON,
    TRX,
    USDC_ERC20,
    USDT_BEP20,
    USDT_ERC20,
    USDT_TON,
    USDT_TRC20
}