import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/freecash/notifications';
import {subscribePushNotifications} from '../../../reducers/app/actions';
import WhiteButton from "../../components/white-button";

export default () => {
    const {supported, registered, button} = useSelector(Selector),
          dispatch = useDispatch();

    if(!supported)
        return <></>;

    return (
        <div className="block notifications">
            <div className="layer">
                <div className="notify"></div>
            </div>
            <div className="layer">
                <div className="content">
                    <div className="title">
                        GET PROMOS FIRST WITH NOTIFICATIONS!
                    </div>
                    <WhiteButton active={button} onClick={e => dispatch(subscribePushNotifications())}>Enable</WhiteButton>
                </div>
            </div>
        </div>
    )
}