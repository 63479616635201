import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.games.testyourluck,
    props.games.buttons.testyourluck,
    props.user.user,
    (testyourluck, button, user) => {
        return {
            testyourluck,
            button,
            user
        }
    }
)