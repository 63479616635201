export default ({active, onClick}) => (
    <div className={`method ${!active ? 'not-available' : ''}`} onClick={e => {
        if(!active)
            return;

        onClick({
            name: 'BNB',
            network: 'BEP-20',
            token_name: "BNB",
            token: "BNB"
        });
    }}>
        <div className="info">
            <div className="icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1275_6808)">
                        <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#0A0E11"/>
                        <path d="M21.3954 10.2808L24.8394 13.7248L23.5884 14.9758L22.3344 16.2299L20.1384 14.0338L17.9394 11.8348L15.7854 13.9888C14.6034 15.1709 13.6224 16.1399 13.6074 16.1399C13.5684 16.1399 11.1294 13.7038 11.1294 13.6648C11.1294 13.6348 17.9094 6.83984 17.9394 6.83984C17.9484 6.83984 19.5024 8.38784 21.3954 10.2808ZM19.2984 16.6139L20.6394 17.9549L19.2894 19.3049L17.9394 20.6549L16.5894 19.3049L15.2394 17.9549L16.5804 16.6139C17.3214 15.8729 17.9304 15.2699 17.9394 15.2699C17.9484 15.2699 18.5574 15.8729 19.2984 16.6139ZM11.8674 17.9099C11.8464 17.9339 11.8314 17.9699 11.8374 17.9849C11.8434 18.0029 11.2884 18.5759 10.6044 19.2599L9.35936 20.5049L8.09936 19.2449C7.40636 18.5519 6.83936 17.9729 6.83936 17.9549C6.83936 17.9399 7.41536 17.3489 8.12036 16.6439L9.40436 15.3599L10.6554 16.6109C11.7414 17.6969 11.9034 17.8679 11.8674 17.9099ZM27.8124 19.2119L26.5584 20.4659L25.2894 19.1999L24.0204 17.9369L25.2774 16.6769L26.5344 15.4199L27.8004 16.6859L29.0694 17.9549L27.8124 19.2119ZM23.6094 20.9249L24.8544 22.1699L21.4044 25.6199L17.9544 29.0699L14.5074 25.6379L11.0604 22.2059L12.3174 20.9579L13.5714 19.7069L15.7554 21.8909L17.9394 24.0749L20.1384 21.8759C21.3444 20.6699 22.3404 19.6799 22.3494 19.6799C22.3584 19.6799 22.9254 20.2409 23.6094 20.9249Z" fill="#F1B90C"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1275_6808">
                            <rect width="36" height="36" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div className="name">BNB <span>BEP-20</span></div>
        </div>

        {
            !active 
            ? <div className="status">Soon</div>
            : <div className="status bonus">Bonus <b>+7%</b></div>
        }
    </div>
)