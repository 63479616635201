import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.games.games,
    props.user.logins,
    props.modals.data.game,
    (games, logins, data) => {
        const game = games.filter(g => g.slug === data.game)[0] || false,
              login = game ? logins.filter(l => l.game === game.slug && l.status === 1)[0] || false : false;

        return {
            game,
            login
        }
    }
)