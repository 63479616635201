import types from './types';

const InitialState = {
    initialized: false,
    connection: null,
    isMobile: false,
    sidebar: false,
    referral_bonus: null,
    affiliate_bonus: null,
    notifications: {
        initialized: false,
        supported: false,
        registered: false,
        button: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.notifications.update:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    ...payload
                }
            }
        case types.notifications.button:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    button: !state.notifications.button
                }
            }
        case types.notifications.init:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    initialized: true,
                    supported: payload.supported,
                    registered: payload.registered
                }
            }
        case types.sidebar:
            return {
                ...state,
                sidebar: !state.sidebar
            }
        case types.mobile:
            return {
                ...state,
                isMobile: payload
            }
        case types.connection:
            return {
                ...state,
                connection: payload
            }
        case types.init:
            console.log(payload);
            return {
                ...state,
                initialized: true,
                referral_bonus: payload.referral_bonus,
                affiliate_bonus: payload.affiliate_bonus
            }
        default:
            return state;
    }
}