import ModalsLib from '../../../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import {useEffect} from 'react';
import tippy from 'tippy.js';

export default ({
    amounts,
    bonus,
    paymentId,
    email,
    status,
    vip_xp
}) => {
    useEffect(() => {
        tippy('.vip-xp', {
            placement: "top",
            animation: 'fade',
            theme: 'translucent',
            arrow: true,
            content: `You got <b>${vip_xp} XP</b> for your deposit`,
            allowHTML: true
        });

        if(bonus)
            tippy(`#modal-bonus-${paymentId}`, {
                placement: "bottom-end",
                animation: 'fade',
                theme: 'translucent',
                arrow: true,
                content: bonus.text,
                allowHTML: true
            });
    }, []);

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Deposit success</div>
                    <div className="descriptions">We have received payment on your invoice #{paymentId}!</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="payment-info-amount">
                    <div className="amount">
                        ${amounts.total.toFixed(2)}
                    </div>
                    <div className="details">
                        <div className="label">Amount</div>
                        {vip_xp > 0 && (
                            <div className="vip-xp">
                                +{vip_xp}
                                <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5131 4.17661C16.2995 4.03183 16.0474 3.95445 15.7894 3.95445C15.5313 3.95445 15.2792 4.03183 15.0656 4.17661L12.8156 5.57161C12.7932 5.58176 12.7689 5.58701 12.7444 5.58701C12.7198 5.58701 12.6955 5.58176 12.6731 5.57161C12.6231 5.55807 12.5801 5.52586 12.5531 5.48161L10.1531 1.16161C10.0364 0.967464 9.87152 0.806818 9.67438 0.695289C9.47725 0.583761 9.2546 0.525146 9.02811 0.525146C8.80161 0.525146 8.57896 0.583761 8.38183 0.695289C8.18469 0.806818 8.01977 0.967464 7.90311 1.16161L5.41311 5.47411C5.3861 5.51836 5.34315 5.55057 5.29311 5.56411C5.27072 5.57426 5.24643 5.57951 5.22186 5.57951C5.19728 5.57951 5.17299 5.57426 5.15061 5.56411L2.93811 4.14661C2.72391 4.01104 2.47452 3.94159 2.22109 3.94693C1.96765 3.95226 1.72141 4.03215 1.51311 4.17661C1.29933 4.32268 1.13383 4.52904 1.03767 4.76943C0.941516 5.00983 0.91904 5.2734 0.973106 5.52661L2.23311 11.4441C2.295 11.7386 2.45618 12.0028 2.68966 12.1926C2.92313 12.3824 3.21473 12.4862 3.51561 12.4866H14.5331C14.834 12.4862 15.1256 12.3824 15.3591 12.1926C15.5925 12.0028 15.7537 11.7386 15.8156 11.4441L17.0756 5.52661C17.1274 5.27114 17.1015 5.00602 17.0012 4.7654C16.901 4.52479 16.731 4.31972 16.5131 4.17661Z" fill="white"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>

                <div className="payment-provider">
                    <div className="left">
                        <div className="icon cards">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.83325 11.5591H17.4166" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M17.4166 9.42332V15.9775C17.3891 18.59 16.6741 19.25 13.9516 19.25H5.29828C2.52995 19.25 1.83325 18.5625 1.83325 15.8308V9.42332C1.83325 6.94832 2.41075 6.15082 4.58325 6.02248C4.80325 6.01332 5.04161 6.00415 5.29828 6.00415H13.9516C16.7199 6.00415 17.4166 6.69165 17.4166 9.42332Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M20.1666 6.16917V12.5767C20.1666 15.0517 19.5891 15.8492 17.4166 15.9775V9.42333C17.4166 6.69167 16.7199 6.00417 13.9516 6.00417H5.29828C5.04161 6.00417 4.80325 6.01333 4.58325 6.0225C4.61075 3.41 5.32578 2.75 8.04828 2.75H16.7016C19.4699 2.75 20.1666 3.4375 20.1666 6.16917Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M4.8125 16.3258H6.38914" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M8.35083 16.3258H11.5042" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                        <div className="name">Debit Card</div>
                    </div>
                    <CopyToClipboard text={`Debit card transaction #${paymentId} for $${amounts.amount} from ${email}`} onCopy={e => {
                        toast.dismiss();
                        toast.info(`The transaction information has been copied to the clipboard.`);
                    }}>
                        <div className="right">
                            #{paymentId}

                            <div className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                    <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                </svg>
                            </div>
                        </div>
                    </CopyToClipboard>
                </div>

                <div className="payment-details">
                    <div className="payment-detail">
                        <div className="row">
                            <div className="label">Payment ID</div>
                            <div className="value">#{paymentId}</div>
                        </div>
                        <div className="row">
                            <div className="label">Receipt email</div>
                            <div className="value">{email}</div>
                        </div>
                    </div>

                    <div className="payment-detail">
                        <div className="row">
                            <div className="label">Amount</div>
                            <div className="value">${amounts.amount}</div>
                        </div>
                        {amounts.comission > 0 && (
                            <div className="row">
                                <div className="label">Processing fee</div>
                                <div className="value">
                                    ${amounts.comission}
                                </div>
                            </div>
                        )}
                    </div>

                    {bonus && (
                        <div className="payment-detail">
                            <div className="row">
                                <div className="label">Bonus</div>
                                <div className="value">
                                    <div className="bonus_amount" id={`modal-bonus-${paymentId}`}>
                                        ${bonus.amount}

                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_1328_6526)">
                                                <rect y="0.345703" width="16" height="16" rx="8" fill="#30344F"/>
                                                <path d="M9.76971 11.2441C9.59936 11.3041 9.05993 11.447 8.81387 11.3198C8.77992 11.3019 8.75127 11.2769 8.73038 11.2468C8.7095 11.2166 8.69701 11.1824 8.69399 11.1469C8.6167 10.7011 8.98422 9.67094 9.18296 9.11798C9.2492 8.93366 9.29652 8.79936 9.31545 8.71791C9.45898 8.11638 9.38801 7.65629 9.10409 7.35053C8.76245 6.9826 8.15645 6.91102 7.65928 6.9776C7.15374 7.03864 6.65972 7.16169 6.19081 7.34338C6.16903 7.35293 6.14999 7.36694 6.13521 7.3843C6.12043 7.40166 6.1103 7.42189 6.10563 7.4434L6.00311 7.88776C5.99756 7.91276 5.99946 7.93863 6.00861 7.96274C6.01776 7.98685 6.03382 8.00831 6.05516 8.02493C6.07676 8.04119 6.10264 8.05211 6.13028 8.05661C6.15793 8.06112 6.18638 8.05907 6.21289 8.05065C6.40059 7.98778 6.98735 7.84633 7.19713 8.02065C7.31543 8.11923 7.2823 8.32784 7.23498 8.48358C7.18609 8.64647 7.12773 8.82222 7.06779 9.00654C6.67977 10.2025 6.23813 11.5584 6.93845 11.9814C7.26979 12.2105 7.67309 12.3381 8.08988 12.3457C8.49998 12.3457 9.0063 12.2285 9.73501 11.9613C9.75697 11.9536 9.77673 11.9415 9.79284 11.9259C9.80895 11.9103 9.82099 11.8917 9.82807 11.8713L9.97792 11.4184C9.98641 11.3926 9.98677 11.3651 9.97897 11.3391C9.97117 11.3131 9.95551 11.2896 9.93375 11.2712C9.912 11.2532 9.88506 11.2411 9.85605 11.2363C9.82703 11.2315 9.79711 11.2342 9.76971 11.2441ZM8.85803 4.3457C8.63239 4.34584 8.41187 4.40658 8.22432 4.52022C8.03678 4.63386 7.89063 4.79532 7.80436 4.98419C7.71808 5.17305 7.69555 5.38085 7.7396 5.58131C7.78366 5.78177 7.89233 5.9659 8.05188 6.11043C8.21143 6.25497 8.4147 6.35341 8.63599 6.39332C8.85729 6.43323 9.08667 6.41282 9.29517 6.33466C9.50366 6.25651 9.68189 6.12412 9.80735 5.95423C9.9328 5.78434 9.99984 5.58457 10 5.38017C9.99992 5.10584 9.87958 4.84276 9.66543 4.64878C9.45129 4.45479 9.16087 4.34578 8.85803 4.3457Z" fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1328_6526">
                                                    <rect y="0.345703" width="16" height="16" rx="8" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="payment-detail">
                        <div className="row">
                            <div className="label">Total amount</div>
                            <div className="value">${amounts.total}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}