import ModalsLib from '../../../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import {useEffect} from 'react';
import tippy from 'tippy.js';

export default ({
    amounts,
    bonus,
    cashtag,
    paymentId,
    recipient,
    status,
    vip_xp
}) => {
    useEffect(() => {
        tippy('.vip-xp', {
            placement: "top",
            animation: 'fade',
            theme: 'translucent',
            arrow: true,
            content: `You got <b>${vip_xp} XP</b> for your deposit`,
            allowHTML: true
        });

        if(bonus)
            tippy(`#modal-bonus-${paymentId}`, {
                placement: "bottom-end",
                animation: 'fade',
                theme: 'translucent',
                arrow: true,
                content: bonus.text,
                allowHTML: true
            });
    }, []);

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Deposit success</div>
                    <div className="descriptions">Your deposit request #{paymentId} has been confirmed!</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="payment-info-amount">
                    <div className="amount">
                        ${amounts.total.toFixed(2)}
                    </div>
                    <div className="details">
                        <div className="label">Amount</div>
                        {vip_xp > 0 && (
                            <div className="vip-xp">
                                +{vip_xp}
                                <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5131 4.17661C16.2995 4.03183 16.0474 3.95445 15.7894 3.95445C15.5313 3.95445 15.2792 4.03183 15.0656 4.17661L12.8156 5.57161C12.7932 5.58176 12.7689 5.58701 12.7444 5.58701C12.7198 5.58701 12.6955 5.58176 12.6731 5.57161C12.6231 5.55807 12.5801 5.52586 12.5531 5.48161L10.1531 1.16161C10.0364 0.967464 9.87152 0.806818 9.67438 0.695289C9.47725 0.583761 9.2546 0.525146 9.02811 0.525146C8.80161 0.525146 8.57896 0.583761 8.38183 0.695289C8.18469 0.806818 8.01977 0.967464 7.90311 1.16161L5.41311 5.47411C5.3861 5.51836 5.34315 5.55057 5.29311 5.56411C5.27072 5.57426 5.24643 5.57951 5.22186 5.57951C5.19728 5.57951 5.17299 5.57426 5.15061 5.56411L2.93811 4.14661C2.72391 4.01104 2.47452 3.94159 2.22109 3.94693C1.96765 3.95226 1.72141 4.03215 1.51311 4.17661C1.29933 4.32268 1.13383 4.52904 1.03767 4.76943C0.941516 5.00983 0.91904 5.2734 0.973106 5.52661L2.23311 11.4441C2.295 11.7386 2.45618 12.0028 2.68966 12.1926C2.92313 12.3824 3.21473 12.4862 3.51561 12.4866H14.5331C14.834 12.4862 15.1256 12.3824 15.3591 12.1926C15.5925 12.0028 15.7537 11.7386 15.8156 11.4441L17.0756 5.52661C17.1274 5.27114 17.1015 5.00602 17.0012 4.7654C16.901 4.52479 16.731 4.31972 16.5131 4.17661Z" fill="white"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>

                <div className="payment-provider">
                    <div className="left">
                        <div className="icon cashapp">
                            <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.908 5.81664C11.6905 5.81664 13.3897 6.44997 14.7391 7.59997C14.8724 7.71664 15.0556 7.79997 15.2555 7.79997C15.4554 7.79997 15.622 7.71664 15.772 7.5833L17.1546 6.19997C17.2213 6.13331 17.2713 6.03331 17.3046 5.94997C17.3379 5.86664 17.3546 5.74997 17.3546 5.66664C17.3546 5.56664 17.3212 5.46663 17.2879 5.3833C17.2546 5.29997 17.188 5.19997 17.1047 5.14997C16.0218 4.21664 14.7558 3.51664 13.3897 3.06664L13.8062 0.999969C13.8229 0.89997 13.8229 0.783304 13.8062 0.683304C13.7895 0.583304 13.7396 0.483302 13.6729 0.399968C13.6063 0.316635 13.523 0.233305 13.423 0.199972C13.3231 0.149972 13.2065 0.133301 13.1065 0.133301H10.4411C10.2745 0.133301 10.1079 0.183301 9.9913 0.299967C9.87468 0.399967 9.77473 0.549972 9.72475 0.699972L9.35826 2.5333C5.79324 2.71664 2.76131 4.46664 2.76131 8.09997C2.76131 11.2333 5.26015 12.5666 7.89227 13.5166C10.3911 14.45 11.7072 14.7833 11.7072 16.1C11.7072 17.4 10.3911 18.2333 8.44201 18.2333C6.55955 18.2666 4.72707 17.55 3.36103 16.25C3.22776 16.1166 3.04451 16.0333 2.8446 16.0333C2.6447 16.0333 2.47811 16.1 2.32818 16.25L0.82887 17.7166C0.762235 17.7833 0.712258 17.85 0.662281 17.95C0.628963 18.0333 0.612305 18.15 0.612305 18.2333C0.612305 18.3333 0.628963 18.4333 0.662281 18.5166C0.695599 18.6 0.745576 18.7 0.82887 18.75C2.04497 19.9 3.52762 20.7 5.14354 21.1L4.72707 23C4.71041 23.1 4.71041 23.2166 4.72707 23.3166C4.74373 23.4166 4.7937 23.5333 4.86034 23.6C4.92697 23.6833 5.02693 23.75 5.11022 23.8166C5.21018 23.8666 5.32679 23.8833 5.42674 23.8833H8.12549C8.29208 23.8833 8.45867 23.8333 8.57529 23.7333C8.6919 23.6333 8.80851 23.4833 8.84183 23.3166L9.22498 21.5C13.5063 21.2333 16.1051 18.9333 16.1051 15.5666C16.1051 12.4666 13.5063 11.1666 10.3411 10.1C8.52531 9.41664 6.95937 8.96663 6.95937 7.6333C6.99268 6.2833 8.44201 5.81664 9.908 5.81664Z" fill="white"></path>
                            </svg>
                        </div>
                        <div className="name">CashApp</div>
                    </div>
                    <CopyToClipboard text={`CashApp request #${paymentId} for $${amounts.amount} from ${cashtag} to ${recipient.name} (${recipient.cashtag})`} onCopy={e => {
                        toast.dismiss();
                        toast.info(`The transaction information has been copied to the clipboard.`);
                    }}>
                        <div className="right">
                            #{paymentId}

                            <div className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                    <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                </svg>
                            </div>
                        </div>
                    </CopyToClipboard>
                </div>

                <div className="payment-details">
                    <div className="payment-detail">
                        <div className="row">
                            <div className="label">Payment ID</div>
                            <div className="value">#{paymentId}</div>
                        </div>
                        <div className="row">
                            <div className="label">Sender</div>
                            <div className="value">{cashtag}</div>
                        </div>
                        <div className="row">
                            <div className="label">Recipient</div>
                            <div className="value">{recipient.name} ({recipient.cashtag})</div>
                        </div>
                    </div>

                    <div className="payment-detail">
                        <div className="row">
                            <div className="label">Amount</div>
                            <div className="value">${amounts.amount}</div>
                        </div>
                        {amounts.comission > 0 && (
                            <div className="row">
                                <div className="label">Processing fee</div>
                                <div className="value">
                                    ${amounts.comission}
                                </div>
                            </div>
                        )}
                    </div>

                    {bonus && (
                        <div className="payment-detail">
                            <div className="row">
                                <div className="label">Bonus</div>
                                <div className="value">
                                    <div className="bonus_amount" id={`modal-bonus-${paymentId}`}>
                                        ${bonus.amount}

                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_1328_6526)">
                                                <rect y="0.345703" width="16" height="16" rx="8" fill="#30344F"/>
                                                <path d="M9.76971 11.2441C9.59936 11.3041 9.05993 11.447 8.81387 11.3198C8.77992 11.3019 8.75127 11.2769 8.73038 11.2468C8.7095 11.2166 8.69701 11.1824 8.69399 11.1469C8.6167 10.7011 8.98422 9.67094 9.18296 9.11798C9.2492 8.93366 9.29652 8.79936 9.31545 8.71791C9.45898 8.11638 9.38801 7.65629 9.10409 7.35053C8.76245 6.9826 8.15645 6.91102 7.65928 6.9776C7.15374 7.03864 6.65972 7.16169 6.19081 7.34338C6.16903 7.35293 6.14999 7.36694 6.13521 7.3843C6.12043 7.40166 6.1103 7.42189 6.10563 7.4434L6.00311 7.88776C5.99756 7.91276 5.99946 7.93863 6.00861 7.96274C6.01776 7.98685 6.03382 8.00831 6.05516 8.02493C6.07676 8.04119 6.10264 8.05211 6.13028 8.05661C6.15793 8.06112 6.18638 8.05907 6.21289 8.05065C6.40059 7.98778 6.98735 7.84633 7.19713 8.02065C7.31543 8.11923 7.2823 8.32784 7.23498 8.48358C7.18609 8.64647 7.12773 8.82222 7.06779 9.00654C6.67977 10.2025 6.23813 11.5584 6.93845 11.9814C7.26979 12.2105 7.67309 12.3381 8.08988 12.3457C8.49998 12.3457 9.0063 12.2285 9.73501 11.9613C9.75697 11.9536 9.77673 11.9415 9.79284 11.9259C9.80895 11.9103 9.82099 11.8917 9.82807 11.8713L9.97792 11.4184C9.98641 11.3926 9.98677 11.3651 9.97897 11.3391C9.97117 11.3131 9.95551 11.2896 9.93375 11.2712C9.912 11.2532 9.88506 11.2411 9.85605 11.2363C9.82703 11.2315 9.79711 11.2342 9.76971 11.2441ZM8.85803 4.3457C8.63239 4.34584 8.41187 4.40658 8.22432 4.52022C8.03678 4.63386 7.89063 4.79532 7.80436 4.98419C7.71808 5.17305 7.69555 5.38085 7.7396 5.58131C7.78366 5.78177 7.89233 5.9659 8.05188 6.11043C8.21143 6.25497 8.4147 6.35341 8.63599 6.39332C8.85729 6.43323 9.08667 6.41282 9.29517 6.33466C9.50366 6.25651 9.68189 6.12412 9.80735 5.95423C9.9328 5.78434 9.99984 5.58457 10 5.38017C9.99992 5.10584 9.87958 4.84276 9.66543 4.64878C9.45129 4.45479 9.16087 4.34578 8.85803 4.3457Z" fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1328_6526">
                                                    <rect y="0.345703" width="16" height="16" rx="8" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="payment-detail">
                        <div className="row">
                            <div className="label">Total amount</div>
                            <div className="value">${amounts.total}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}