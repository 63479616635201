export default {
    initialized: ({app}) => app.initialized,
    connection: ({app}) => app.connection,
    isMobile: ({app}) => app.isMobile,
    sidebar: ({app}) => app.sidebar,
    referral_bonus: ({app}) => app.referral_bonus,
    affiliate_bonus: ({app}) => app.affiliate_bonus,
    notifications: {
        initialized: ({app}) => app.notifications.initialized,
        supported: ({app}) => app.notifications.supported,
        registered: ({app}) => app.notifications.registered,
        button: ({app}) => app.notifications.button
    }
}