import {navigate} from '../../../../../../utils/navigation';

export default () => {

    return (
        <div className="slide invite size-params">
            <div className="size-params-layer layer0"><div className="money"></div></div>
            <div className="layer-margin size-params-layer layer1"><div className="money"></div></div>
            <div className="layer-margin size-params-layer layer2"><div className="girl"></div></div>
            <div className="layer-margin size-params-layer layer3"><div className="pocket"></div></div>
            <div className="layer-margin size-params slide-details">
                <div className="title">
                    Earn money<br />Together!
                </div>
                <div className="descriptions-short">Invite Your Friends, Share The Fun and Earn Real Cash Rewards Every Time They Play!</div>
                <div className="details">
                    <div className="button" onClick={e => navigate('/invite')}>Start earning</div>
                </div>
            </div>
        </div>
    )
}