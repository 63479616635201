import {createSelector} from 'reselect';
import props from '../../../../props';

export default createSelector(
    props.wallet.methods.cards_deposit,
    (cards_deposit) => {
        return {
            props: cards_deposit[cards_deposit.stage]
        }
    }
)