import types from '../types';
import API from '../../../lib/api';
import ModalsLib from '../../../lib/modals';
import {toast} from 'react-toastify';

export const getHistory = () => async dispatch => {
    const response = await API.call({
        path: '/payments/history/load',
        method: 'POST'
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
        return;
    }

    dispatch({
        type: types.history.loaded,
        payload: response.payments
    });
}

export const toggleHistoryTransaction = paymentId => {
    return {
        type: types.history.toggle,
        payload: paymentId
    }
}

export const setTab = tab => {
    return {
        type: types.tab,
        payload: tab
    }
}

export const setPreset = preset => {
    return {
        type: types.preset,
        payload: preset
    }
}