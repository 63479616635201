import {useSelector} from 'react-redux';
import Selector from '../../../selectors/pages/invite/referrals';
import DateLib from '../../../lib/date';
import Numeric from '../../components/numeric';

export default () => {
    const {referrals} = useSelector(Selector);

    return (
        <div className="invite-history">
            <div className="top">
                <div className="title">My Referrals</div>
            </div>

            <div className="table">
                {referrals.length < 1 ? (
                    <div className={'plug'}>
                        <div className="icon">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.3337 15.0666V9.38664C27.3337 4.0133 26.0803 2.66663 21.0403 2.66663H10.9603C5.92033 2.66663 4.66699 4.0133 4.66699 9.38664V24.3999C4.66699 27.9466 6.61367 28.7866 8.97367 26.2533L8.98698 26.24C10.0803 25.08 11.747 25.1733 12.6936 26.4399L14.0403 28.24" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M24.2667 28.5333C26.6231 28.5333 28.5333 26.6231 28.5333 24.2667C28.5333 21.9103 26.6231 20 24.2667 20C21.9103 20 20 21.9103 20 24.2667C20 26.6231 21.9103 28.5333 24.2667 28.5333Z" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M29.3333 29.3333L28 28" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.667 9.33337H21.3337" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 14.6666H20" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>

                        <span>You don't have any<br/>invited friends!</span>
                    </div>
                ) : (
                    <>
                        <div className="head">
                            <div className="col user">User</div>
                            <div className="col earned">Earned</div>
                            <div className="col xp">XP</div>
                            <div className="col date">Date</div>
                        </div>
                        <div className="body">
                            {referrals.map((user, key) => {
                                return (
                                    <div className="tr" key={key}>
                                        <div className="col user">{user.name}</div>
                                        <div className="col earned">
                                             <Numeric value={user.earned_money} prefix='+ $' fixedDecimalScale={0} />
                                        </div>
                                        <div className="col xp">
                                            <svg width="35" height="25" viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M32.804 7.74485C32.3613 7.44484 31.8389 7.28448 31.3042 7.28448C30.7694 7.28448 30.247 7.44484 29.8044 7.74485L25.1417 10.6357C25.0953 10.6567 25.045 10.6676 24.9941 10.6676C24.9431 10.6676 24.8928 10.6567 24.8464 10.6357C24.7427 10.6077 24.6537 10.5409 24.5977 10.4492L19.6242 1.49691C19.3825 1.09459 19.0407 0.761683 18.6322 0.530564C18.2237 0.299445 17.7623 0.177979 17.2929 0.177979C16.8235 0.177979 16.3622 0.299445 15.9536 0.530564C15.5451 0.761683 15.2034 1.09459 14.9616 1.49691L9.80159 10.4336C9.74563 10.5254 9.65663 10.5921 9.55292 10.6202C9.50654 10.6412 9.4562 10.6521 9.40527 10.6521C9.35434 10.6521 9.304 10.6412 9.25762 10.6202L4.67268 7.68269C4.2288 7.40176 3.712 7.25784 3.18681 7.2689C2.66162 7.27996 2.15133 7.4455 1.71967 7.74485C1.27666 8.04755 0.933709 8.47519 0.73444 8.97336C0.535171 9.47154 0.488594 10.0177 0.600635 10.5424L3.21172 22.8052C3.33998 23.4154 3.674 23.963 4.15782 24.3563C4.64165 24.7495 5.24592 24.9647 5.86943 24.9656H28.7009C29.3244 24.9647 29.9286 24.7495 30.4125 24.3563C30.8963 23.963 31.2303 23.4154 31.3586 22.8052L33.9697 10.5424C34.077 10.013 34.0233 9.46363 33.8155 8.96502C33.6078 8.4664 33.2555 8.04142 32.804 7.74485Z" fill="#FAA13E"/>
                                            </svg>
                                           <Numeric value={user.earned_xp} fixedDecimalScale={0} prefix='' suffix=' XP' />
                                        </div>
                                        <div className="col date" dangerouslySetInnerHTML={{__html: DateLib.parse(user.timestamp, '<br/>')}}></div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}