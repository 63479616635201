import BlueButton from '../components/blue-button';
import ModalsLib from '../../lib/modals';
import FormInput from '../components/form-input';
import {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/modals/complete_profile';
import {completeProfile} from '../../reducers/modals/actions';

export default () => {
    const {user, data} = useSelector(Selector),
          dispatch = useDispatch();

    const [firstName, setFirstName] = useState(data.first_name || (user.first_name || "")),
          [lastName, setLastName] = useState(data.last_name || (user.last_name || "")),
          [phone, setPhone] = useState(data.phone || (user.phone.value || "")),
          [email, setEmail] = useState(data.email || (user.email.value || "")),
          [username, setUsername] = useState(data.username || (user.username || "")),
          [password, setPassword] = useState(data.password || (user.password_uncomplete || ""));

    console.clear();
    console.log(data);

    return (
        <div className="modal auth animate__animated animate__fadeInUp animate__faster">
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Complete your profile</div>
                    <div className="descriptions">Complete the details of your profile to continue..</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                {data.error && (
                    <div class="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <span dangerouslySetInnerHTML={{__html: data.error}}></span>
                    </div>
                )}
                <div className="form-group">
                    {(user.uncompleted_fields.includes('FIRST_NAME') || user.uncompleted_fields.includes('LAST_NAME')) && (
                        <div className="double-input">
                            <FormInput
                                name="first_name"
                                type={'text'}
                                label={'First name'}
                                placeholder={'James'}
                                validators={[
                                    value => value.replace(/ /gi, '').length >= 3
                                ]}
                                defaultValue={firstName}
                                onChange={({value, validator}) => {
                                    setFirstName(value.trim()); 
                                }} />

                            <FormInput
                                name="last_name"
                                type={'text'}
                                label={'Last name'}
                                placeholder={'Doe'}
                                validators={[
                                    value => value.replace(/ /gi, '').length >= 3
                                ]}
                                defaultValue={lastName}
                                onChange={({value, validator}) => {
                                    setLastName(value.trim()); 
                                }} />
                        </div>
                    )}

                    {user.uncompleted_fields.includes('PHONE') && (
                        <FormInput
                            name="phone"
                            type={'tel'}
                            label={'Phone number'}
                            placeholder={'(198) 765 43-21'}
                            defaultValue={phone}
                            isPhone={true}
                            validators={[
                                (value) => {
                                    value = value.replace(/[^0-9]/gi, '');
                                    return value.length === 10;
                                }
                            ]}
                            onChange={({value, validator}) => setPhone(value.replace(/[^0-9]/gi, ''))} />
                    )}

                    {user.uncompleted_fields.includes('EMAIL') && (
                        <FormInput
                            name="email"
                            type={'text'}
                            label={'Email'}
                            placeholder={'Email'}
                            defaultValue={email}
                            validators={[
                                (value) => {
                                    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    return regex.test(value);
                                }
                            ]}
                            onChange={({value, validator}) => setEmail(value)} />
                    )}

                    {user.uncompleted_fields.includes('USERNAME') && (
                        <FormInput
                            name="username"
                            type={'text'}
                            label={'Username'}
                            placeholder={'username'}
                            defaultValue={username}
                            validators={[
                                (value) => value.replace(/[^a-z0-9_]/gi, '').length > 6
                            ]}
                            onChange={({value, validator}) => setUsername(value.replace(/[^a-z0-9_]/gi, ''))} />
                    )}

                    {user.uncompleted_fields.includes('PASSWORD') && (
                        <FormInput
                            name="password"
                            type={'password'}
                            label={'Password'}
                            placeholder={'Password'}
                            defaultValue={password}
                            validators={[
                                value => value.length > 7,
                                value => value.replace(/[^a-z]/gi, '').length > 0,
                                value => value.replace(/[^0-9]/gi, '').length > 0
                            ]}
                            onChange={({value, validator}) => setPassword(value)} />
                    )}
                </div>
            </div>

            <div className="modal-footer">
                <BlueButton active={data.button} onClick={e => {
                    dispatch(completeProfile({
                        first_name: firstName,
                        last_name: lastName,
                        phone,
                        email,
                        username,
                        password
                    }, data.game));
                }}>
                    Save
                </BlueButton>
            </div>
        </div>
    )
}