import types from './types';
import globalTypes from '../types';

const InitialState = {
    loaded: false,
    wheel: null,
    status: null,
    history: {
        all: [],
        my: []
    },
    buttons: {
        spin: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case globalTypes.user.logout:
            return InitialState;
        case types.history.all:
            return {
                ...state,
                history: {
                    ...state.history,
                    all: [
                        {
                            ...payload,
                            timestamp: payload.timestamp + Date.now(),
                            animation: true
                        },
                        ...state.history.all.map(tx => {
                            return {
                                ...tx,
                                animation: false
                            }
                        })
                    ]
                }
            }
        case types.history.my:
            return {
                ...state,
                history: {
                    ...state.history,
                    my: [
                        {
                            ...payload,
                            timestamp: payload.timestamp + Date.now(),
                            expire_at: payload.expire_at + Date.now(),
                            animation: true
                        },
                        ...state.history.my.map(tx => {
                            return {
                                ...tx,
                                animation: false
                            }
                        })
                    ]
                }
            }
        case types.spin:
            return {
                ...state,
                wheel: {
                    ...state.wheel,
                    spin: payload
                }
            }
        case types.status:
            return {
                ...state,
                status: {
                    ...payload,
                    timestamp: payload.timestamp + Date.now()
                }
            }
        case types.rotate:
            return {
                ...state,
                wheel: {
                    ...state.wheel,
                    rotate: payload
                }
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                wheel: payload.wheel,
                status: {
                    ...payload.status,
                    timestamp: payload.status.timestamp + Date.now()
                },
                history: {
                    all: payload.history.all.map(tx => {
                        return {
                            ...tx,
                            timestamp: tx.timestamp + Date.now()
                        }
                    }),
                    my: payload.history.my.map(tx => {
                        return {
                            ...tx,
                            timestamp: tx.timestamp + Date.now(),
                            expire_at: tx.expire_at + Date.now()
                        }
                    }),
                }
            }
        case types.unload:
            return InitialState;
        default:
            return state;
    }
}