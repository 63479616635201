export default ({active, onClick}) => (
    <div className={`method ${!active ? 'not-available' : ''}`} onClick={e => {
        if(!active)
            return;

        onClick({
            name: 'TON',
            network: 'Ton',
            token_name: "TON",
            token: "TON"
        });
    }}>
        <div className="info">
            <div className="icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1275_6789)">
                        <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#0098EA"/>
                        <path d="M24.0773 9H11.9215C9.68646 9 8.26992 11.4109 9.39429 13.3599L16.8965 26.3633C17.3859 27.2123 18.6128 27.2123 19.1024 26.3633L26.606 13.3599C27.729 11.414 26.3123 9 24.0789 9H24.0773ZM16.8903 22.4637L15.2565 19.3017L11.3142 12.2508C11.0541 11.7995 11.3754 11.2212 11.92 11.2212H16.8888V22.4652L16.8903 22.4637ZM24.6816 12.2493L20.7408 19.3032L19.107 22.4637V11.2197H24.0758C24.6204 11.2197 24.9416 11.798 24.6816 12.2493Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1275_6789">
                            <rect width="36" height="36" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div className="name">TON <span>Ton</span></div>
        </div>

        {
            !active 
            ? <div className="status">Soon</div>
            : <div className="status bonus">Bonus <b>+7%</b></div>
        }
    </div>
)