export default() => {
    return (
        <div className="live-operator" onClick={e => {
            window.open('https://t.me/casinos_support_bot', '_blank');
        }}>
            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.74 20.295C26.2212 19.1263 26.4825 17.875 26.4825 16.5C26.4825 15.51 26.3312 14.5613 26.07 13.6813C25.1762 13.8875 24.2413 13.9975 23.265 13.9975C21.2657 13.9997 19.2952 13.5211 17.5196 12.6022C15.744 11.6833 14.2153 10.351 13.0625 8.71752C11.8304 11.7023 9.50361 14.1029 6.55875 15.4275C6.50375 15.7713 6.50375 16.1425 6.50375 16.5C6.50375 17.8127 6.76231 19.1126 7.26467 20.3254C7.76703 21.5382 8.50335 22.6402 9.43158 23.5684C11.3062 25.4431 13.8488 26.4963 16.5 26.4963C17.9437 26.4963 19.3325 26.18 20.5838 25.6163C21.3675 27.115 21.725 27.8575 21.6975 27.8575C19.4425 28.6138 17.6963 28.985 16.5 28.985C13.1725 28.985 9.99625 27.6788 7.65875 25.3275C6.23569 23.9112 5.17862 22.1698 4.57875 20.2538H2.75V13.9975H4.24875C4.70283 11.7872 5.7468 9.74108 7.26989 8.07621C8.79297 6.41133 10.7383 5.18983 12.8996 4.54131C15.0609 3.89279 17.3574 3.84145 19.5454 4.39274C21.7335 4.94403 23.7315 6.07737 25.3275 7.67252C27.0597 9.39894 28.2415 11.5999 28.7237 13.9975H30.25V20.2538H30.1675L25.2725 24.75L17.985 23.925V21.6288H24.6262L25.74 20.295ZM12.7463 16.1838C13.1588 16.1838 13.5575 16.3488 13.8462 16.6513C14.1364 16.9438 14.2993 17.3392 14.2993 17.7513C14.2993 18.1633 14.1364 18.5587 13.8462 18.8513C13.5575 19.14 13.1588 19.305 12.7463 19.305C11.88 19.305 11.1788 18.6175 11.1788 17.7513C11.1788 16.885 11.88 16.1838 12.7463 16.1838ZM20.24 16.1838C21.1063 16.1838 21.7938 16.885 21.7938 17.7513C21.7938 18.6175 21.1063 19.305 20.24 19.305C19.3738 19.305 18.6725 18.6175 18.6725 17.7513C18.6725 17.3355 18.8376 16.9368 19.1316 16.6429C19.4256 16.3489 19.8243 16.1838 20.24 16.1838Z" fill="white"/>
            </svg>
        </div>
    )
}