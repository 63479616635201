import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.modals.data.wallet_deposit_failed,
    (data) => {
        return {
            transactionId: data.transactionId,
            transaction: data.transaction
        }
    }
)