export default () => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1275_6812)">
            <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#627EEA"/>
            <path d="M17.2793 4.32275V13.9023L25.3761 17.5203L17.2793 4.32275Z" fill="white" fill-opacity="0.602"/>
            <path d="M17.8156 4.32275L9.71777 17.5203L17.8156 13.9023V4.32275Z" fill="white"/>
            <path d="M17.2793 24.1431V30.6522L25.3815 19.4429L17.2793 24.1431Z" fill="white" fill-opacity="0.602"/>
            <path d="M17.8156 30.6522V24.1419L9.71777 19.4429L17.8156 30.6522Z" fill="white"/>
            <path d="M17.2793 22.3605L25.3761 17.6594L17.2793 14.0435V22.3605Z" fill="white" fill-opacity="0.2"/>
            <path d="M9.71777 17.6594L17.8156 22.3605V14.0435L9.71777 17.6594Z" fill="white" fill-opacity="0.602"/>
        </g>
        <defs>
            <clipPath id="clip0_1275_6812">
                <rect width="36" height="36" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)