import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/modals';

import AddCash from './addcash';
import CashOut from './cashout';
import LoginReady from './loginReady';
import CashoutFailed from './cashoutFailed';
import DepositFailed from './depositFailed';
import DepositSuccess from './depositSuccess';
import LoginRemove from './loginRemove';
import LoginReset from './loginReset';
import LoginResetDone from './loginResetDone';
import Game from './game';
import Download from './download';
import Wallet from './wallet';
import WalletDepositSuccess from './wallet_deposit_success';
import WalletDepositFailed from './wallet_deposit_failed';
import ChangeGame from './changeGame';
import TestYourLuck from './testyourluck';
import FreePlay from './freeplay';
import Auth from './auth';
import ProfileComplete from './profile_complete';
import AffiliateFreeplay from './affiliate_freeplay';
import Notifications from './notifications';

export default () => {
    const {modal, isAuth} = useSelector(Selector);

    if(!modal)
        return <></>;

    return (
        <div className="modal-overlay animate__animated animate__fadeIn animate__faster">
            {isAuth && (
                <>
                    {modal === 'wallet' && <Wallet />}
                    {modal === 'wallet_deposit_success' && <WalletDepositSuccess />}
                    {modal === 'wallet_deposit_failed' && <WalletDepositFailed />}
                    {modal === 'addcash' && <AddCash />}
                    {modal === 'cashout' && <CashOut />}
                    {modal === 'cashout_failed' && <CashoutFailed />}
                    {modal === 'login_ready' && <LoginReady />}
                    {modal === 'login_remove' && <LoginRemove />}
                    {modal === 'login_reset' && <LoginReset />}
                    {modal === 'login_reset_done' && <LoginResetDone />}
                    {modal === 'deposit_failed' && <DepositFailed />}
                    {modal === 'deposit_success' && <DepositSuccess />}
                    {modal === 'game' && <Game />}
                    {modal === 'download' && <Download />}
                    {modal === 'changeGame' && <ChangeGame />}

                    {modal === 'testyourluck_add' && <TestYourLuck.Add />}
                    {modal === 'freeplay_add' && <FreePlay.Add />}
                    {modal === 'profile_complete' && <ProfileComplete />}
                    {modal === 'affiliate_freeplay' && <AffiliateFreeplay />}
                    {modal === 'notifications' && <Notifications />}
                </>
            )}

            {modal === 'signin' && <Auth.SignIn />}
            {modal === 'signup' && <Auth.SignUp />}
        </div>
    )
}