import {useEffect} from 'react';
import PageContainer from '../page-container';
import Components from '../../components';

import Telegram from './telegram';
import Promocode from './promocode';
import Vouchers from './vouchers';
import Notifications from './notifications';

export default () => {
    useEffect(() => {
        Components.Loader.close();
    }, []);

    return (
        <PageContainer>
            <div className="fc-page">
                <Components.Winners />

                <div className="fc-container">
                    {/* <Telegram /> */}
                    <Notifications />
                    <Promocode />
                    <Vouchers />
                    {/* <Profile /> */}
                </div>
                <Components.DepositBonus />
                <Components.Footer />
            </div>

        </PageContainer>
    )
}