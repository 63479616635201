import {useEffect} from 'react';
import DateLib from '../../../../../../lib/date';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import CryptoIcons from '../../../../../components/crypto_icons';
import CryptoTokens from '../../../../../../utils/crypto_tokens';
import tippy from 'tippy.js';

export default ({
    token,
    amounts,
    bonus,
    paymentId,
    status,
    timestamp,
    active,
    onClick
}) => {
    const Icon = typeof CryptoIcons[token] === 'undefined' ? <></> : CryptoIcons[token],
          token_info = CryptoTokens.getTokenInfo(token);

    useEffect(() => {
        if(active && bonus) {
            tippy(`#bonus-${paymentId}`, {
                placement: "bottom-end",
                animation: 'fade',
                theme: 'translucent',
                arrow: true,
                content: bonus.text,
                allowHTML: true
            });
        }
    }, [active]);

    return (
        <div className={`payment ${active ? 'active' : ''} ${bonus ? 'bonus-deposit' : ''}`}>
            <div className="top" onClick={onClick}>
                <div className="left">
                    <div className="icon crypto">
                        <Icon />
                    </div>
                    <div className="details">
                        <div className="name">
                            {token_info.name} <span>{token_info.network}</span>
                        </div>
                        {DateLib.parse(timestamp)}
                    </div>
                </div>
                <div className="right">
                    <div className="info">
                        <div className={`amount green`}>+ ${amounts.total}</div>
                        <div className="status confirmed">
                            <div className="dot"></div>
                            Confirmed
                        </div>
                    </div>

                    <div className="arrow">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.9668 3.22003L10.3135 7.5667C10.8268 8.08003 10.8268 8.92003 10.3135 9.43336L5.9668 13.78" stroke="#4F546A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
            {active && (
                <div className="bottom">
                    <hr />
                    <div className="order-details">
                        <div className="detail">
                            <div className="label">Payment ID</div>
                                <CopyToClipboard text={`${token_info.name} transaction #${paymentId} for $${amounts.total} (${amounts.crypto} ${token_info.token}) on ${token_info.network} network`} onCopy={e => {
                                    toast.dismiss();
                                    toast.info(`The transaction information has been copied to the clipboard.`);
                                }}>
                                <div className="value">
                                    #{paymentId}

                                    <div className="icon">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                            <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                        </svg>
                                    </div>
                                </div>
                            </CopyToClipboard>
                        </div>
                        <div className="detail">
                            <div className="label">Deposit amount</div>
                            <div className="value"><span>{amounts.crypto} {token_info.token} ≈</span> ${amounts.amount}</div>
                        </div>
                        {amounts.bonus && (
                            <div className="detail">
                                <div className="label">Deposit bonus</div>
                                <div className="value"><span>+{amounts.bonus.percent}% ≈</span> ${amounts.bonus.amount}</div>
                            </div>
                        )}

                        {amounts.comission > 0 && (
                            <div className="detail">
                                <div className="label">Processing fee</div>
                                <div className="value">${amounts.comission}</div>
                            </div>
                        )}

                        {bonus && (
                            <div className="detail">
                                <div className="label">Bonus</div>
                                <div className="value bonus" id={`bonus-${paymentId}`}>
                                    ${bonus.amount} 
                                
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1328_6858)">
                                            <rect width="16" height="16" rx="8" fill="#30344F"/>
                                            <path d="M9.76971 10.8984C9.59936 10.9584 9.05993 11.1013 8.81387 10.9741C8.77992 10.9562 8.75127 10.9312 8.73038 10.9011C8.7095 10.8709 8.69701 10.8367 8.69399 10.8012C8.6167 10.3554 8.98422 9.32524 9.18296 8.77228C9.2492 8.58796 9.29652 8.45365 9.31545 8.37221C9.45898 7.77067 9.38801 7.31059 9.10409 7.00482C8.76245 6.6369 8.15645 6.56532 7.65928 6.6319C7.15374 6.69294 6.65972 6.81599 6.19081 6.99768C6.16903 7.00723 6.14999 7.02124 6.13521 7.0386C6.12043 7.05595 6.1103 7.07619 6.10563 7.0977L6.00311 7.54206C5.99756 7.56705 5.99946 7.59293 6.00861 7.61704C6.01776 7.64114 6.03382 7.66261 6.05516 7.67923C6.07676 7.69549 6.10264 7.7064 6.13028 7.71091C6.15793 7.71542 6.18638 7.71336 6.21289 7.70495C6.40059 7.64208 6.98735 7.50063 7.19713 7.67494C7.31543 7.77353 7.2823 7.98214 7.23498 8.13788C7.18609 8.30077 7.12773 8.47651 7.06779 8.66083C6.67977 9.85676 6.23813 11.2127 6.93845 11.6356C7.26979 11.8648 7.67309 11.9924 8.08988 12C8.49998 12 9.0063 11.8828 9.73501 11.6156C9.75697 11.6079 9.77673 11.5958 9.79284 11.5802C9.80895 11.5646 9.82099 11.546 9.82807 11.5256L9.97792 11.0727C9.98641 11.0469 9.98677 11.0194 9.97897 10.9934C9.97117 10.9674 9.95551 10.9439 9.93375 10.9255C9.912 10.9075 9.88506 10.8954 9.85605 10.8906C9.82703 10.8858 9.79711 10.8885 9.76971 10.8984ZM8.85803 4C8.63239 4.00014 8.41187 4.06087 8.22432 4.17452C8.03678 4.28816 7.89063 4.44962 7.80436 4.63848C7.71808 4.82735 7.69555 5.03514 7.7396 5.23561C7.78366 5.43607 7.89233 5.6202 8.05188 5.76473C8.21143 5.90926 8.4147 6.0077 8.63599 6.04761C8.85729 6.08752 9.08667 6.06711 9.29517 5.98896C9.50366 5.9108 9.68189 5.77842 9.80735 5.60853C9.9328 5.43863 9.99984 5.23887 10 5.03447C9.99992 4.76014 9.87958 4.49706 9.66543 4.30307C9.45129 4.10909 9.16087 4.00008 8.85803 4Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1328_6858">
                                                <rect width="16" height="16" rx="8" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        )}

                        <div className="detail total">
                            <div className="label">Total amount</div>
                            <div className="value">${amounts.total}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}