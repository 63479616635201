export default {
    loaded: "games.loaded",
    winner: "games.winner",
    game: "games.game",
    reset: "games.reset",
    testyourluck: "games.testyourluck",
    history: {
        add: "games.history.add",
        update: "games.history.update"
    },
    toggleButton: "games.toggleButton"
}