import {navigate} from '../../../../../../utils/navigation';

export default () => {

    return (
        <div className="slide wheel size-params">
            <div className="size-params-layer layer0">
                <div className="wheel-roll">
                    <div className="wheel-rotate"></div>
                </div>
            </div>
            <div className="layer-margin size-params-layer layer1">
                <div className="girl"></div>
            </div>
            <div className="layer-margin size-params slide-details">
                <div className="title">
                    Wheel of<br/>Fortune
                </div>
                <div className="descriptions-short">Spin the Wheel of Fortune to win: Free Games, Real Cash, VIP XP and exciting bonuses!</div>
                <div className="details">
                    <div className="button" onClick={e => navigate('/wheel')}>Enjoy the roll</div>
                </div>
            </div>
        </div>
    )
}