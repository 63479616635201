export default {
    mode: ({wallet}) => wallet.mode,
    tab: ({wallet}) => wallet.tab,
    method: ({wallet}) => wallet.method,
    bonuses: {
        error: ({wallet}) => wallet.bonuses.error,
        message: ({wallet}) => wallet.bonuses.message,
        button: ({wallet}) => wallet.bonuses.button
    },
    history: {
        loaded: ({wallet}) => wallet.history.loaded,
        list: ({wallet}) => wallet.history.list,
        count: ({wallet}) => wallet.history.count,
    },
    methods: {
        cashapp_deposit: ({wallet}) => wallet.methods.cashapp_deposit,
        cashapp_cashout: ({wallet}) => wallet.methods.cashapp_cashout,
        crypto_deposit: ({wallet}) => wallet.methods.crypto_deposit,
        cards_deposit: ({wallet}) => wallet.methods.cards_deposit
    }
}