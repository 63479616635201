import {useSelector} from 'react-redux';
import Selector from '../../../selectors/pages/home/welcome_bonus';
import ModalsLib from '../../../lib/modals';
import Timer from './timer';

export default () => {
    const {isAuth, active, timestamp} = useSelector(Selector);

    if(!active)
        return <></>;

    return (
        <div className="deposit_bonus">
            <div className="layer"><div className="gift"></div></div>
            <div className="layer"><div className="gift"></div></div>
            <div className="layer details">
                <div className="left">
                    <div className="gift"></div>
                    <div className="text">
                        <div className="title">Get +50% to your first deposit</div>
                        <div className="descriptions">Make a deposit now and take a bonus of up to 1 000 USD to your deposit</div>
                    </div>
                </div>

                <div className="right">
                    <div className="button" onClick={e => {
                        if(isAuth)
                            ModalsLib.open('wallet')
                        else
                            ModalsLib.open('signin')
                    }}>Claim now</div>
                    <Timer timestamp={timestamp} />
                </div>
            </div>
        </div>
    )
}