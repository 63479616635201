import {createSelector} from 'reselect';
import props from '../../../props';

export default createSelector(
    props.wallet.history.loaded,
    props.wallet.history.list,
    props.wallet.history.count,
    (loaded, list, count) => {
        return {
            loaded,
            list,
            count
        }
    }
)