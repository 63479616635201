export default ({active, onClick}) => (
    <div className={`method ${!active ? 'not-available' : ''}`} onClick={e => {
        if(!active)
            return;

        onClick({
            name: 'USDC',
            network: 'ERC-20',
            token_name: "USDC",
            token: "USDC_ERC20"
        });
    }}>
        <div className="info">
            <div className="icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1316_203)">
                        <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#2775CA"/>
                        <path d="M22.2543 19.7257C22.2543 17.4156 20.8707 16.6233 18.1033 16.2939C16.1268 16.0296 15.7314 15.5016 15.7314 14.5776C15.7314 13.6535 16.3903 13.0589 17.7081 13.0589C18.8941 13.0589 19.5561 13.4551 19.8823 14.445C19.9161 14.5407 19.9785 14.6237 20.061 14.6827C20.1435 14.7418 20.2422 14.7741 20.3436 14.7752H21.4114C21.4726 14.7768 21.5334 14.7659 21.5902 14.7432C21.6469 14.7206 21.6985 14.6866 21.7419 14.6434C21.7851 14.6002 21.8191 14.5487 21.8419 14.4919C21.8647 14.4351 21.8757 14.3743 21.8743 14.3132V14.2473C21.7456 13.5321 21.3844 12.8794 20.8468 12.3904C20.3094 11.9013 19.6254 11.6034 18.9013 11.5427V9.967C18.9013 9.70264 18.7036 9.50498 18.3742 9.43909H17.3818C17.1175 9.43909 16.9198 9.63676 16.854 9.967V11.4832C14.8773 11.7468 13.6254 13.0654 13.6254 14.7134C13.6254 16.8885 14.9432 17.7459 17.7112 18.0753C19.5594 18.4047 20.1492 18.7984 20.1492 19.855C20.1492 20.9118 19.2259 21.6357 17.9748 21.6357C16.2609 21.6357 15.6679 20.9125 15.4702 19.921C15.4504 19.8123 15.3939 19.7137 15.31 19.6417C15.2263 19.5699 15.1203 19.5288 15.0099 19.5256H13.8801C13.819 19.5243 13.7583 19.5352 13.7016 19.5579C13.6449 19.5807 13.5934 19.6146 13.5502 19.6578C13.507 19.701 13.4731 19.7524 13.4504 19.8091C13.4276 19.8658 13.4167 19.9266 13.4181 19.9876V20.0536C13.6825 21.7032 14.7391 22.8907 16.9174 23.2203V24.8088C16.9174 25.0731 17.1151 25.2708 17.4445 25.3366H18.4329C18.6964 25.3366 18.8941 25.1391 18.96 24.8088V23.2242C20.9365 22.8948 22.2543 21.5079 22.2543 19.7257Z" fill="white"/>
                        <path d="M14.5428 26.6503C9.40026 24.8023 6.75751 19.0635 8.67148 13.9829C9.6606 11.2116 11.8381 9.10077 14.5428 8.11084C14.8071 7.97905 14.9389 7.7814 14.9389 7.45116V6.52791C14.9389 6.26357 14.8071 6.06589 14.5428 6C14.4769 6 14.3451 6 14.2792 6.0659C8.0118 8.04495 4.58079 14.7085 6.55984 20.9767C7.13491 22.7872 8.13615 24.4333 9.4794 25.7766C10.8226 27.1198 12.4687 28.1211 14.2792 28.696C14.3341 28.7278 14.3957 28.746 14.459 28.7493C14.5223 28.7527 14.5855 28.741 14.6434 28.7152C14.7013 28.6894 14.7523 28.6503 14.7922 28.6011C14.8321 28.5517 14.8598 28.4937 14.873 28.4317C14.9389 28.3659 14.9389 28.3 14.9389 28.1682V27.2442C14.9389 27.0465 14.7428 26.7822 14.5428 26.6503ZM21.5332 6.0659C21.4785 6.0342 21.4168 6.01593 21.3537 6.01257C21.2904 6.00921 21.2272 6.02085 21.1693 6.04655C21.1114 6.07226 21.0604 6.11127 21.0205 6.16042C20.9805 6.20959 20.9527 6.26751 20.9395 6.32944C20.8737 6.39532 20.8737 6.46122 20.8737 6.59381V7.51704C20.8737 7.7814 21.0712 8.04495 21.2697 8.17672C26.4123 10.0248 29.055 15.7644 27.141 20.8441C26.6545 22.1946 25.8762 23.421 24.8613 24.436C23.8464 25.4509 22.6201 26.2296 21.2697 26.7162C21.0054 26.848 20.8737 27.0465 20.8737 27.3759V28.2991C20.8737 28.5627 21.0054 28.7611 21.2697 28.8271C21.3357 28.8271 21.4674 28.8271 21.5332 28.7611C27.8007 26.7813 31.2318 20.1178 29.2527 13.8504C28.6794 12.0315 27.6798 10.3759 26.337 9.0217C24.9942 7.66748 23.3472 6.65459 21.5332 6.0659Z" fill="white"/>
                        <path d="M27.75 36C32.3064 36 36 32.3064 36 27.75C36 23.1936 32.3064 19.5 27.75 19.5C23.1936 19.5 19.5 23.1936 19.5 27.75C19.5 32.3064 23.1936 36 27.75 36Z" fill="#627EEA"/>
                        <path d="M27.416 21.4819V25.8726L31.1272 27.5308L27.416 21.4819Z" fill="white" fill-opacity="0.602"/>
                        <path d="M27.6646 21.4819L23.9531 27.5308L27.6646 25.8726V21.4819Z" fill="white"/>
                        <path d="M27.416 30.5664V33.5498L31.1296 28.4121L27.416 30.5664Z" fill="white" fill-opacity="0.602"/>
                        <path d="M27.6646 33.5498V30.5658L23.9531 28.4121L27.6646 33.5498Z" fill="white"/>
                        <path d="M27.416 29.7485L31.1272 27.5939L27.416 25.9365V29.7485Z" fill="white" fill-opacity="0.2"/>
                        <path d="M23.9531 27.5939L27.6646 29.7485V25.9365L23.9531 27.5939Z" fill="white" fill-opacity="0.602"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1316_203">
                            <rect width="36" height="36" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div className="name">USDC <span>ERC-20</span></div>
        </div>

        {
            !active 
            ? <div className="status">Soon</div>
            : <div className="status bonus">Bonus <b>+7%</b></div>
        }
    </div>
)