import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.modals.data.signin,
    (data) => {
        return {
            data
        }
    }
)