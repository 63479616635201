import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/components/header/user';
import {logout} from '../../../../reducers/user/actions';
import Numeric from '../../numeric';
import ModalsLib from '../../../../lib/modals';
import {navigate} from '../../../../utils/navigation';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';

export default () => {
    const {user} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <div className="user-header">
            <div className="balance-block" onClick={e => ModalsLib.open('wallet')}>
                <div className="amount">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#44B1F9"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9641 6C11.7038 6 12.4407 6 13.1804 6C13.1369 6.46879 13.0907 6.94013 13.0499 7.40892C13.9119 7.46752 14.7522 7.72739 15.5082 8.1121C15.1819 8.88153 14.8528 9.65096 14.5238 10.4204C13.8874 9.91338 13.0499 9.63312 12.215 9.64331C12.0056 9.64841 11.7881 9.68662 11.614 9.79873C11.4998 9.87261 11.4155 9.98726 11.3911 10.1146C11.372 10.2166 11.3775 10.3312 11.4373 10.4229C11.5053 10.5248 11.6249 10.586 11.7446 10.6242C11.9893 10.7057 12.2531 10.7108 12.5087 10.7363C13.1097 10.8051 13.7134 10.9019 14.2818 11.1032C14.7223 11.2611 15.1438 11.4981 15.4456 11.8446C15.7312 12.1631 15.8971 12.5656 15.9596 12.9783C16.033 13.4548 16.0113 13.949 15.8536 14.4127C15.7312 14.7847 15.5218 15.1312 15.2499 15.4242C14.6869 16.0357 13.8603 16.4025 13.0118 16.4968C13.039 16.9987 13.0716 17.4981 13.1015 18C12.3999 18 11.7011 18 11.0022 18C11.0294 17.5032 11.0647 17.0064 11.0892 16.5096C10.5263 16.4917 9.96883 16.3745 9.44399 16.1885C8.93546 16.0076 8.45142 15.7682 8 15.4879C8.33176 14.6879 8.66353 13.8879 8.99801 13.0854C9.376 13.3834 9.77847 13.6611 10.219 13.8726C10.6922 14.0994 11.217 14.2446 11.75 14.2497C12.0029 14.2522 12.264 14.2395 12.5033 14.158C12.6501 14.107 12.7943 14.0178 12.8595 13.8777C12.9275 13.7299 12.9221 13.5439 12.8187 13.4115C12.7453 13.3223 12.642 13.2611 12.5332 13.2178C12.3864 13.1618 12.2341 13.1312 12.0791 13.1083C11.508 13.0217 10.9342 12.9503 10.3822 12.7822C9.93891 12.6471 9.50653 12.4586 9.15302 12.1682C8.93003 11.9873 8.74511 11.7656 8.61458 11.5159C8.41878 11.1439 8.34536 10.7236 8.3508 10.3108C8.3508 9.84968 8.45142 9.38089 8.69616 8.9758C8.90827 8.61401 9.23188 8.31847 9.59627 8.09172C10.0368 7.81911 10.529 7.63312 11.0294 7.4828C11.0076 6.98854 10.9859 6.49427 10.9641 6Z" fill="#45B1FA"/>
                    </svg>

                    <Numeric value={user.balance} prefix={''} />
                </div>
                <div className="wallet-button">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.8333 9.29175H5.83325" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1.66675 9.2917V5.4417C1.66675 3.7417 3.04175 2.3667 4.74175 2.3667H9.42508C11.1251 2.3667 12.5001 3.42503 12.5001 5.12503" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.5667 10.1667C14.1501 10.5667 13.9501 11.1833 14.1167 11.8166C14.3251 12.5916 15.0918 13.0833 15.8918 13.0833H16.6667V14.2917C16.6667 16.1333 15.1751 17.625 13.3334 17.625H5.00008C3.15841 17.625 1.66675 16.1333 1.66675 14.2917V8.45833C1.66675 6.61667 3.15841 5.125 5.00008 5.125H13.3334C15.1667 5.125 16.6667 6.625 16.6667 8.45833V9.66663H15.7667C15.3001 9.66663 14.8751 9.84999 14.5667 10.1667Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.3333 10.5166V12.2333C18.3333 12.7 17.95 13.0833 17.475 13.0833H15.8667C14.9667 13.0833 14.1417 12.425 14.0667 11.525C14.0167 11 14.2167 10.5083 14.5667 10.1667C14.875 9.84999 15.3 9.66663 15.7667 9.66663H17.475C17.95 9.66663 18.3333 10.05 18.3333 10.5166Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Wallet</span>
                </div>
            </div>

            <div className="profile">
                <CopyToClipboard text={`User #${user.userId} [${user.userStringId}]`} onCopy={e => {
                    toast.dismiss();
                    toast.info(`User ID copied to clipboard`);

                    // window.subscribePushNotifications();
                }}>
                    <div className="avatar-block no-arrow">
                        {
                            user.type === "local"
                            ? <img src={`${window.location.origin}/source/system/avatars/default.png`} alt="" /> 
                            : <img src={user.avatar} alt="" />
                        }
                        {/* <img src={`${window.location.origin}/logo192.png`} alt="" /> */}
                        {/* <div className="arrow">
                            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 4.5L4 6L2.5 4.5" stroke="#4FACFE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M4 2L4 5" stroke="#4FACFE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div> */}
                    </div>
                </CopyToClipboard>
            </div>

            {/* <div className="notifications-block">
                <div className="button">
                    <div className="dot"></div>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0167 2.42505C7.25841 2.42505 5.01674 4.66672 5.01674 7.42505V9.83338C5.01674 10.3417 4.80007 11.1167 4.54174 11.55L3.58341 13.1417C2.99174 14.125 3.40007 15.2167 4.48341 15.5834C8.07507 16.7834 11.9501 16.7834 15.5417 15.5834C16.5501 15.25 16.9917 14.0584 16.4417 13.1417L15.4834 11.55C15.2334 11.1167 15.0167 10.3417 15.0167 9.83338L15.0167 7.42505C15.0167 4.67505 12.7667 2.42505 10.0167 2.42505Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                        <path d="M11.5584 2.6667C11.3001 2.5917 11.0334 2.53337 10.7584 2.50003C9.95843 2.40003 9.19176 2.45837 8.4751 2.6667C8.71676 2.05003 9.31676 1.6167 10.0168 1.6167C10.7168 1.6167 11.3168 2.05003 11.5584 2.6667Z" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.5166 15.8833C12.5166 17.2583 11.3916 18.3833 10.0166 18.3833C9.33327 18.3833 8.69993 18.1 8.24993 17.65C7.79993 17.2 7.5166 16.5666 7.5166 15.8833" fill="none" stroke-width="1.5" stroke-miterlimit="10"/>
                    </svg>
                </div>
            </div> */}

            {user.type === "local" && (
                <div className="button logout" onClick={e => {
                    navigate('/', () => {
                        dispatch(logout());
                    })
                }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5833 13.7C12.3249 16.7 10.7833 17.925 7.40825 17.925L7.29992 17.925C3.57492 17.925 2.08325 16.4334 2.08325 12.7084L2.08325 7.27505C2.08325 3.55005 3.57492 2.05838 7.29992 2.05838L7.40825 2.05838C10.7583 2.05838 12.2999 3.26671 12.5749 6.21671" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.50006 10L16.9834 10" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.1251 12.7916L17.9167 9.99996L15.1251 7.20829" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            )}
        </div>
    )
}