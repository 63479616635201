import ModalsLib from '../../../../lib/modals';

export default () => {

    return (
        <div className="guest-buttons">
            <div className="button signin" onClick={e => ModalsLib.open('signin')}>Sign In</div>
            <div className="button signup" onClick={e => ModalsLib.open('signup')}>Sign Up</div>
        </div>
    )
}