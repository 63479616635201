import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.app.isMobile,
    props.app.sidebar,
    props.user.isAuth,
    props.user.user,
    props.vip.levels,
    (isMobile, sidebar, isAuth, user, levels) => {
        const current = user ? levels.filter(lvl => lvl.xp.from <= user.xp && lvl.xp.to > user.xp)[0] || false : false;

        return {
            isMobile,
            sidebar,
            isAuth,
            user,
            vip: current
        }
    }
)