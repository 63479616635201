import {useNavigate} from 'react-router-dom';
import {getState} from '../lib/store';
import ModalsLib from '../lib/modals';

const navigate = (path, afterChange) => {
    const {user} = getState();

    if(!user.user && path !== "/")
        return ModalsLib.open('signin');

    window.dispatchEvent(new CustomEvent('location.change', {
        detail: {
            path: path != window.location.pathname ? path : null,
            afterChange
        }
    }));
}

const NavigationLink = ({path, children}) => {
    return (
        <div onClick={() => navigate(path)}>
            {children}
        </div>
    )
}

export {
    NavigationLink,
    navigate
}

export default {
    navigate
}