import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/modals/deposit_success';
import ModalsLib from '../../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import GreenButton from '../../components/green-button';
import BlueButton from '../../components/blue-button';
import {getCashoutsRange} from '../../../utils/sessions';
import tippy from 'tippy.js';

export default () => {
    const {transaction, game, login, sessions} = useSelector(Selector),
          [showPassword, setShowPassword] = useState(false);

    const cashout_range = getCashoutsRange({type: login.balance_details.session, amount: 0, loginBalance: login.balance_details.session_amount, sessions});

    useEffect(() => {
        tippy('#cashoutRulesInfo', {
            placement: "top",
            animation: 'fade',
            theme: 'translucent',
            arrow: true,
            content: "<b>Play Big - Win Big</b> <br/> Hello world",
            allowHTML: true
        });

        if(!transaction || !game)
            ModalsLib.close();
    }, []);

    if(!transaction || !game)
        return <></>;

    return (
        <div className="modal animate__animated animate__fadeInUp animate__faster">
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Deposit success</div>
                    <div className="descriptions">Your deposit has been successfully credited to your game login!</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="game-small">
                    <div className="game-details">
                        <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${game.slug}.png)`}}></div>
                        <div className="name">{game.name}</div>
                    </div>
                    <div className="tx-amount"><span>$</span>{transaction.amount}</div>
                </div>

                <div className="cashouts" id="cashoutRulesInfo">
                    <div className="rules">
                        Cashouts range
                    </div>
                    <div className="value">
                        <span>{cashout_range.text}</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-tip data-for="cashoutRules">
                            <rect width="20" height="20" rx="10" fill="#30344F"/>
                            <path d="M11.7697 12.8984C11.5994 12.9584 11.0599 13.1013 10.8139 12.9741C10.7799 12.9562 10.7513 12.9312 10.7304 12.9011C10.7095 12.8709 10.697 12.8367 10.694 12.8012C10.6167 12.3554 10.9842 11.3252 11.183 10.7723C11.2492 10.588 11.2965 10.4537 11.3154 10.3722C11.459 9.77067 11.388 9.31059 11.1041 9.00482C10.7624 8.6369 10.1564 8.56532 9.65928 8.6319C9.15374 8.69294 8.65972 8.81599 8.19081 8.99768C8.16903 9.00723 8.14999 9.02124 8.13521 9.0386C8.12043 9.05595 8.1103 9.07619 8.10563 9.0977L8.00311 9.54206C7.99756 9.56705 7.99946 9.59293 8.00861 9.61704C8.01776 9.64114 8.03382 9.66261 8.05516 9.67923C8.07676 9.69549 8.10264 9.7064 8.13028 9.71091C8.15793 9.71542 8.18638 9.71336 8.21289 9.70495C8.40059 9.64208 8.98735 9.50063 9.19713 9.67494C9.31543 9.77353 9.2823 9.98214 9.23498 10.1379C9.18609 10.3008 9.12773 10.4765 9.06779 10.6608C8.67977 11.8568 8.23813 13.2127 8.93845 13.6356C9.26979 13.8648 9.67309 13.9924 10.0899 14C10.5 14 11.0063 13.8828 11.735 13.6156C11.757 13.6079 11.7767 13.5958 11.7928 13.5802C11.8089 13.5646 11.821 13.546 11.8281 13.5256L11.9779 13.0727C11.9864 13.0469 11.9868 13.0194 11.979 12.9934C11.9712 12.9674 11.9555 12.9439 11.9338 12.9255C11.912 12.9075 11.8851 12.8954 11.856 12.8906C11.827 12.8858 11.7971 12.8885 11.7697 12.8984ZM10.858 6C10.6324 6.00014 10.4119 6.06087 10.2243 6.17452C10.0368 6.28816 9.89063 6.44962 9.80436 6.63848C9.71808 6.82735 9.69555 7.03514 9.7396 7.23561C9.78366 7.43607 9.89233 7.6202 10.0519 7.76473C10.2114 7.90926 10.4147 8.0077 10.636 8.04761C10.8573 8.08752 11.0867 8.06711 11.2952 7.98896C11.5037 7.9108 11.6819 7.77842 11.8073 7.60853C11.9328 7.43863 11.9998 7.23887 12 7.03447C11.9999 6.76014 11.8796 6.49706 11.6654 6.30307C11.4513 6.10909 11.1609 6.00008 10.858 6Z" fill="white"/>
                        </svg>
                    </div>
                </div>

                {login && (
                    <>
                        <div className="line-title">
                            <div className="line"></div>
                            <span>LOGIN DETAILS</span>
                            <div className="line"></div>
                        </div>

                        <div className="login-details">
                            <div className="input">
                                <div className="value">{login.login}</div>
                                <div className="icons">
                                    <CopyToClipboard text={login.login} onCopy={e => {
                                        toast.dismiss();
                                        toast.info(`Login copied to clipboard`);
                                    }}>
                                        <div className="icon">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                                <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                            </svg>
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>

                            <div className="input">
                                <div className="value">{showPassword ? login.password : '••••••'}</div>
                                <div className="icons">
                                    <div className={`icon ${showPassword ? 'active' : ''}`} onClick={e => setShowPassword(!showPassword)}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.3866 8.00007C10.3866 9.32007 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32007 5.61328 8.00007C5.61328 6.68007 6.67995 5.6134 7.99995 5.6134C9.31995 5.6134 10.3866 6.68007 10.3866 8.00007Z" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M7.9999 13.5134C10.3532 13.5134 12.5466 12.1267 14.0732 9.72671C14.6732 8.78671 14.6732 7.20671 14.0732 6.26671C12.5466 3.86671 10.3532 2.48004 7.9999 2.48004C5.64656 2.48004 3.45323 3.86671 1.92656 6.26671C1.32656 7.20671 1.32656 8.78671 1.92656 9.72671C3.45323 12.1267 5.64656 13.5134 7.9999 13.5134Z" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <CopyToClipboard text={login.password} onCopy={e => {
                                        toast.dismiss();
                                        toast.info(`Password copied to clipboard`);
                                    }}>
                                        <div className="icon">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                                <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                            </svg>
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className="modal-footer">
                {game.allows.web ? (
                    <GreenButton onClick={e => {
                        ModalsLib.open('game', {
                            game: game.slug
                        })
                    }}>
                        Play
                    </GreenButton>
                ) : (
                    <>
                        {game.allows.download && (
                            <BlueButton onClick={e => {
                                ModalsLib.open('download', {
                                    game: game.slug
                                })
                            }}>
                                Download
                            </BlueButton>
                        )}
                    </>
                )}

                <div className="button close" onClick={e => ModalsLib.close()}>
                    Close
                </div>
            </div>
        </div>
    )
}