import {useState, useEffect} from 'react';
import Timer from '../../../../../../../utils/timer';

export default ({timestamp}) => {
    const [time, setTime] = useState(timestamp - Date.now());

    useEffect(() => {
        window.sliderWelcomeBonusTimeout = setTimeout(() => {
            setTime(timestamp - Date.now())
        }, 800);

        return () => clearTimeout(window.sliderWelcomeBonusTimeout);
    }, [time]);

    const timer = Timer.parse(time, true);

    return (
        <>
            <div className="timer desktop">
                <div className="top">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.6666 8.00004C14.6666 11.68 11.6799 14.6667 7.99992 14.6667C4.31992 14.6667 1.33325 11.68 1.33325 8.00004C1.33325 4.32004 4.31992 1.33337 7.99992 1.33337C11.6799 1.33337 14.6666 4.32004 14.6666 8.00004Z" stroke="#E9974D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.4734 10.12L8.40675 8.88671C8.04675 8.67338 7.75342 8.16005 7.75342 7.74005V5.00671" stroke="#E9974D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Get bonus in time
                </div>
                <div className="progress">
                    <div className="line" style={{width: `${(time / (24 * 60 * 60 * 1e3))*100}%`}}></div>
                </div>
                <div className="time">
                    {`${timer.hours != '00' ? `${timer.hours}H` : ''}${timer.hours != '00' ? ` ${timer.minutes}M` : (timer.minutes === '00' ? '' : ` ${timer.minutes}M`)} ${timer.seconds}S`}
                </div>
            </div>
            <div className="timer mobile">
                <div className="sector">
                    <div className="time">{timer.hours}</div>
                    hours
                </div>
                <div className="dots">
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
                <div className="sector">
                    <div className="time">{timer.minutes}</div>
                    minutes
                </div>
                <div className="dots">
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
                <div className="sector">
                    <div className="time">{timer.seconds}</div>
                    seconds
                </div>
            </div>
        </>
    )
}