import {int} from '../utils/formats';

export const getNames = ({
    type,
    first_name,
    last_name,
    username
}) => { 
    return `UNNAMED`
}

export const getInviteLink = (type, code) => {
    if(type === "telegram-webapp") return `t.me/lucksy_bot?start=${code}`;
    if(type === "local") return `lucksy.net/?r=${code}`;
    return "";
}

export const getWelcomeBonusTimestamp = () => {
    let timestamp = localStorage.getItem('wb');

    if(!timestamp) {
        timestamp = Date.now() + (8 * 60 * 60 * 1e3);
        localStorage.setItem('wb', timestamp);
    }

    if(timestamp - Date.now() < 0) {
        timestamp = Date.now() + (8 * 60 * 60 * 1e3);
        localStorage.setItem('wb', timestamp);
    }

    return timestamp;
}

export const getNotificationModalTimestamp = () => {
    let timestamp = localStorage.getItem('nmt');

    if(!timestamp)
        return 0;

    return int(timestamp);
}