import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/modals/wallet/methods/cashapp_deposit';
import {initialize, clearStages} from '../../../../../reducers/wallet/actions/cashapp_deposit';
import ModalsLib from '../../../../../lib/modals';

import Cards from './cards';
import Create from './create';
import Confirm from './confirm';
import Confirmed from './confirmed';
import Choose from './choose';

export default () => {
    const {stage, initialized, works} = useSelector(Selector),
          dispatch = useDispatch();

    const modalCloseHandler = () => {
        dispatch(clearStages());
    }

    useEffect(() => {
        document.addEventListener('modal.close', modalCloseHandler);
        return () => document.removeEventListener('modal.close', modalCloseHandler);
    }, []);

    useEffect(() => {
        dispatch(initialize());
    }, [initialized]);

    if(!initialized)
        return (
            <>
                <div className="modal-header">
                    <div className="modal-header-left">
                        <div className="title"></div>
                        <div className="descriptions"></div>
                    </div>
                    <div className="close" onClick={e => ModalsLib.close()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="modal-loader">
                        <div className="element"></div>
                    </div>
                </div>
            </>
        )

    if(!works)
        return <Cards />

    if(stage === 'choose') return <Choose />
    if(stage === 'create') return <Create />
    if(stage === 'confirm') return <Confirm />
    if(stage === 'confirmed') return <Confirmed />
    return <>{stage}</>;
}