import {createSelector} from 'reselect';
import props from '../../../../props';

export default createSelector(
    props.wallet.methods.cashapp_deposit,
    (cashapp_deposit) => {
        return {
            stage: cashapp_deposit.stage,
            initialized: cashapp_deposit.initialized,
            works: cashapp_deposit.works
        }
    }
)