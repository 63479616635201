import {createSelector} from 'reselect';
import props from './props';

export default createSelector(
    props.app.initialized,
    props.user.loaded,
    props.games.loaded,
    props.vip.levels_loaded,
    props.app.notifications.initialized,
    props.user.isAuth,
    (initialized, user, games, vip_levels, notifications, isAuth) => {
        return {
            loaded: initialized && user && games && vip_levels && notifications,
            initialized,
            user,
            games,
            vip_levels,
            notifications,
            isAuth
        }
    }
)