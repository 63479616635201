import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/modals/wallet/deposit';
import {setPreset} from '../../../../../reducers/wallet/actions';
import {changeCoin} from '../../../../../reducers/wallet/actions/crypto_deposit';
import Tokens from './tokens';

export default () => {
    const {voucher} = useSelector(Selector),
          dispatch = useDispatch();

    const onTokenClick = tokenData => {
        dispatch(changeCoin(tokenData));

        dispatch(setPreset({
            mode: "methods",
            method: "crypto-deposit"
        }));
    }

    return (
        <>
            {voucher && (
                <div className="bonus-message">
                    <div className="icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.16665 5.16666H7.99998C7.92149 5.16346 7.84481 5.14206 7.77601 5.10414C7.70721 5.06622 7.64817 5.01282 7.60356 4.94816C7.55894 4.8835 7.52997 4.80935 7.51894 4.73157C7.5079 4.65379 7.51511 4.57451 7.53998 4.49999C7.77332 3.91332 7.98665 3.31332 8.15998 2.72666C8.30665 2.27332 8.87998 0.853323 10.32 0.853323C10.8812 0.835339 11.4266 1.04074 11.8366 1.42444C12.2465 1.80814 12.4875 2.33881 12.5066 2.89999C12.5066 4.18666 11.0466 4.71332 10.5066 4.89999C9.74321 5.09921 8.95534 5.18899 8.16665 5.16666ZM10.3133 1.83332C9.54665 1.83332 9.19998 2.71999 9.11332 2.99332C8.99332 3.37999 8.86665 3.76666 8.72665 4.15332C9.20459 4.15029 9.67996 4.08302 10.14 3.95332C10.52 3.81999 11.4733 3.45999 11.4733 2.87999C11.4528 2.58941 11.3205 2.31807 11.1042 2.12292C10.8879 1.92777 10.6045 1.82398 10.3133 1.83332Z" fill="#0C76DA"/>
                            <path d="M7.81352 5.16663C7.04198 5.18022 6.27232 5.08598 5.52686 4.88663C3.86686 4.34663 3.52686 3.49997 3.52686 2.88663C3.52964 2.62758 3.58483 2.37177 3.68909 2.1346C3.79335 1.89744 3.94453 1.68383 4.13352 1.50663C4.55379 1.08385 5.12407 0.84423 5.72019 0.839966C6.84019 0.839966 7.40686 1.88663 7.82686 2.6333C8.0998 3.24539 8.32275 3.87857 8.49352 4.52663C8.51417 4.60017 8.5177 4.67747 8.50383 4.75259C8.48997 4.8277 8.45907 4.89865 8.41352 4.95997C8.36936 5.02189 8.31148 5.07276 8.24441 5.10861C8.17733 5.14446 8.10288 5.16432 8.02686 5.16663H7.81352ZM5.64686 1.8333C5.33027 1.84693 5.03141 1.98322 4.81352 2.2133C4.71849 2.2971 4.64166 2.39949 4.58777 2.51416C4.53387 2.62883 4.50407 2.75333 4.50019 2.87997C4.50019 3.28663 4.99352 3.67997 5.83352 3.9533C6.32367 4.07999 6.8273 4.14714 7.33352 4.1533C7.22569 3.79827 7.09429 3.45083 6.94019 3.1133C6.55352 2.3733 6.18019 1.84663 5.66686 1.8333H5.64686Z" fill="#0C76DA"/>
                            <path d="M14.1668 6.83329V5.99996C14.1668 5.51373 13.9737 5.04741 13.6299 4.7036C13.286 4.35978 12.8197 4.16663 12.3335 4.16663H3.66683C3.1806 4.16663 2.71428 4.35978 2.37047 4.7036C2.02665 5.04741 1.8335 5.51373 1.8335 5.99996V6.83329H14.1668ZM8.50016 7.83329V15.1666H12.3335C12.8197 15.1666 13.286 14.9735 13.6299 14.6297C13.9737 14.2858 14.1668 13.8195 14.1668 13.3333V7.83329H8.50016ZM7.50016 7.83329H1.8335V13.3333C1.8335 13.8195 2.02665 14.2858 2.37047 14.6297C2.71428 14.9735 3.1806 15.1666 3.66683 15.1666H7.50016V7.83329Z" fill="#0C76DA"/>
                        </svg>
                    </div>
                    <div className="text" dangerouslySetInnerHTML={{
                        __html: `You will receive <b>+${voucher.type === 'deposit-cash' ? `$${voucher.bonus}` : `${voucher.bonus}%`}</b> to the next deposit${voucher.minimal_deposit_amount > 0 ? ` from <b>$${voucher.minimal_deposit_amount}</b>` : ''} for activating the «<b>${voucher.code}</b>» promo code`
                    }}></div>
                </div>
            )}

            <div className="methods-list">
                <div className="methods">
                    <div className="group-title">Cash methods</div>
                    {/* <div className="list local">
                        <div className="method cashapp" onClick={e => {
                            dispatch(setPreset({
                                mode: "methods",
                                method: "cashapp-deposit"
                            }));
                        }}>
                            <div className="info">
                                <div className="icon">
                                    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.908 5.81664C11.6905 5.81664 13.3897 6.44997 14.7391 7.59997C14.8724 7.71664 15.0556 7.79997 15.2555 7.79997C15.4554 7.79997 15.622 7.71664 15.772 7.5833L17.1546 6.19997C17.2213 6.13331 17.2713 6.03331 17.3046 5.94997C17.3379 5.86664 17.3546 5.74997 17.3546 5.66664C17.3546 5.56664 17.3212 5.46663 17.2879 5.3833C17.2546 5.29997 17.188 5.19997 17.1047 5.14997C16.0218 4.21664 14.7558 3.51664 13.3897 3.06664L13.8062 0.999969C13.8229 0.89997 13.8229 0.783304 13.8062 0.683304C13.7895 0.583304 13.7396 0.483302 13.6729 0.399968C13.6063 0.316635 13.523 0.233305 13.423 0.199972C13.3231 0.149972 13.2065 0.133301 13.1065 0.133301H10.4411C10.2745 0.133301 10.1079 0.183301 9.9913 0.299967C9.87468 0.399967 9.77473 0.549972 9.72475 0.699972L9.35826 2.5333C5.79324 2.71664 2.76131 4.46664 2.76131 8.09997C2.76131 11.2333 5.26015 12.5666 7.89227 13.5166C10.3911 14.45 11.7072 14.7833 11.7072 16.1C11.7072 17.4 10.3911 18.2333 8.44201 18.2333C6.55955 18.2666 4.72707 17.55 3.36103 16.25C3.22776 16.1166 3.04451 16.0333 2.8446 16.0333C2.6447 16.0333 2.47811 16.1 2.32818 16.25L0.82887 17.7166C0.762235 17.7833 0.712258 17.85 0.662281 17.95C0.628963 18.0333 0.612305 18.15 0.612305 18.2333C0.612305 18.3333 0.628963 18.4333 0.662281 18.5166C0.695599 18.6 0.745576 18.7 0.82887 18.75C2.04497 19.9 3.52762 20.7 5.14354 21.1L4.72707 23C4.71041 23.1 4.71041 23.2166 4.72707 23.3166C4.74373 23.4166 4.7937 23.5333 4.86034 23.6C4.92697 23.6833 5.02693 23.75 5.11022 23.8166C5.21018 23.8666 5.32679 23.8833 5.42674 23.8833H8.12549C8.29208 23.8833 8.45867 23.8333 8.57529 23.7333C8.6919 23.6333 8.80851 23.4833 8.84183 23.3166L9.22498 21.5C13.5063 21.2333 16.1051 18.9333 16.1051 15.5666C16.1051 12.4666 13.5063 11.1666 10.3411 10.1C8.52531 9.41664 6.95937 8.96663 6.95937 7.6333C6.99268 6.2833 8.44201 5.81664 9.908 5.81664Z" fill="white"/>
                                    </svg>
                                </div>

                                <div className="name">Cash App</div>
                            </div>
                        </div>

                        <div className="method cards" onClick={e => {
                            dispatch(setPreset({
                                mode: "methods",
                                method: "cards-deposit"
                            }));
                        }}>
                            <div className="info">
                                <div className="icon">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.83325 11.5591H17.4166" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M17.4166 9.42332V15.9775C17.3891 18.59 16.6741 19.25 13.9516 19.25H5.29828C2.52995 19.25 1.83325 18.5625 1.83325 15.8308V9.42332C1.83325 6.94832 2.41075 6.15082 4.58325 6.02248C4.80325 6.01332 5.04161 6.00415 5.29828 6.00415H13.9516C16.7199 6.00415 17.4166 6.69165 17.4166 9.42332Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M20.1666 6.16917V12.5767C20.1666 15.0517 19.5891 15.8492 17.4166 15.9775V9.42333C17.4166 6.69167 16.7199 6.00417 13.9516 6.00417H5.29828C5.04161 6.00417 4.80325 6.01333 4.58325 6.0225C4.61075 3.41 5.32578 2.75 8.04828 2.75H16.7016C19.4699 2.75 20.1666 3.4375 20.1666 6.16917Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M4.8125 16.3258H6.38914" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.35083 16.3258H11.5042" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>

                                <div className="name">Debit card</div>
                            </div>
                        </div>
                    </div> */}
                    <div className="list">
                        <Tokens.CashApp active={true} onClick={data => dispatch(setPreset(data))} />
                        <Tokens.Cards active={true} onClick={data => dispatch(setPreset(data))} />
                    </div>
                </div>

                <div className="methods">
                    <div className="group-title">Cryptocurrency</div>
                    <div className="list">
                        <Tokens.BTC active={true} onClick={data => onTokenClick(data)} />
                        <Tokens.ETH active={true} onClick={data => onTokenClick(data)} />
                        <Tokens.LTC active={true} onClick={data => onTokenClick(data)} />
                        <Tokens.TRX active={true} onClick={data => onTokenClick(data)} />
                        <Tokens.USDT_TRC20 active={true} onClick={data => onTokenClick(data)} />
                        <Tokens.USDT_ERC20 active={true} onClick={data => onTokenClick(data)} />
                        <Tokens.USDC_ERC20 active={true} onClick={data => onTokenClick(data)} />
                        <Tokens.BNB active={false} onClick={data => onTokenClick(data)} />
                        <Tokens.TON active={false} onClick={data => onTokenClick(data)} />
                        <Tokens.USDT_BEP20 active={false} onClick={data => onTokenClick(data)} />
                        <Tokens.USDT_TON active={false} onClick={data => onTokenClick(data)} />
                    </div>
                </div>
            </div>
        </>
    )
}