import {useRef, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../selectors/modals/game';
import ModalsLib from '../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default () => {
    const {game, login} = useSelector(Selector);

    const [showLoginDetails, setShowLoginDetails] = useState(true),
          [showPassword, setShowPassword] = useState(false);

    const [isLoginCopied, setIsLoginCopied] = useState(false),
          [isPasswordCopied, setIsPasswordCopied] = useState(false);

    useEffect(() => {
        if(isLoginCopied)
            window.loginCopiedTimeout = setTimeout(() => {
                setIsLoginCopied(false);
            }, 1e3);

        return () => clearTimeout(window.loginCopiedTimeout);
    }, [isLoginCopied])

    useEffect(() => {
        if(isPasswordCopied)
            window.passwordCopiedTimeout = setTimeout(() => {
                setIsPasswordCopied(false);
            }, 1e3);

        return () => clearTimeout(window.passwordCopiedTimeout);
    }, [isPasswordCopied])

    if(!game)
        return <></>;

    return (
        <div className="modal game animate__animated animate__fadeInUp animate__faster">
            <iframe src={game.links.play} />
            <div className="close" onClick={e => ModalsLib.close()}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 18L18 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 18L6 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>

            {(login && showLoginDetails) && (
                <div className="login-details">
                    <div className="input">
                        <div className="value">{login.login}</div>
                        <div className="icons">
                            <CopyToClipboard text={login.login} onCopy={e => {
                                setIsLoginCopied(true);
                            }}>
                                <div className={`icon ${isLoginCopied ? 'copied' : ''}`}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                        <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                    </svg>
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>

                    <div className="input">
                        <div className="value">{showPassword ? login.password : '••••••'}</div>
                        <div className="icons">
                            <div className={`icon ${showPassword ? 'active' : ''}`} onClick={e => setShowPassword(!showPassword)}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.3866 8.00007C10.3866 9.32007 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32007 5.61328 8.00007C5.61328 6.68007 6.67995 5.6134 7.99995 5.6134C9.31995 5.6134 10.3866 6.68007 10.3866 8.00007Z" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.9999 13.5134C10.3532 13.5134 12.5466 12.1267 14.0732 9.72671C14.6732 8.78671 14.6732 7.20671 14.0732 6.26671C12.5466 3.86671 10.3532 2.48004 7.9999 2.48004C5.64656 2.48004 3.45323 3.86671 1.92656 6.26671C1.32656 7.20671 1.32656 8.78671 1.92656 9.72671C3.45323 12.1267 5.64656 13.5134 7.9999 13.5134Z" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <CopyToClipboard text={login.password} onCopy={e => {
                                setIsPasswordCopied(true);
                            }}>
                                <div className={`icon ${isPasswordCopied ? 'copied' : ''}`}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                        <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                    </svg>
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>

                    <div className="close" onClick={e => setShowLoginDetails(false)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 18L18 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M18 18L6 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            )}
        </div>
    )
}