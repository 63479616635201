import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/invite';
import {loadInvite} from '../../../reducers/invite/actions';
import PageContainer from '../page-container';
import Components from '../../components';
import VIP from './vip';
import Referrals from './referrals';
import FAQ from './faq';
import Steps from './steps';
import Top from './top';

export default () => {
    const {loaded} = useSelector(Selector),
          dispatch = useDispatch();

    useEffect(() => {
        if(loaded) {
            Components.Loader.close();
            Components.Loader.closePage();
        } else {
            dispatch(loadInvite());
        }
    }, [loaded]);
    
    return (
        <PageContainer>
            <Components.Loader.Page />
            {loaded && (
                <div className="invite-page">
                    <Components.Winners />

                    <div className="invite-container">
                        <Top />
                        <Steps />
                        {/* <FAQ /> */}
                        <VIP />
                        <Referrals />
                    </div>

                    <Components.DepositBonus />
                    <Components.Footer />
                </div>
            )}
        </PageContainer>
    )
}