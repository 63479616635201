import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/pages/vip/cashback/weekly';
import {redeemCashback} from '../../../../../reducers/vip/actions';
import ModalsLib from '../../../../../lib/modals';
import Numeric from '../../../../components/numeric';
import Timer from './timer';
import GreenButton from '../../../../components/green-button';

export default () => {
    const {profile, button, level} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <div className="cashback weekly">
            <div className="title">WEEKLY</div>
            <div className="details">
                <div className="amount">
                    <Numeric value={profile.amount} fixedDecimalScale={0} />
                    BOOST
                </div>
                <div className="royalty">
                    Cashback {level ? <span>{level.preset.cashback.weekly}%</span> : <span>N/A</span>}
                </div>
            </div>

            {profile.status === 0 ? (
                <GreenButton active={false} className="cashback-button" onClick={e => {
                    ModalsLib.open('wallet');
                }}>
                    Add Cash
                </GreenButton>
            ) : (
                <Timer timestamp={profile.timestamp} timer_key={`vip_cashback_weekly`}>
                    <GreenButton active={button} className="cashback-button" onClick={e => {
                        if(button)
                            return;

                        dispatch(redeemCashback('weekly'))
                    }}>
                        Redeem <Numeric value={profile.amount} fixedDecimalScale={0} />
                    </GreenButton>
                </Timer>
            )}
        </div>
    )
}