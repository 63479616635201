import {useSelector} from 'react-redux';
import Selector from '../../selectors/components/winners';

export default () => {
    const {winners} = useSelector(Selector);

    return (
        <div className="winners">
            <div className="title">
                <div className="text">Winners</div>
            </div>

            <div className="winners-list">
                {winners.map((winner, key) => {
                    return (
                        <div className="winner" key={key}>
                            <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${winner.game}.png)`}}></div>
                            <div className="details">
                                <div className="amount">+{winner.amount} USD</div>
                                <div className="username">{winner.name}</div>
                            </div>
                            <div className={`line ${winner.type}`}></div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}