export default {
    modal: ({modals}) => modals.modal,
    data: {
        profile_complete: ({modals}) => modals.data.profile_complete,
        signup: ({modals}) => modals.data.signup,
        signin: ({modals}) => modals.data.signin,
        wallet: ({modals}) => modals.data.wallet,
        addcash: ({modals}) => modals.data.addcash,
        cashout: ({modals}) => modals.data.cashout,
        login_ready: ({modals}) => modals.data.login_ready,
        login_remove: ({modals}) => modals.data.login_remove,
        login_reset: ({modals}) => modals.data.login_reset,
        login_reset_done: ({modals}) => modals.data.login_reset_done,
        cashout_failed: ({modals}) => modals.data.cashout_failed,
        deposit_failed: ({modals}) => modals.data.deposit_failed,
        deposit_success: ({modals}) => modals.data.deposit_success,
        game: ({modals}) => modals.data.game,
        download: ({modals}) => modals.data.download,
        wallet_deposit_success: ({modals}) => modals.data.wallet_deposit_success,
        wallet_deposit_failed: ({modals}) => modals.data.wallet_deposit_failed,
        changeGame: ({modals}) => modals.data.changeGame,
        
        testyourluck_add: ({modals}) => modals.data.testyourluck_add,
        freeplay_add: ({modals}) => modals.data.freeplay_add
    }
}